<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
#app{
  position:relative;
  width: 100%;
}
::v-deep .el-loading-spinner  {
  .el-loading-text{
    font-size: 14px!important;
    color: #146EF6!important;
    font-weight: 600!important;
  }
  i{
    color: #146EF6!important;
  }
}
</style>
