export default {
        //login
        loading: '加载中...',
        SignUp: '注册',
        LoginSuccess: '登录成功',
        LoginOut: '退出成功',
        email: '邮箱',
        password: '密码',
        logIn: '登录',
        WelcomeBack: '欢迎回来',
        PleaseEnterYourEmailAndPassword: '请输入您的邮箱和密码',
        CharactersRequired: '不少于8个字符',
        keepMeSignedIn: '保持登录',
        forgetPassword: '忘记密码',
        dontHaveAnAccount: '没有账号?',
        signUpNow: '立即注册',
        AllRightsReserved: '版权所有',
        privacyPolicy: '隐私',
        and: '和',
        termsOfService: '条款',
        Name: '姓名',
        viewAll: '查看全部',
        fold: '收起',
        role: '角色',
        noLimit: "无限制",
        //signup
        company: '公司',
        companyName: '公司名称',
        signUp: '注册',
        alreadyHaveAnAccount: '已有账号',
        getStarted: '开始吧！ 通过我们的平台为您的业务赋能',
        signUpSuccess: '注册成功',
        updateCreditCard: '更新信用卡',
        //foget
        noWorries: '不用担心，输入您的注册电子邮件，我们将向您发送重置说明',
        //reset
        reset: '重置',
        forgotPassword: '忘记密码',
        RequestPasswordReset: '请求密码重置',
        backToLogin: '返回登录',
        verifyPassword: '验证密码',
        EnterANewPasswordFor: '输入新密码',
        ResetPassword: '重置密码',
        newPassword: '新密码',
        //top-nav
        Home: '首页',
        Search: '搜索',
        Prospects: '客户',
        filterYourSearchToFindHighlySpecific: '筛选搜索以找到高度特定的目标潜在客户群',
        groupsOfTargetedLeads: '',
        filterYourSearchToFindPreciselyTargeted: '筛选搜索以找到精确定位的公司群',
        listsOfCompanies: '',
        transformAnonymousWebsiteTrafficInto: '将匿名网站流量转化为实时识别的B2B',
        b2bLeadsWithRealtimeIdentification: '潜在客户',
        discoverAndConnectWithPotentialBuyers: '积极发现并联系潜在买家寻找您今天的',
        activelySeekingYourSolutionToday: '解决方案',
        Intent_Signals: '趋势信号',
        Website_Intent: '网站趋势',
        Market_Intent: '市场趋势',
        Sequence: '序列',
        Tools: '工具',
        Services: '服务',
        technologyLookup: '技术查询',
        domainLookup: '域名查询',
        emailVerification: '邮箱认证',
        upgrade: '升级',
        helpCenter: '帮助中心',
        contactUs: '联系我们',
        feedback: '反馈',
        hello: '你好',
        clickToChange: '点击更改',
        yourProfile: '个人资料',
        currentUsage: '使用情况',
        upgradePlan: '升级计划',
        integrations: '集成',
        featureRequest: '特色请求',
        change: '更改',
        en: '英文',
        cn: '中文（简）',
        tw: '中文（繁）',
        logout: '退出',
        switchLanguage: '切换语言成功',

        //not available
        ComingSoon: '即将推出!',
        TheFeatureIscurrentlyUnder: '目前该功能正在开发中',
        development: '',
        weAppreciateYourPatienceAsWeWorkToImprove: '我们感谢您的耐心等待，我们将努力提升',
        yourExperience: '您的体验',
        inTheMeantimeIfYouHaveAnyQuestionsOrNeed: '同时，如果您有任何疑问或需要帮助，',
        assistancePleaseContactOurSupportTeam: '请联系我们的支持团队',
        contactSupport: '联系支持',
        ok: '确定',
        thankYouForBeingAValuedUserOfOurPlatform: '感谢您成为我们平台的重要用户',


        //search-company
        people: '人',
        companies: '公司',
        clearAll: '清除所有',
        searchProspects: '搜索客户、关键词...',
        //actions
        isFoundIn: '在',
        isNotFoundWithinAnyLists: '没有在任何列表中找到',
        l: '个列表中找到',
        remove: '移除',
        saveToLists: '保存到列表',

        //delete dialog
        areYouSureYouWantToDeleteThisRecord: '您确定要删除此记录吗？',
        proceedingWithDeletionWillResultInPermanentRemovalOfTheList: '继续删除将导致永久删除列表',
        delete: '删除',
        byCancelingYouEnsureThatNoListsWillBeRemoved: '取消将不会删除任何列表',
        DeleteFailed: '删除失败',
        //export dialog
        holdOnWereGeneratingYourExport: "稍等，我们正在生成您的导出",
        UseThisButtonOnlyIfYouWishToCancelYourAction: '仅在您希望取消操作时使用此按钮',
        //save search pop
        saveSearch: '保存搜索',
        newSaveSearch: '新保存搜索',
        UpdateSavedSearch: '更新保存搜索',
        enterNewSearchName: '输入新搜索名称',
        visibility: '可见性',
        manageWhoCanSeeYourSavedSearch: '管理谁可以看到您的保存搜索',
        private: '私人',
        onlyYouCanSeeYourSavedSearch: '只有您可以看到您的保存搜索',
        everyoneCanSeeYourSavedSearch: '每个人都可以看到您的保存搜索',
        pinSavedSearch: '将保存搜索固定在顶部',
        findSavedSearch: '查找保存搜索',
        enterSavedSearchName: '输入保存搜索名称',
        duplicate: '复制',
        update: '更新',
        noSavedSearches: '没有保存的搜索',
        //add credits pop
        ClickTheButtonBelowToAddMoreCredits: '点击下面的按钮添加更多信用点数',
        PleaseEnterTheAmountYouLikeToTopUpToYourCredit: '请输入您想充值的金额',





        //companyLink\contactBtn\functionBtn\default
        more: '更多',
        verified: '已认证',
        GetEmailSuccessfully: '获取邮箱成功',
        CopySuccessfully: '复制成功',
        recorded: '已记录',
        implement: '执行',
        sequence: '序列',
        of: '  总条数：',
        toAssistYouEffectivelyWeRequireFurtherDetailsAbout: '为了有效地帮助您，我们需要有关您搜索偏好的更多详细信息。请利用左侧面板中的筛选选项来指定您所需的条件，以便我们能够优化搜索结果,并为您提供满足您要求的目标群体资料。',


        //filter
        all: '全部',
        my: '我的',
        team: '团队',
        lists: '列表',
        employees: '员工',
        technologies: '技术',
        socialmedia: '社交媒体',
        enterSocialMedia: '请输入社交媒体',
        noResultsFound: '没有找到结果',
        enterCompanies: '请输入公司',
        searchCompanies: '搜索公司',
        filters: '筛选',
        accountHqLocation: '总部位置',
        CityStateCountryZIP: '城市/州/国家/邮编',
        enterLocations: '请输入位置',
        revenue: '营收',
        chooseARangeOfRevenue: '选择营收区间',
        ofRevenueIsUnknown: '营收未知',
        technology: '技术',
        category: '类别',
        categories: '类别',
        enterCategories: '请输入类别',
        enterTechnologies: '请输入技术',
        employee: '员工',
        predefinedRange: '预定义范围',
        ofEmployeesIsUnknown: '员工数量未知',
        enterNames: '请输入名称',
        jobTitle: '职位',
        searchForAJobTitle: '搜索职位',
        enterIndustries: '请输入行业',
        founded: '成立时间',
        chooseARangeOfFoundedTime: '选择时间区间',
        from: '从',
        to: '到',
        specialties: '特色',
        enterSpecialties: '请输入特色',
        location: '位置',
        total: '总数',
        department: '部门',
        EnterDepartments: '请输入部门',
        JobTitle: '职位',
        EnterJobTitles: '请输入职位',
        ManagementLevel: '管理层级',
        EnterManagementLevels: '请输入管理层级',
        AccountHQ: '公司地址',
        ContactHQ: '联系地址',
        departmentFunction: '部门职能',
        EnterJobFunctions: '请输入部门职能',
        enterNamesAndPressTheEnterKey: '输入名称并按Enter键',
        EnterJobTitlesAndPressTheEnterLKey: '输入职位并按Enter键',
        pleaseSelectRange: '请选择范围',




        //table
        selectAllCompanies: '选择所有公司',
        selected: '已选择',
        export: '导出',
        industry: '行业',
        keywords: '关键词',
        companyLocation: '公司位置',
        action: '操作',
        PleaseEnterTheSelectedQuantity: '请输入选择的数量',
        contact: '联系方式',
        Title: '职位',
        selectThisPage: '选择此页',
        clearSelection: '清除选择',
        selectNumberOfCompanies: '选择公司数量',
        maxCompanies: '最大公司数量',
        youCanOnlySelectAMaximumOf25Companies: '您最多只能选择25家公司。',
        UpgradeYourPlanNow: '立即升级您的计划！',
        viewPlans: '查看计划',
        applySelection: '应用选择',
        selectNumberOfProspects: '选择客户数量',
        maxProspects: '最大客户数量',
        youCanOnlySelectAMaximumOf25Prospects: '您最多只能选择25个客户。',
        PleaseSelectTheDataToBeExported: '请选择要导出的数据',
        overview: '概览',
        genericContacts: '联系方式',
        size: '规模',
        contactLocation: '联系地址',





        //account
        addCreditCard: '添加信用卡',
        zip: '邮编',
        country: '国家',
        state: '州',
        city: '城市',
        address: '地址',
        billingInformation: '账单信息',
        yourTeams: '您的团队',
        cvc: 'CVC',
        year: '年',
        month: '月',
        expirationDate: '到期日',
        creditCardNumber: '信用卡号码:',
        cardInformation: '卡信息',
        title: '标题',
        fullName: '全名',
        yourDetail: '您的详细信息',
        creditsToUse: '待使用信用点数',
        youHave: '你有',
        download: '下载',
        buyMoreCredits: '购买更多信用点数',
        description: '描述',
        topUpCredits: '充值信用点数',
        cancel: '取消',
        date: '日期',
        invoiceHistory: '发票历史',
        confirmNewPass: '确认新密码',
        creditsUsed: '已使用信用点数',
        newPass: '新密码',
        youHaveNotEnteredAnyCreditCardInformationYet: '您尚未输入任何信用卡信息。',
        oldPass: '旧密码',
        cardNumber: '卡号',
        changePassword: '更改密码',
        creditUsage: '信用点数使用情况',
        typeTheNewTitle: '输入新标题',
        save: '保存',
        addMoreCredits: '添加更多信用点数',
        creditsMo: '信用点数/月',
        apiIntegrations: 'API集成',
        profileSubscription: "个人资料订阅",
        'additional-user-licence': "附加用户许可证",
        'agency-white-label': "机构白标",
        'api-extended-limit': "API扩展限制",
        'profile-subscription': "个人资料订阅",
        creditsInYourPlan: '您计划中的信用点数',
        plugin: "插件",
        settings: '设置',
        phone: '电话',
        '1User': '1个用户',
        users: '用户',
        billing: '账单',
        monthlyQueryLimit: '每月查询限額',
        price: '价格',
        whatsIncluded: '包括什么',
        givenName: '名',
        purchasePlan: '购买计划',
        accountOverview: '账户概述',
        familyName: '姓',
        dailyQueryLimit: '每日查询限額',
        rateLimit: '限制',
        youAreOnA: '您正在',
        PlanAndYourCreditsWillRefreshOn: '计划中，您的信用点数将在',
        过期: '更新',
        searchSettings: '搜索设置',
        plan: '计划',
        accountInfo: '账户信息',
        creditCardInformation: '信用卡信息',
        apiUsage: 'API使用情况',
        mo: '/月',
        perUserPerMonth: '每用户每月',
        billedAnnually: '按年计费',
        dailyQuery: '每日查询',
        monthlyQuery: '每月查询',
        currentPlan: '当前计划',
        everythingInPlus: 'everythingInPlus',
        mostPopular: '最受欢迎',
        topUpToIncreaseYourCredits: '增加您的信用点数',
        addCredits: '购买信用点数',
        allPlansInclude: '所有计划均包括',
        Amount: '金额',
        credits: '信用点数',
        purchaseSuccess: '购买成功,您的总信用点数为',
        purchaseFailed: '购买失败',
        Loading: '加载中',
        Selected: '已选择',
        SelectPlan: '选择计划',
        upgradeSuccess: '计划切换成功',
        upgradeFailed: '计划切换失败',
        inviteSentSuccessfully: '邀请发送成功',
        DeleteSuccessfully: '删除成功',
        AccountupdatedSuccessfully: '账户更新成功',
        AccountUpdateFailed: '账户更新失败',
        PasswordUpdatedSuccessfully: '密码更新成功',
        PasswordUpdateFailed: '密码更新失败',
        Users: '用户',
        newUser: '新用户',
        deleteUser: '删除用户',
        admin: '管理员',
        inviteYourTeam: '邀请您的团队',
        empowerEveryoneToGetMoreDealsDonefaster: '授权每个人更快地完成更多交易',
        addMultipleEmailAddressesSeparatedByCommas: '添加多个电子邮件地址（用逗号分隔）',
        sendInvites: '发送邀请',
        AreYouSureYouWantToDeleteThisTeamMember: '您确定要删除此团队成员吗？',
        teamDetails: '团队详情',
        teamName: '团队名称',
        changeTeam: '切换团队',
        clickToChangeTeam: '点击更改团队',
        changeTeamSuccessfully: '切换团队成功',
        status: '状态',
        Action: '操作',
        pending: '待定',
        current: '当前',
        AreYouSureYouWantToDeleteThisCreditCard: '您确定要删除此信用卡吗？',
        PasswordCannotBeEmpty: '密码不能为空',
        nickname: '昵称',
        FirstName: '名',
        LastName: '姓',
        timezone: '时区',
        contactNo: '联系电话',
        controlYourTeamMembersAndTheirAccountAccessRightHere: '在此处管理您的团队成员及其帐户访问权限',
        pleaseEnterYourCurrentPasswordToChangeYourPassword: '请输入当前密码以更改密码',
        updatePassword: '更新密码',
        oldPassword: '旧密码',
        confirmNewPassword: '确认新密码',
        InviteYourTeam: '邀请您的团队',
        empowerEveryoneToGetMoreDealsDoneFaster: '授权每个人更快地完成更多交易',
        enterEmailAddressesMultipleCommaseparated: '输入电子邮件地址（多个用逗号分隔）',
        invoice: '发票',
        accountAdmin: '账户管理员',
        setAsDefault: '设为默认',
        bankName: '银行名称',
        expiry: '到期',
        viewAllYourPreviousInvoicesHere: '在此处查看您以前的所有发票',
        BillingHistory: '账单历史',
        updateYourCreditCardInformation: '更新您的信用卡信息',
        ManageCreditCard: '管理信用卡',
        CardNumber: '卡号',
        billingAddress: '账单地址',
        yourCreditsWillRefreshOn: '您的信用点数将在',
        yourCreditsWillBeChargeOn: "您的信用点数将在",
        "被收取": "被收取",
        perYear: '/每年',
        perMonth: '/每月',
        monthlyCredit: "每月信用",
        Usage: "使用",
        features: '特征功能',
        credits_mo: '信用点数/月',
        credits_year: '信用点数/年',
        _mo: '/月',
        seeOtherPlan: '查看其他计划',
        subscriptionTiers: '订阅区',
        experiencePricingThatsEasyToUnderstandAndAdaptable: "体验易于理解和适应的定价",
        monthlyBilling: '月度计费',
        annualBilling: '年度计费',
        forPlanCustomizationContactOurSalesTeam: '有关定制计划的更多信息，请联系我们的销售团队',
        billedAnnually_user: '年度计费/用户',
        seePlanComparison: '查看计划比较',
        topUp: '充值',
        changeTeamNameFailed: '更改团队名称失败',
        // purchaseCreditsAtTheRateOf100PerCreditCustomize: '按每信用点数1美元的价格购买，请根据您的需求定制信用点数包',
        totalPrice: '总价',
        credit: '信用点数',
        RemoveCreditCard: '删除信用卡',
        enterATitleForYourCard: '为您的卡输入标题',
        enterYourCardNumber: '输入您的卡号',
        digit: '3位数字',
        provideYourCompleteAddress: '提供您的完整地址',
        selectYourCountry: '选择您的国家',
        collapse: '折叠',
        expand: '展开',





        // Intent
        intentOverview: "概览",
        intentAnalytic: "分析",
        intentFilter: "筛选",
        searchCompany: '搜索公司、网站、领英...',
        savedCompanyWebsite: '已保存公司的网站',
        companiesFound: '家公司',
        companyFound: '家公司',
        filterList: {
                technologies: '技术',
                time: '时间',
                locations: '账号位置',
                verticals: '行业',
                pageview_greater_than: '页面预览',
                session_greater_than: '会话',
                advertisements: '广告',
                company_groups: '列表',
                start_date: '开始时间',
                end_date: '结束时间',
                search_term: '搜索名称'
        },
        employeeFilterList: {
                industry: '行业',
                technology: '技术',
                jobTitle: '职位',
                company: '公司',
                list: '列表',
                name: '名字',
                size: '规模',
                location: '位置',
                jobFunction: '工作职能'
        },
        selectLists: '请选择列表...',
        chooseARangeOfTime: '时间段选择',
        greaterThan: '大于',
        selectAdvertisement: '选择广告',
        // intentIndustry: '行业',
        tabActivity: '活动',
        tabOverview: '概览',
        tabEmployees: '员工',
        tabContacts: '通用联系人',
        tabTechnologies: '技术',
        intentStatistic: '统计',
        intentCampaign: '活动',
        newVisitors: '新访客',
        recurringVisitors: '常客',
        mediocreLead: '普通的潜在客户',
        sessionsTracked: '个跟踪会话',
        sessionTracked: '个跟踪会话',
        lastSeenOn: '最后查看',
        firstSeenOn: '首次查看',
        viewTrackedWebSession: '查看跟踪的网络会话',
        webSession: '网络会话',
        client: '客户端',
        timeSpent: '花费时间',
        pageviews: '浏览量',
        pageview: '浏览量',
        referrer: '来源',
        sessions: '会话',
        session: '会话',
        visited: '访问',
        AM: '早晨',
        PM: '下午',
        companyDetails: '公司详情',
        overviewList: {
                location: '位置',
                industry: '行业',
                founded: '创建时间',
                ranking: '排名',
                size: '规模',
                revenue: '收入',
                keywords: '关键词',
        },
        advancedSearch: '高级搜索',
        jobFunction: '工作职能',
        employeeLocation: '位置',
        concatLocation: '联系位置',
        contactEmail: '邮件',
        categoryAndTechnology: '类别和技术',
        firstDetected: '首次查看',
        lastDetected: '最后查看',
        saved: '已保存',
        addToContactList: '添加到联系人列表',
        noCampaignIdentified: '暂时没有活动',
        copyEmail: '复制邮箱',
        willBeSavedToTheFollowingSelectedLists: '将会被保存到下列选中的列表:',
        addToLists: '添加到列表',
        noDataToDisplay: '暂无更多数据',
        intentFounded: '创建时间',
        intentIndustry: '行业',
        intentRevenue: '收入',
        intentSize: '规模',
        applyFilters: '筛选',
        youCanEditTheseFilters: '您可以随时编辑这些选项以筛选结果',


        //         analytic: '统计分析',
        //         allLeads: '所有线索',
        //         campaign: '活动',
        //         medium: '媒体',
        //         source: '来源',
        //         term: '项目',
        //         employees: '员工',
        //         activities: '活动',
        //         genericContacts: '通用联系人',
        //         technologies: '技术',
        //         campaigns: '活动',
        installB2BDirectLeadFinder: '在您的网站上安装 B2B Direct Lead Finder',
        addSite: '添加站点',
        areYouSureYouWantToRemoveThisWebsite: '您确定要移除这个网站吗？',
        Cancel: '取消',
        installB2BLeadFinder: '安装 B2B Lead Finder',
        pleaseTellUsABitAboutTheWebsiteYoudLikeToAdd: "请告诉我们您要添加的网站的一些信息",
        siteUrl: '站点网址',
        copyToClipboard: '复制到剪贴板',
        pleaseSelectAContactFirst: '请先选择一个联系人',
        pasteCodeBelowIntoTheHead: '将下面的代码粘贴到您希望跟踪访问者的每个页面的',
        ofEveryPageYouWishToTrackVisitorOn: '中',
        forWordpressWeRecommendInstalling: '对于 WordPress，我们建议安装来自 Stefano Lissa 的',
        pluginFromStefanoLissa: '插件。',
        afterInstallingAndActivating: '安装并激活插件后，导航到 WordPress 仪表板中的',
        inYourWordPressDashboard: '。',
        youWillSeeANumberOfTabs: '您会在插件界面中看到许多选项卡， 确保您位于默认的“页眉和页脚”选项卡中，然后复制并粘贴以下代码片段:',
        settingHeaderAndFooter: '设置 → 页眉和页脚',
        dontForgetToClearYourCache: "不要忘记清除缓存",
        ifYouAreUsingAnyCaChing: '如果你正在使用像',
        orSimilar: "之类的插件, 确保在安装代码段后已经清除缓存!",
        GTMshouldAlreadyBeInstalled: '在按照这些说明操作之前，您的网站上应该已经安装了 Google Tag Manager',
        ifYouDontHaveGTM: "如果您还没有安装 Google Tag Manager，您可以在",
        GTMsupportArticleAboutInstall: 'Google Tag Manager 支持的文章中了解如何安装它',
        signInToGTMAndClick: '登录 Google Tag Manager，然后点击添加新标签按钮，然后',
        clickWhereItSays: '点击',
        chooseATagTypeToBeginSetup: ' "Choose a tag type to begin setup" ',
        andSelect: '并从列表中选择 ',
        fromTheList: '。然后将以下代码复制并粘贴到文本字段中',
        nextClick: '然后点击 ',
        giveTheTagAName: '，为标签命名，然后单击“保存”按钮。',
        dontForgetToPublishYourChange: "不要忘记发布您的更改",
        afterCreateTheCustom: '创建 "Custom HTML" 标记后，不要忘记',
        yourChanges: '你的更改!',
        submitAndPublish: '提交并发布',
        copySuccessfully: '复制成功',
        addToCompanyList: '添加到公司列表',
        addToList: '添加到列表',
        enterOrCreateList: '输入或创建列表',
        installCode: '安装代码',
        wordpress: 'wordpress',
        googleTagManager: 'googleTagManager',
        noVisitorsYet: '暂无浏览',
        onceTheInsightsRollIn: "一旦深入了解，您将解锁有关访客等的宝贵信息。",
        noTechAreFound: '暂无技术内容',
        updateSuccessfully: '更新成功',
        enterJobFunction: '请输入工作职能',
        pinSuccessfully: '置顶成功',
        cancelPinSuccessfully: '取消置顶成功',
        adsName: '广告名称',
        utmTerm: 'utmTerm',
        utmCampaign: 'utmCampaign',
        utmSource: 'utmSource',
        utmMedium: 'utmMedium',
        enterNumber: "输入数字",
        enterName: "请输入名称",
        enterJobTitle: "请输入职位",
        areYouSureYouWantToRemovePeopleOfThisGroup: '您确定要将该人员从列表中删除吗',
        areYouSureYouWantToRemoveCompanyOfThisGroup: '您确定要将该公司从列表中删除吗',
        byCancelingYouEnsureThatNoCompanyWillBeRemoved: '取消将不会删除任何公司',
        byCancelingYouEnsureThatNoPeopleWillBeRemoved: '取消将不会删除任何人员',
        howToGetStarted: '如何开始',
        Data: '条数据',
        deactivateWebsite: '停用网站',
        areYouCertainAboutDeactivatinYourWebsite: '您确定要停用你的网站吗？这将导致无法接收任何潜在的公司活动。',
        byCancelingYouWillContinueToActivateAndUse: '取消后，您将继续激活并使用此工具',
        yourTrialHasEnded: '您的试用已到期',
        yourWebsiteHasBeenAutomaticallyInactivated: '您的网站已自动停用。是否要重新激活此工具以继续监视数据？',
        Inactivated: '停用中',
        onTrial: '试用中',
        Active: '激活',
        youAreOnTrail: '您正在七天试用期中',
        yourTrailHasEnded: '您的试用已过期',
        createNewWebIntent: '创建新的Web Intent',
        clickOnTheButton: '1. 点击 (+) 按钮',
        typeInUrlOrWebAddress: '2. 输入URL或网址',
        theTrialWillActivate: '选中后，7天试用将激活',
        pleaseEnterASiteUrl: '请输入网址',
        noMoreData: '暂无更多数据',
        pleaseEnterANewName: '请输入名称',
        pleaseSelectASearchName: '请选择搜索名称',
        clickToGetEmail: '点击获取邮件',
        emailIsNotAvailable: '无法获取邮件',
        enterOrCreateLists: '搜索或创建列表',
        viewMore: '查看更多',
        //  fold: '收起',
        removeFromContactList: '从联系人列表中移除',
        willBeRemovedFromTheFollowingLists: '将会从下列列表中移除',
        removeFromLists: '从列表中移除',
        pleaseSelectOrCreateAList: '请选择或创建一个列表',
        chooseATriggerToMakeThisTagFire: 'chooseATriggerToMakeThisTagFire',
        youWontBeChargedYet: '目前您还不会被收费',
        youDontHaveEnoughCredits: "您没有足够的信用点数",
        wouldYouLikeToAdd50Credits: "您想添加50个点数（最低充值金额）以继续发送电子邮件吗？",
        ThisEmailRequire: '该邮件需要',
        TheseEmailsRequires: '这些邮件需要',
        intentCredit: '信用点数',
        intentCredits: '信用点数',
        // analytic
        showNumber: '展示条数',
        Website: '网站',
        b2bTraffic: 'B2B 贸易',
        b2bReturningVsNew: 'B2B 常客 V.S. 新用户',
        visitors: '访客',
        totalView: '总浏览量',
        //  b2bPageViews: 'B2B 浏览量',
        totalSessions: '全部会话',
        operatingSystem: '操作系统',
        browser: '浏览器',
        device: '设备',
        companySize: '公司规模',
        topIndustry: '热门行业',
        topCountries: '热门国家',
        topReferrals: '热门推荐',
        new: '新用户',
        returning: '老用户',
        countriesStatistics: '国家统计',
        topCompaigns: '热门活动',
        topPagesByVisits: '浏览量分析',
        topPagesByEngagement: '参与度分析',
        Referrals: '推荐',
        Pages: '页面',
        avgTime: "平均时间",
        totalCompanies: '全部公司',
        b2bPageViews: 'B2B 页面浏览',
        TotalSessions: '全部会话',
        viewCompanyList: '查看全部公司',
        noDiscussionsYet: "暂无讨论",
        useThisSpaceToAskQuestions: "您可以使用此空间直接与我们提问、获取更新和讨论细节",
        // mycontact
        prospects: '前景',
        list: '列表',
        view: '查看',
        // delete: '删除',
        add: '添加',
        newList: '新建列表',
        savedLists: '已保存列表',
        searchList: '搜索列表名称',
        owner: '所有者',
        records: '记录',
        actions: '操作',
        lastModified: '最后修改',
        name: '姓名',
        enterAListName: '请输入列表名字',
        pleaseSelectAListFirst: '请先选择一个列表项',
        saveSuccessfully: '保存成功',
        pleaseEnterAListName: '请输入列表名称',
        editList: '编辑列表',
        fromThisList: '从列表中移除,您确定要继续吗',
        areYouSureYouWantToChange: '您确定要将列表类型更改为',
        // yes: '确定',
        noListCreated: '暂无列表',
        enhanceTheOrganization: '加强您在目标市场内联系人和帐户的组织。创建列表后，它们将显示在此页面上。',
        createSuccessfully: '创建成功',
        removeSuccessfully: '移除成功',
        createNewList: '创建新列表',
        groupTeam: '团队',
        groupPersonal: '个人',
        edit: '编辑',
        areYouSureYouWantToDeleteTheList: '您确定要删除这个列表吗?',
        proceedingWithDeletion: '继续删除将导致永久删除该列表。',
        byCanceling: '若您取消，不会有任何列表被删除',
        byCancelingYouEnsureThatNo: '通过取消，您可以确保没有',
        willBeRemoved: '会被移除',
        groupPeople: '人员',
        groupCompany: '公司',
        myList: '我的列表',
        teamList: "团队列表",
        resetAssured: "请放心，您可以根据需要灵活地在不同类型之间切换。",
        wontBeChanged: "通过取消，您可以确保此列表类型不会更改",
        priority: '优先级',
        proceedingWithDeletionWillResultInPeopleDelete: '继续删除将导致永久删除人员',
        proceedingWithDeletionWillResultInCompanyDelete: '继续删除将导致永久删除公司',
        areYouSureYouWantToRemoveCompanyFromList: '您确定要将已选中公司从下列列表中删除吗?',
        areYouSureYouWantToRemovePeopleFromList: '您确定要将已选中人员从下列列表中删除吗?',
        sorryTheListIsCurrrntlyOpen: '抱歉，列表当前处于打开状态。你确定要删除它吗？',
        listName: '名称',
        pleaseEnterAValidSiteUrl: '请输入有效的网站url',
        addYourCreditCardInformation: '添加您的信用卡信息',

        // market-intent
        comingSoon: '即将开放',
        weAreCurrentlyWorkingOnThisFeature: '我们目前正在开发此功能，并将很快推出',
        stayConnectedWithUs: '通过我们的社交媒体频道与我们保持联系以接收更新',
        selectAWebsite: '请选择一个网站',
        // sequence
        Sequences: '序列',
        label: '标签',
        template: '模板',
        folder: '文件夹',
        single: '单个',
        group: '组',
        steps: '步骤',
        sizes: '规模',
        time: '时间',
        addAnyAdditionalStepsOrConditionsAsNeeded: '根据需要添加任何额外的步骤或条件',
        reviewAndFinalizeSequenceSettingsAndPreferences: '审查并完成序列设置和首选项',
        //email
        SendATestEmail: '发送测试邮件',
        Categories: '类别',
        addToCategory: '添加到类别',
        EnterOrCreateCategories: '输入或创建类别',
        addSuccessfully: '添加成功',
        Channels: '频道',
        emails: '邮件',
        enterChannel: '输入频道',
        CategoryName: '类别名称',
        PleaseEnterANewCategoryName: '请输入新类别名称',
        EnterANewCategoryName: '输入新类别名称',
        Steps: '步骤',
        Actions: '操作',
        Time: '时间',
        From: '从',
        To: '到',
        CancelSequenceCreation: '取消序列创建',
        AreYouSureYouWantToCancelTheSequenceCreationProcess: '您确定要取消序列创建过程吗？',
        SaveAsDraft: '保存为草稿',
        CancelSequence: '取消序列',
        ByCancelingAllSettingsWillBeLost: "取消后，所有设置将丢失",
        ImportContacts: '导入联系人',
        PleaseUploadACSVFileByDraggingAndDroppingItHere: '请上传一个 CSV 文件，将其拖放到此处',
        ChooseAFile: '选择一个文件',
        CSVFileFields: 'CSV 文件字段',
        ReplyFields: '回复字段',
        continue: '继续',
        Contacts: '联系人',
        Back: '返回',
        Import: '导入',
        Imported: '已导入',
        Skipped: '已跳过',
        Done: '完成',
        NewCustomField: '新自定义字段',
        EnterNewLabel: '输入新标签',
        EnterNewType: '输入新类型',
        removeRecorded: '移除记录',
        YouHaveUnsavedChanges: '您有未保存的更改',
        LeavingDiscard: '离开将丢弃所有更改',
        StayPage: '留在本页',
        LeavePage: '离开',
        NewCategory: '新类别',
        Create: '创建',
        UnlockingSequence: '解锁此序列可进行修改',
        ModifyingLeadDeselection: '修改此内容将导致当前模板版本的选择取消',
        Modify: '修改',
        CancelingPreserveCurrent: '取消将保留当前版本',
        rename: '重命名',
        CreatNewLabel: '创建新标签',
        NewLabel: '新标签',
        PleaseEnterANewLabelName: '请输入新标签名称:',
        EnterANewLabelName: '输入新标签名称',
        SequenceName: '序列名称',
        PleaseProvideNameForNewSequence: '请提供新序列名称',
        EnterNewSequenceName: '输入新序列名称',
        SetUpEmail: '设置发送邮件的账户',
        NewSchedule: '新日程安排',
        PleaseEnterANewScheduleName: '请输入新日程名称',
        EnterNewScheduleName: '输入新日程名称',
        AddNewTemplate: '添加新模板',
        RemoveFromTemplate: '从模板中移除',
        deleteSuccess: '删除成功',
        removeSuccess: '移除成功',
        GroupName: '组名称',
        PleaseEnterNewGroupName: '请输入新组名称',
        EnterNewGroupName: '输入新组名称',
        TemplateName: '模板名称',
        PleaseEnterANewTemplateName: '请输入新模板名称',
        EnterNewTemplateName: '输入新模板名称',
        renameSuccessfully: '重命名成功',
        CreateSuccessfully: '创建成功',
        addToGroup: '添加到组',
        SelectChannelForNewTemplate: '选择新模板的频道',
        Role: '角色',
        Account: "账号",
        customFields: '自定义字段',

        contactsToStartEngagingWithThem: '联系人开始与他们接触',
        thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid: '此序列尚无任何潜在客户或线索。添加一些联系人开始',
        noProspectsAdded: '未添加客户',
        deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS: '当前未通过此序列发送任何消息',
        ensureEfficientCommunicationByMonitoringYourSequen: '通过监听您的序列，确保高效的沟通',
        noMessagesHaveBeenSent: '未发送任何消息',
        yourNeedsGetStartedByClickingNewSequence: '您的需求,点击“新序列”开始',
        beginBuildingYourSalesStrategiesByDesigningSequenc: '通过设计序列开始构建您的销售策略',
        noSequencesCreatedYet: '尚未创建序列',
        leadActions: '客户操作',
        leadInformation: '客户信息',
        thisGroupHasNoTemplatesYet: '此组尚无模板',
        key: '{expr}',
        copyContent: '复制内容',
        default: '默认',
        selectVersion: '选择版本',
        subject: '主题',
        searchTemplatesfolders: '搜索模板/文件夹',
        send: '发送',
        subject2: '主题:',
        enterASubjectOrLeaveItBlankToContinueInTheSameThre: '输入主题或留空以继续在同一线程中',
        chooseYourChannel: '选择您的频道',
        kl: 'KL',
        addContacts: '添加联系人',
        addContactsToSequence: '添加联系人到序列',
        addremoveCustomContactField: '添加/移除联系人的自定义字段',
        contactCustomFields: '联系人自定义字段',
        type: '类型',
        typePhoneNumber: '输入电话号码',
        addAPhoneNumber: '添加电话号码',
        fieldType: '字段类型',
        fieldLabel: '字段标签',
        skipTheFollowingNationalHolidaysLaborDayIndependen: '跳过以下国家假期：劳动节、独立日、感恩节、圣诞节、新年',
        checked: '已选中',
        useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi: '使用联系人的本地时区，而不是日程表的时区',
        definePostreplyOrMeetingActionAndSequenceCompletio: '定义后续回复或会议操作和序列完成',
        stopRepliesHandling: '停止回复处理',
        timeZone: '时区',
        country2: '国家',
        setMaxInitialEmailsDelaysEnabledisableOpensAndLink: '设置最大初始邮件延迟时间，启用/禁用打开和链接跟踪',
        configuration: '配置',
        additionalInformation: '附加信息',
        connectALinkedinAccount: '连接一个 LinkedIn 账户',
        newLinkedinAccount: '新的 LinkedIn 账户',
        feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR: '随时随地随意回顾以前的任何步骤，以便重新调整您的设置',
        template2: '模板',
        next: '下一步',
        settings2: '设置',
        editThisTemplateScheduleFromThe: '从“设置”选项卡编辑此模板/日程',
        typeEmail: '输入邮件',
        templateScheduling: '模板/日程安排',
        launch: '启动',
        linkedin: 'LinkedIn',
        addAEmail: '添加邮件',
        addEmailAccount: '添加邮件账户',
        addStep: '添加步骤',
        newEmailAccount: '新邮件账户',
        craftYourLeadgenerationJourneyWithANewSequenceDefi: '定义新序列，打造您的潜在客户生成之旅',
        craftYourCampaign: '打造您的活动',
        tailorYourOwnHoursToSuitSpecificSchedulingNeeds: '量身定制您自己的日程表',
        personalizedScheduling: '个性化日程表',
        requiredInformation: '必填信息',
        chooseEmailAccountsForSendingCampaignMessages: '选择发送活动消息的邮件账户',
        accounts: '账户',
        enterSequenceName: '输入序列名称',
        thisPageEmpowersYouToTailorYourSchedulingSequenceS: '量身定制您的日程表，满足您的特定需求',
        schedule: '日程安排',
        useThisOptionToAddContactsToTheSequenceYouHaveCrea: '使用此选项将联系人添加到您创建的序列中',
        save2: '保存',
        days: '天',
        days2: '天内',
        within: '在',
        opened: '已打开',
        opened2: '已打开',
        day: '天',
        waitFor: '等待',
        day2: '天内',
        sendTestEmailToMe: '给我发送测试邮件',
        create: '创建',
        enterANameForCustomContactField: '输入自定义联系人字段的名称',
        a: 'A',
        byCancelingYouEnsureThatNoStepsWillBeDeleted: '取消后，您可以确保不会删除任何步骤',
        to2: '到',
        enterNameForNewVersion: '输入新版本的名称',
        activated: '已激活',
        currentVersion: '当前版本',
        setTheNewVersionDefaultNow: '现在设置新版本为默认',
        enterNewName: '输入新名称',
        newVersion: '新版本',
        dontForgetToSetTheNewVersionDefaultOnceYouveFinish: '完成后，不要忘记设置新版本为默认',
        theDeletionOfThisConditionWillAlsoRemoveAllSubsequ: '删除此条件还将删除所有后续步骤',
        pleaseEnterANewName2: '请输入新名称',
        aNewVersionOfTheSequenceHasBeenCreated: '已创建序列的新版本',
        youCanEitherEditTheCurrentVersionOrSaveItAndCreate: '您可以编辑当前版本，也可以保存并创建新版本',
        areYouSureYouWantToProceed: '您确定要继续吗？',
        newVersionName: '新版本名称',
        sequenceStart: '序列开始',
        chooseActions: '选择操作',
        chooseSteps: '选择步骤',
        enterChannel2: '输入频道',
        chooseCategories: '选择类别',
        htmlMode: 'htmlMode',
        considerBroadeningYourSearchCriteria: ' - 考虑扩大您的搜索条件',
        checkYourSpellingAndMakeSureThereAreNoTypos: ' - 检查拼写，确保没有拼写错误',
        tryUsingDifferentKeywordsOrPhrases: '- 尝试使用不同的关键字或短语',
        noResultsFoundForYourSearchCriteria: '未找到符合您的搜索条件的结果',
        fromScratch: '从头开始创建',
        sender: '发送者',
        finished: '已完成',
        inProgress: '进行中',
        fromTemplate: '从模板创建',
        newSequence: '新序列',
        nameOwner: '名称/所有者',
        sort: '排序',
        ownedBy: '所有者',
        starredOnly: '仅标星',
        starred: '标星',
        add2: '添加',
        lastSynced: '最后同步时间',
        hourlyLimit: '每小时限制',
        dailyLimit: '每日限制',
        account: '账户',
        skipTheFollowingNationalHolidaysLaborDayIndependen2: '跳过以下国家假期：劳动节、独立日、感恩节、圣诞节、新年',
        useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2: '使用联系人的本地时区，而不是日程表的时区',
        saveChanges: '保存更改',
        emailSignature: '邮件签名',
        reset2: '重置',
        pleaseNoteTheValueShouldBe0OrEmails: '填写区间应大于0 或小于',
        youHaveNoScheduledTemplateYet: '您尚无已安排的模板',
        discoverPremiumOptions: '发现高级选项',
        mailbox: '邮箱',
        sendingLimits: '发送限制',
        connect: '连接',
        joinOurCommunityOfPremiumUsersTransformingEmailEng: '加入我们的高级用户社区，改变电子邮件营销的方式',
        enterYourEmailAddress: '输入您的电子邮件地址',
        toJustMailbox: '',
        yourCurrentBasicPlanAllowsAccess: '您当前的基本计划允许访问',
        setAsDefault2: '设置为默认',
        createNewSchedule: '创建新日程安排',
        enterTemplateName: '输入模板名称',
        googleMeet: '谷歌会议',
        emailPreview: '邮件预览',
        addTemplate: '添加模板',
        simplifyYourWorkflowGroupYourSequenceTemplatesToKe: '简化您的工作流程。将您的序列模板分组以保持组织',
        startCreatingPersonalizedTemplatesForYourSequences: '开始为您的序列创建个性化模板',
        noTemplateYet: '尚无模板',
        preview: '预览',
        creatNewGroup: '创建新组',
        stayOrganizedWithYourSequenceTemplatesCreateGroups: '通过创建组，保持您的序列模板组织有序',
        noGroupsHaveBeenCreated: '尚未创建任何组',
        creatNewTemplate: '创建新模板',
        addSequenceTemplateToTheGroup: '将序列模板添加到组',
        byCancelingYouEnsureThatNoChangesAreMade: '取消后，您可以确保不会进行任何更改',
        sequenceTemplates: '序列模板',
        youCanDeselectAndClickSaveToRemoveTheTemplateFromT: '您可以取消选择并单击“保存”以将模板从组中移除',
        selectTemplateToAddToGroup: '选择要添加到组的模板',
        sequenceStatus: '序列状态',
        sequenceStep: '序列步骤',
        newField: '新字段',
        enableTailoredDataEntryAndAdaptationToUniqueBusine: '启用定制数据输入和适应独特业务需求',
        customContactFields: '自定义联系人字段',
        modality: '模态',
        enterADescriptionForCustomContactField: '为自定义联系人字段输入描述',
        description2: '描述',
        enterADefaultValueForCustomContactField: '为自定义联系人输入默认值',
        defaultValue: '默认值',
        enterAExampleForCustomContactField: '为自定义联系人输入示例',
        example: '示例',
        enterPicklistoptionsForCustomContactField: '为自定义联系人字段输入选项',
        newCustomContactFields: '新自定义联系人',
        engagementMetrics: '参与度指标',
        message: '消息',
        to3: '到',
        receiver: '接收者',
        createNewCategory: '创建新类别',
        contacted: '已联系',
        sendTestEmail: '发送测试邮件',
        message2: '消息',
        includeSignature: '包括签名',
        continueOnFail: '失败后继续',
        replyToPreviousThread: '回复到以前的线程',
        sendAutomatic: '自动发送',
        searchByContacts: '按联系人搜索',
        processingErrorOccurredMaximumNumberOfUniqueContac: '发生处理错误。最大联系人数为 1000',
        shiftdragToMoveTheCanvas: 'Shift+拖动 以移动画布',
        active: '活跃',
        inactive: '停用',
        archived: '已归档',
        archive: '归档',
        unarchive: '取消归档',
        archiveSuccessfully: '归档成功',
        unarchiveSuccessfully: '取消归档成功',
        sequenceArchiveSuccess: '归档成功',
        sequenceUnarchiveSuccess: '取消归档成功',
        alphabetical: '按字母排序',
        creationDate: '按创建日期排序',
        viewDate: '按查看日期排序',
        staredFirst: '标星优先',
        SaveAsANewVersion: '保存为新版本',
        SaveCurrentVersion: '保存当前版本',
        schedules: '日程安排',
        EmailsSentPerDay: '每日发送邮件',
        EmailsSentPerHour: '每小时发送邮件',
        DelayBetweenEachEmailSent: '每封邮件发送之间的延迟',
        RecommendedDailyLimit: '建议的每日限制：50-100 封电子邮件，只有当您发送高度个性化的营销活动并保持良好的域声誉时，100 封以上的邮件除外。',
        RecommendedHourlyLimit: '建议的每小时限制：将电子邮件数量控制在 100 封以下。每小时不要超过 120 封电子邮件。',
        currentDelayAllows: '当前的延迟允许您每小时最多发送 120 封电子邮件。',
        SetAsDefault: '设置为默认',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
        Templates: '模板',
        Analytics: '统计分析',
        Meetings: '会议',
        Settings: '设置',
        EnterTemplateName: '输入模板名称',
        EnterGroupName: '输入组名称',
        ExportAllData: '导出所有数据',
        ExportAsCSV: '导出为 CSV',
        Total: '总数',
        Opened: '已打开',
        Clicked: '已点击',
        NotOpened: '未打开',
        Bounced: '退信',
        Replied: '已回复',
        Failed: '失败',
        Inbox: '收件箱',
        Report: '报告',
        Log: '日志',
        UseThisTemplate: '使用此模板',
        UseThisFolder: '使用此组',
        UpdateThisTemplate: '更新此模板',
        RemoveFromGroup: '从组中移除',
        DeleteThisTemplate: '删除此模板',
        MarkAsFinished: '标记为完成',
        Remove: '移除',
        Pause: '暂停',
        Resume: '恢复',
        AddToSequence: '添加到序列',
        Export: '导出',
        InProgress: '进行中',
        Finished: '已完成',
        Paused: '已暂停',
        Engaged: '已参与',
        Reply: '回复',
        NotSent: '未发送',
        MaxNumberOfEmailsPerDay: '每日最大邮件数',
        MaxNumberOfEmailsPerHour: '每小时最大邮件数',
        EnableOpensTracking: '启用打开跟踪',
        EnableReplyTracking: '启用回复跟踪',
        EnableClickTracking: '启用点击跟踪',
        ReplyByMessage: '通过消息回复',
        BookAMeeting: '预约会议',
        ClickOnLink: '点击链接',
        CreateManually: '手动创建',
        AddContactsToTheSequenceManually: '手动将联系人添加到序列',
        ImportFromCSV: '从 CSV 导入',
        UploadCSVFileToAddContactsToSequence: '上传 CSV 文件以将联系人添加到序列',
        AddFromExisting: '从现有联系人添加',
        AddExistingContactsIntoTheSequence: '将现有联系人添加到序列',
        ChooseFile: '选择文件',
        MapAttributes: '映射属性',
        Options: '选项',
        ReviewAndImport: '查看并导入',
        account_website: '公司网站',
        linkedin_url: '领英URL地址',
        raw_address: '地址',
        first_name: '名字',
        last_name: '姓氏',
        account_name: '公司名称',
        Identifier: '标识符',
        firstName: '名字',
        lastName: '姓氏',
        EnterJobTitleOrPosition: '输入职位或职位',
        EnterCompanyName: '输入公司名称',
        EnterYourCustomerID: '输入您的客户 ID',
        EnterCompanyWebsiteURL: '输入公司网站 URL',
        EnterLinkedInProfileURL: '输入领英个人资料 URL',
        EnterCity: '输入城市',
        versionName: '版本名称',
        PleaseEnterANewVersionName: '请输入新的版本名称',
        EnterNewVersionName: '输入新的版本名称',
        versions: '版本',
        DuplicateVersionSuccessfully: '成功复制版本',
        SequenceNameUpdatedSuccessfully: '序列名称更新成功',
        SequenceNameCannotBeEmpty: '序列名称不能为空',
        delete_success: '删除成功',
        delete_fail: '删除失败',
        set_default_success: '设置默认成功',
        CreateNewVersionSuccessfully: '成功创建新版本',
        UpdateSuccessfully: '更新成功',
        AddToGroupSuccessfully: '成功添加到组',
        sendAutomaticEmail: '发送自动邮件',
        sendMessage: '发送消息',
        uploadFailed: '上传失败',
        InvalidEmailFormat: '无效的电子邮件格式',
        FailedToSetUpEmailAccount: '设置电子邮件账户失败',
        noAccount: '无账户',
        addAnAccountToPersonalizYourExperienceAndUnlock: '添加账户以个性化您的体验并解锁',
        trackingFeaturesForThisSequence: '此序列的追踪功能',
        UpdatedSuccessfully: '更新成功',
        update_fail: '更新失败',
        connectYourGoogleMeetAccountToPubrioConversations: '将您的 Google Meet 账户连接到 Pubrio 会话',
        set_default_fail: '设置默认失败',
        duplicatedSuccessfully: '成功复制',
        noStepsInSequencePleaseAddStepsFirst: '序列中没有步骤，请先添加步骤',
        addLabelSuccessfully: '成功添加标签',
        PleaseEnterSequenceName: '请输入序列名称',
        sequenceNameCannotBeEmpty: '序列名称不能为空',
        sequenceNameUpdatedSuccessfully: '序列名称更新成功',
        createStepFlowSuccessfully: '创建步骤流程成功',
        updateStepFlowSuccessfully: '更新步骤流程成功',
        testEmailSentSuccessfully: '测试邮件发送成功',
        removeEmailAccountSuccessfully: '成功移除邮件账户',
        pleaseSelectTheDataToBeExported: '请选择要导出的数据',
        confirm: '确认',
        saveFailed: '保存失败',
        deleteThisStep: '删除此步骤',
        Email: '邮件',
        LinkedIn: '领英',
        API: 'API',
        Task: '任务',
        Custom: '自定义',
        proceedImmediately: '立即进行',
        dayss: '天',
        hourss: '时',
        minutess: '分',
        theConditionAndAllTheStepsThatFollowFromItWillBeDeleted: '该条件及其后续的所有步骤都将被删除，是否继续？',
        sendConnectionRequest: '发送连接请求',
        viewProfile: '查看个人资料',
        callAnAPI: '调用 API',
        createAManualTask: '创建手动任务',
        hasEmailAddress: '有电子邮件地址',
        hasLinkedInURL: '有领英 URL',
        hasPhoneNumber: '有电话号码',
        customCondition: '自定义条件',
        openedEmail: '已打开邮件',
        clickOnLinkInEmail: '点击邮件中的链接',
        unsubscribeFromEmail: '取消订阅邮件',
        bookAMeeting: '预约会议',
        acceptedInvite: '接受邀请',
        conditions: '条件',
        Yes: ' 是 ',
        No: ' 否 ',
        feelFreeToEditTheContentAndSaveYourChangesDirectly: '随时随地编辑内容并直接保存更改',
        activateTheNewVersionNow: '立即激活新版本',
        newVariable: '新变量',
        selectVariable: '选择变量',
        ifLeadVariable: '如果变量',
        condition: '条件',
        selectCondition: '选择条件',
        value: '值',
        contains: '包含',
        is_equal_to: '等于',
        is_empty: '为空',
        is_not_empty: '不为空',
        picture: '图片',
        company_domain: '公司域名',
        company_name: '公司名称',
        icebreaker: '破冰',
        change_steps_conditions: '更改步骤条件',
        ForceASpecificSenderForThisStep: '为此步骤强制指定发送者',
        method: '方法',
        selectASender: '选择发送者',
        assignAPITaskTo: '分配 API 任务给',
        enterCompanyNamesAndPressEnterKey: '输入公司名称并按 Enter 键',
        nameOnCard: '卡上姓名',
        expiryDate: '到期日期',
        cvccvv: 'CVC/CVV',
        details: '详情',
        notification: '通知',
        markAsAllRead: '全部标记为已读',
        Personal: '个人',
        profile: '个人资料',
        extensions: '扩展',
        notifications: '通知',
        Company: '公司',
        roles: '角色',
        teams: '团队',
        'current-usage': '当前使用情况',
        'upgrade-plan': '升级计划',
        Products: '产品',
        APIKeys: 'API密钥',
        popular: '热门',
        free: '免费',
        lite: '精简',
        growth: '增长',
        business: '商业',
        enterEmailAddressesMultipleCommaSeparated: '输入电子邮件地址（多个以逗号分隔）',
        sharingRulesSelectTeamToEnableThisField: '共享规则：选择团队以启用此区域',
        allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges: '所有座位目前已被占用。任何需要付费座位的新用户将产生额外费用。',
        dueToday: '到期',
        costPerBillingCycle: '每个计费周期的费用',
        'select...': '请选择...',
        invite: '邀请',
        services: '服务',
        'services-group': '服务组',
        'all-notification-services': '所有通知服务',
        'services-status-update': '服务状态更新',
        'system-group': '系统组',
        'all-notification-system': '所有系统通知',
        'system-new-feature-release': '系统新功能发布',
        'system-news-letter': '系统新闻通讯',
        'system-generic-alarm': '系统通用警报',
        markAsRead: '标记为已读',
        showLess: '显示更少',
        showMore: '显示更多',
        changedStatusTo: '更改状态为',
        cardInfo: '卡片信息',
        creditCardDetails: '信用卡详细信息',
        currentBalance: '当前余额',
        enableAutoTopups: '启用自动充值',
        automaticallyTopUp: '自动充值',
        whenBalanceFallsBelow: '当余额低于',
        'access-management-group': '访问管理组',
        'access-management-roles': '访问管理角色',
        'access-management-create-roles': '访问管理创建角色',
        'access-management-edit-user-roles': '访问管理编辑用户角色',
        'access-management-add-role-to-users': '访问管理为用户添加角色',
        'access-management-delete-users-role': '访问管理删除用户角色',
        'access-management-delete-roles': '访问管理删除角色',
        'access-management-read-roles-and-role-users': '访问管理读取角色和角色用户',
        'access-management-teams': '访问管理团队',
        'access-management-delete-users-team': '访问管理删除用户团队',
        'access-management-read-teams-sharing-rules-teams-users': '访问管理读取团队共享规则及团队用户',
        'access-management-create-teams': '访问管理创建团队',
        'access-management-edit-teams-sharing-rules': '访问管理编辑团队共享规则',
        'access-management-delete-teams-sharing-rules': '访问管理删除团队共享规则',
        'access-management-add-and-update-team-to-users': '访问管理添加和更新团队到用户',
        'access-management-read-team-relations': '访问管理读取团队关系',
        'access-management-modify-team-relations': '访问管理修改团队关系',
        'intent-signal-group': '意图信号组',
        'market-intent-signal': '市场意图信号',
        'web-intent-signal': '网络意图信号',
        'web-intent-signal-add-and-activate': '网络意图信号添加并激活',
        'web-intent-signal-read-data': '网络意图信号读取数据',
        'web-intent-signal-export-data': '网络意图信号导出数据',
        'web-intent-signal-delete-data': '网络意图信号删除数据',
        'web-intent-signal-read-analytic': '网络意图信号读取分析',
        'search-group': '搜索组',
        'company-search': '公司搜索',
        'prospects-search': '前景搜索',
        'sequence-group': '序列组',
        'sequences': '序列',
        'service-create-request': '服务创建请求',
        'service-update-request': '服务更新请求',
        'service-read-request': '服务读取请求',
        'service-delete-service-attachment': '服务删除附件',
        'service-delete-service-message': '服务删除消息',
        'service-send-and-edit-service-message': '服务发送并编辑消息',
        'service-upload-service-attachment': '服务上传附件',
        'settings-group': '设置组',
        'custom-fields': '自定义字段',
        'user-management-and-billing-group': '用户管理和计费组',
        'billing-read-credit-card': '计费读取信用卡',
        'billing-download-invoice': '计费下载发票',
        'billing-read-invoice': '计费读取发票',
        'credit-usage': '信用使用',
        'credit-usage-read-profile-detail': '信用使用读取配置文件详细信息',
        'credit-usage-read-profile-usage': '信用使用读取配置文件使用情况',
        'profile-and-team': '配置文件和团队',
        'profile-and-team-invite-team-member': '配置文件和团队邀请团队成员',
        'profile-and-team-update-profile': '配置文件和团队更新配置文件',
        'profile-and-team-delete-team-member': '配置文件和团队删除团队成员',
        'profile-and-team-get-user-secret-token': '配置文件和团队获取用户秘密令牌',
        'profile-and-team-update-user-and-password': '配置文件和团队更新用户和密码',
        'profile-and-team-read-profile-users': '配置文件和团队读取配置文件用户',
        'upgrade-plan-read-subscription': '升级计划读取订阅',
        'upgrade-plan-upgrade-subscription': '升级计划升级订阅',
        'all-access-access-management-roles': '全访问访问管理角色',
        'all-access-access-management-teams': '全访问访问管理团队',
        'all-access-market-intent-signal-page': '全访问市场意图信号页面',
        'all-access-web-intent-signal-page': '全访问网络意图信号页面',
        'all-access-company-search-page': '全访问公司搜索页面',
        'all-access-prospects-search-page': '全访问前景搜索页面',
        'all-access-sequence-page': '全访问序列页面',
        'all-access-service-page': '全访问服务页面',
        'all-access-custom-fields-page': '全访问自定义字段页面',
        'all-access-billing-page': '全访问计费页面',
        'all-access-credit-usage-page': '全访问信用使用页面',
        'all-access-profile-and-team-page': '全访问配置文件和团队页面',
        permission: '权限',
        newPermissionProfile: '新权限配置文件',
        enterAName: '输入名称',
        addUserToRole: '添加用户到角色',
        changePermission: '更改权限',
        selectPermissionProfiles: '选择权限配置文件',
        backToRoles: '返回角色',
        updateRole: '更新角色',
        enterADescription: '输入描述',
        createANewRole: '创建新角色',
        AreYouSureYouWantToRemoveThisRole: '您确定要删除此角色吗？',
        AreYouSureYouWantToRemoveThisUserFromTheTeam: '您确定要从团队中删除此用户吗？',
        ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser: '继续删除将永久删除用户的角色。',
        ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam: '继续删除将永久删除用户的团队。',
        addUserToTeam: '添加用户到团队',
        user: '用户',
        sharingRuleGroup: '共享规则组',
        updateTeam: '更新团队',
        createANewTeam: '创建新团队',
        backToTeams: '返回团队',
        listOfSharingRules: '共享规则列表',
        sharingRules: '共享规则',
        manageSharingRules: '管理共享规则',
        CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules: '创建和自定义共享规则以控制不同用户的访问权限。单击下面的按钮添加新的共享规则。',
        newSharingRules: '新共享规则',
        'full-sharing-market-intent-signal-data': '完全共享市场意图信号数据',
        'full-sharing-web-intent-signal-data': '完全共享网络意图信号数据',
        'full-sharing-company-search-data': '完全共享公司搜索数据',
        'full-sharing-prospects-search-data': '完全共享前景搜索数据',
        'full-sharing-sequence-data': '完全共享序列数据',
        'full-sharing-service-data': '完全共享服务数据',
        'share-service-attachments': '共享服务附件',
        'share-service-messages': '共享服务消息',
        relation: '关系',
        newTeam: '新团队',
        RenameSharingRules: '重命名共享规则',
        enterNewSharingRulesNameBelow: '在下面输入新的共享规则名称：',
        enterNewSharingRulesName: '输入新的共享规则名称',
        enterNewSharingRulesDescriptionBelow: '在下面输入新的共享规则描述：',
        enterNewSharingRulesDescription: '输入新的共享规则描述',
        enterSharingRulesName: '输入共享规则名称',
        currentUsers: '当前用户',
        pendingUsers: '待定用户',
        addRole: '添加角色',
        addTeam: '添加团队',
        addPermission: '添加权限',
        'Re-invite': '重新邀请',
        relations: '关系',
        'is-parent-of': '是父级',
        addRelation: '添加关系',
        deleteRelation: '删除关系',
        updateRelation: '更新关系',
        API_Keys: 'API 密钥',
        createNewKey: '创建新密钥',
        createAPIKey: '创建 API 密钥',
        APIs: 'API',
        setAsMasterKey: '设置为主密钥',
        'growth-annual': "年度增强",
        business: "商务",
        "website-intent-data": "网站趋势数据",
        'demo-identification': "演示标识",












        // services
        serviceMenuOverview: '概览',
        serviceMenuTeams: '团队',
        serviceMenuPipeline: '管道',
        serviceMenuAnalytics: '分析',
        serviceMenuResources: '资源',
        salesServices: '销售服务',
        chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations: '选择您的服务类型以简化和增强您的业务运营',
        dataPreparation: '数据准备',
        salesAssistant: '销售助理',
        appointmentSetting: '会议设置',
        othersCustomService: '其他 (自定义服务)',
        requestService: '请求服务',
        learnMore: '了解更多',
        serviceRequestStatus: '服务请求状态',
        noServiceRequestsYet: '尚无服务请求',
        submitYourFirstRequestToUnlockThePower: '提交您的第一个请求以解锁我们销售服务的力量！在这里跟踪您的请求状态，让您的销售工作效能倍增。让我们开始吧！',
        serviceTableItemName: '名称',
        serviceTableItemStatus: '状态',
        serviceTableItemType: '类型',
        serviceTableItemProgress: '进度',
        serviceTableItemStart: '开始',
        serviceTableItemEnd: '结束',
        serviceTableItemPriority: '优先级',
        addASubject: '主题',
        created: '已创建',
        closed: '已关闭',
        paused: '已暂停',
        working: '进行中',
        completed: '已完成',
        resuming: '恢复中',
        reviewing: '审查中',
        others: '其他',
        data_preparation: '数据准备',
        sales_assistant: '销售助理',
        appointment_setting: '预约设置',
        appointment_booking: '预约',
        low: '低',
        normal: '普通',
        urgent: '紧急',
        high: '高',
        requiredCredits: '所需信用点',
        serviceCreated: '创建时间',
        QandARecord: '问题记录',
        serviceAttachments: '附件',
        discussionBoard: '讨论板',
        wannaAddSomething: "想添加一些内容?",
        serviceEdit: '编辑',
        serviceDelete: '删除',
        addDescription: '添加描述',
        DragDropor: '拖放或',
        chooseFile: '选择',
        toUpload: '来上传',
        ourDataPreparationService: '我们的数据准备服务提供量身定制的全面解决方案，以优化您的销售数据管理。我们专注于简化流程，使您能够增强现有数据集，通过数据清理确保准确性，并创建完全符合您需求的定制列表。这种方法为您提供了有效管理数据所需的灵活性和效率。',
        moreoverWithOurDedicatedDataManagement: '此外，有了我们专门的数据管理服务Pubrio，这个过程变得更加轻松。您可以无缝地附加文件，并使用附加信息扩充数据集。Pubrio提供的不仅仅是丰富；它还允许根据您的特定标准创建全新的列表。这项个性化服务确保您的数据集经过精确定制，以满足您的目标和行业标准，让您能够控制您的数据格局。',
        flexibilityToAttach: "可以灵活地附加现有列表或指示Pubrio根据指定标准查找新列表。",
        comprehensiveDataEnrichment: "全面的数据丰富，添加缺失的细节，增强数据集的完整性。",
        dataCleansingServicesToEnsureAccuracy: "数据清理服务，以确保准确性和质量，包括删除重复和纠错。",
        abilityToInstructPubrio: "能够指导Pubrio根据精心定义的标准策划全新的列表。",
        tailoringDatasetsPrecisely: '精确定制数据集，以符合特定目标和行业标准。',
        dataEnrichmentWithExistingList: "利用现有列表进行数据扩展：通过将当前列表上传到Pubrio，轻松增强当前列表。我们将填写缺失的详细信息，如电子邮件地址和电话号码，以满足您的要求。例如，提供您的客户数据库，我们将使用重要信息对其进行更新。",
        customListGenerationIf: "自定义列表生成：如果你没有列表，让Pubrio为你找到一个。只要告诉我们你的标准，比如职位或行业，我们就会创建一个完美的列表。例如，如果您需要一份特定领域的顶级技术高管名单，我们将为您处理。",
        dataCleansingForExistingLists: "现有列表的数据清理：使用Pubrio的数据清理服务确保您的数据准确可靠。将您的列表发送给我们，我们将删除重复项并更正任何错误。例如，如果您有一份最近的潜在客户列表，我们将确保它已为您的营销活动做好准备。",
        empoweringYourSalesEeavors: "我们的销售助理服务为您的销售工作提供支持，可在各个关键领域提供全面的支持，确保您的外联和沟通策略得到精心调整，以取得成功。无论您是希望制作引人注目的内容、无缝组织活动、开发量身定制的演示文稿，还是完善您的销售和沟通策略，我们的专职团队都会在这里加大您的销售力度，提升您的品牌在市场上的影响力。",
        craftingCompellingContent: "制作引人入胜的内容：有效的沟通是吸引观众注意力的关键。我们的团队擅长根据您的品牌和受众偏好量身定制引人入胜的内容。从发人深省的LinkedIn帖子到信息丰富的博客文章和引人注目的社交媒体更新，我们确保您的信息与您的目标市场产生共鸣。",
        seamlessEventAndWebinarPreparation: "无缝的活动和网络研讨会准备：活动和网络会议是与观众互动和产生潜在客户的强大工具。我们的销售助理服务负责从计划、促销到执行的所有细节。无论是举办信息丰富的网络研讨会还是组织网络活动，我们都会确保您的活动给人留下持久的印象。",
        tailoredContentDevelopmentAndPresentation: "量身定制的内容开发和演示：通过引人入胜的演示和个性化信息来吸引您的受众。我们的团队专门致力于打造具有视觉吸引力的套牌，并精心制作能与特定受众群体产生共鸣的定制信息。无论您是向投资者推介还是培养潜在客户，我们都会确保您的内容脱颖而出。",
        strategicSalesAndCommunicationFrameworks: "战略销售和沟通框架：通过战略指导和有效的沟通脚本简化您的销售流程。我们的销售助理服务为您的团队提供了推动转化和建立有意义的联系所需的工具和策略。",
        craftingCompellingContent: "制作引人入胜的内容",
        companyLinkedInPosts: "公司领英帖子（频率：每周或每月）",
        personalLinkedInPosts: "个人LinkedIn帖子（频率：灵活，基于偏好和内容可用性）",
        websiteBlogPosts: "网站博客文章（频率：通常每周一次或每两周一次）",
        socialMediaPosts: "社交媒体帖子（频率：每日或根据内容策略）",
        emailMarketingCampaigns: "电子邮件营销活动（频率：每周或每两周）",
        seamlessEventAndWebinarPreparation: "无缝的活动和网络研讨会准备",
        webinarAndWorkshopPreparation: "网络研讨会和研讨会准备",
        networkingAndOfflineEvent: "网络和线下活动协调",
        tailoredContentDevelopment: "量身定制的内容开发和演示",
        companyDeckPreparation: "公司甲板准备（用于演示、推介等）",
        highlyPersonalizedMessage: "高度个性化的消息创建（针对特定受众群体量身定制）",
        strategicSales: "战略销售和沟通框架",
        salesCallScripts: "销售电话脚本",
        emailOutreachTemplate: "电子邮件外联模板",
        salesChannelStrategies: "销售渠道策略",
        ourAppointmentSettingService: "我们的预约设置服务旨在通过促进重要联系、促进行业曝光率和培养有价值的前景来提升您的业务。我们专注于个性化的行业曝光、无缝的研讨会和网络研讨会协调、预热新的前景，并向新的受众发起吸引人的方法。",
        individualIndustryAppointment: "个别行业预约曝光与反应",
        tailoredOutreach: "针对行业内的关键决策者和影响者进行量身定制的宣传，确保有针对性的曝光。",
        strategicFollowups: "战略跟进使我们能够评估反应，解决问题，并促进更深入的参与。",
        eachInteraction: "每一次互动都是根据接受者的个人资料和偏好进行个性化的，最大限度地发挥影响。",
        seminarWebinarCoordination: "研讨会和网络研讨会协调",
        ourExpertTeamHandles: "我们的专家团队负责研讨会和网络研讨会协调的各个方面，从最初的规划到活动后的跟进。",
        weMeticulouslyPlan: "我们精心策划和执行活动，以展示您的专业知识，确保参与者获得无缝和引人入胜的体验。",
        byLeveragingAdvancedTechnologyPlatforms: "通过利用先进的技术平台，我们加强互动和参与，最大限度地提高您的活动的影响力。",
        newProspectWarmUp: "新客户预热",
        proactiveEngagementStrategies: "采用积极主动的参与战略，为新的前景预热，为富有成果的讨论奠定基础。",
        tailoredCommunicationStrategies: "量身定制的沟通策略旨在建立融洽的关系、信任和对您的产品的兴趣。",
        throughStrategicNurturing: '通过战略培育，我们让潜在客户参与进来，并为转换做好准备，增加成功任命的可能性。',
        newAudienceApproach: "新受众方法",
        weEmployInnovativeStrategies: "我们采用创新的策略来接触新的受众，并将您的业务范围扩大到传统渠道之外。",
        targetedOutreachCampaigns: "有针对性的外联活动是在彻底的受众研究和细分的基础上精心制定的，以确保相关性和有效性。",
        ourApproachIsContinuouslyOptimized: "我们的方法基于反馈和见解不断优化，确保我们在接触和吸引新受众方面处于领先地位。",
        individualIndustry: "单独行业预约曝光与反应",
        personalizedAppointmentSetting: "与行业利益相关者的个性化预约设置。",
        strategicFollowupCommunication: "战略性后续沟通以进一步参与。",
        comprehensivePlanning: "全面规划和执行活动。",
        utilizationOfAdvancedTechnologies: "利用先进技术增强互动。",
        newProspectWarmUp: "新前景热身",
        proactiveEngagementStrategy: "针对前景的主动参与策略。",
        customizedCommunication: "定制沟通以保持兴趣。",
        newAudienceApproach: "新观众方法",
        innovativeOutreach: "为新观众设计的创新性外展活动。",
        continuousRefinement: "根据反馈和洞察不断完善。",
        customService: "定制服务",
        tailoredToMeet: "我们的定制服务可满足您的独特需求，提供灵活性和适应性，以满足各种需求。无论您是在网络研讨会参与者跟踪、线下活动推广、广告增强还是任何其他专业任务方面寻求帮助，我们的专职团队都会帮助您高效地实现目标。",
        webinarParticipantsTracking: "网络研讨会参与者跟踪",
        comprehensiveTrackingOfWebinar: "全面跟踪网络研讨会参与者，分析参与度并收集有价值的见解。",
        customizedReportsAndAnalytics: "定制报告和分析，以评估网络研讨会的有效性并优化未来战略。",
        seamlessIntegration: "与现有系统无缝集成，实现简化的数据管理和报告。",
        offlineEventPromotion: "线下活动推广",
        strategicPlanning: "战略规划和执行线下活动推广活动，以最大限度地提高出席率和参与度。",
        utilizationOfTargetedMarketingChannels: '利用有针对性的营销渠道和促销策略，有效地接触到您想要的受众。',
        creativeApproaches: '针对每个活动的独特特点量身定制的创造性方法，确保最大的影响力和投资回报率。',
        adsEnhancement: "广告增强",
        optimizationOfAdCampaigns: "优化各种平台上的广告活动，以提高可见性、参与度和转化率。",
        ABTesting: "A/B测试和性能分析，以确定最有效的广告创意、信息传递和目标定位策略。",
        continuousMonitoring: "持续监控和调整广告活动，以适应不断变化的市场动态并最大限度地提高效果。",
        customizedSolutions: "解决方案",
        ourCustomService: "我们的定制服务超越了预定义的功能，以满足您的特定要求和偏好。",
        whetherYouNeedAssistance: "无论您在数据分析、内容创建、战略规划或业务的任何其他方面是否需要帮助，我们都可以根据您的需求定制我们的服务。",
        ourFlexibleApproach: "我们灵活的方法使我们能够与您密切合作，开发定制的解决方案，带来切实的成果并推动业务增长。",
        setupAndImplementation: "建立和实施网络研讨会参与者跟踪系统。",
        customizedReporting: "定制报告和分析，以衡量网络研讨会的表现和观众参与度。",
        developmentAndExecution: "制定并执行有针对性的线下活动推广活动。",
        coordinationOfPromotionalActivities: "协调促销活动，包括印刷材料、直邮和本地广告。",
        evaluationAndOptimization: "评估和优化跨数字平台的广告活动。",
        creativeDevelopment: "创造性开发、目标细化和绩效监控，以最大限度地提高投资回报率。",
        tailoredServices: "针对特定需求和目标量身定制的服务。",
        collaborationWithOurTeam: "与我们的团队合作，开发满足您独特需求的定制解决方案。",
        serviceGetStarted: "开始",


        keyFeatures: '主要特点',
        dataEnrichment: '数据丰富',
        dataCleansing: "数据整理",
        customListGeneration: "自定义列表生成",
        serviceOfferings: "提供的服务",
        introDataPreparation: "我们的数据准备服务提供量身定制的全面解决方案，以优化您的销售数据管理。我们专注于。。。",
        introSalesAssitant: '销售助理服务超越了一般的行政支持，它专门专注于销售流程中不可或缺的任务。它。。。',
        introAppointmentSetting: "这项服务包括一种个性化的、动手操作的方法，根据提供的服务将客户与其目标受众联系起来。。。",
        introOtherService: '“其他”服务允许客户通过提交有关其独特需求的详细信息来请求定制服务。。。',
        questionCommonPagePriorityLevelTitle: '您对此任务的优先级是多少？',
        questionCommonPagePriorityLevelDesc: '说明您的任务优先级有助于我们及时满足您的需求。您可以在提交后更改它。',
        questionCommonPageTimelineTitle: '考虑订阅条款或时间表？',
        questionCommonPageTimelineDesc: '让我们知道您的首选时间以及您计划何时开始。',
        questionCommonPageServiceRequestTitle: '准备好开始了吗？首先，给你的应用程序一个独特的名称',
        questionCommonPageServiceRequestDesc: "我们将使用它来跟踪和更新您的仪表板中的进度。",
        questionCommonPageServiceDurationTitle: "您的服务期会持续多久？",
        quesitonCommonPageInvestmentBudget: "您用于发展业务和项目的投资预算，是否超过每个月12000港币？",
        questionCommonPageHearAboutUs: '您是从哪里听说我们的？',
        questionCommonPageUploadTitle: '请在此处上传您的Excel/CSV文件以进行数据丰富或数据清理',
        questionCommonPageUploadDesc: "如果您选择创建自定义列表生成，则可以跳过或单击“下一步”而不上传",
        questionDataPreparationPageInterestedDataOptimizationTitle: '您对数据优化的哪些服务感兴趣？',
        questionDataPreparationPageInterestedDataOptimizationDesc: '您需要在下一步中附加一个CSV文件，以便选择数据富集或数据清理',
        questionDataPreparationPageDataAttributesTitle: '选择要合并到文件中的特定数据属性（如果适用）',
        questionDataPreparationPageDataAttributesDesc: '选择一个或者多个',
        questionDataPreparationPageNumberOfLeadsTitle: '您希望为您的业务产生或获得多少潜在客户？',
        questionDataPreparationPageNumberOfLeadsDesc: '指定数量或范围，为您定制我们的服务',
        slugServiceName: '服务名称',
        slugServiceDesc: '服务描述',
        errEnterServiceName: '请输入服务名称',
        errEnterDescription: "请输入服务描述",
        errSelectAService: "请选择一个服务",
        errSelectSpecificDataAttributes: "请选择具体的数据属性",
        errInputTheQuantity: "请输入数量",
        errSelectPriorityLevel: "请选择优先级",
        errSelectTimeline: "请选择时间",
        errInputContent: "请输入内容",
        errInvestmentBudget: "请选择是或否",
        errServiceDuration: "请选择服务期限",
        errHearAboutUs: '请选择听说渠道',
        placeHolderEnterServiceName: "请输入服务名称",
        placeHolderEnterServiceDescription: '请输入服务描述',
        placeHolderEnterListGeneration: "输入生成的列表",
        placeHolder150TO250: 'placeHolder150TO250',


        serviceDownload: "下载",
        serviceExample: '例子',
        forYourReference: '作为您的参考',
        yourFileNeedsToInclude: '您的文件至少需要包含网站或公司名称',
        includeAll: '选择全部',
        serviceNext: '下一步',
        serviceBack: "上一步",
        data_enrichment: "数据丰富",
        data_cleansing: "数据整理",
        custom_list_generation: "自定义列表生成",
        job_title: "职位名称",
        timeline_within_one_weeks: "一周之内",
        timeline_one_to_two_weeks: '一到两周',
        timeline_two_to_four_weeks: "二到四周",
        timeline_recurring: "循环的",
        timeline_custom_range: "自定义范围",
        hear_about_us_social_media: '社交媒体',
        hear_about_us_google: '谷歌',
        hear_about_us_online_article: "网上的文章",
        hear_about_us_webinar: '网络研讨会',
        hear_about_us_referral: "介绍",
        hear_about_us_custom_input: '其他',
        duration_last_than_one_month: "超过一个月",
        duration_one_to_three_months: '一到三个月',
        duration_over_three_months: '超过三个月',
        duration_decide_later: "之后再说",
        yes: "是",
        no: "否",
        byCancelingYouCanEnsureThatNoTasksWillBeClosed: '通过取消，可以确保不会关闭任何任务',
        areYouSureYouWantToCloseThisTask: '确定要关闭此任务?',
        onceTheTaskIsClosed: "一旦任务关闭，它就无法撤消，您也将无法继续执行它",
        Close: '关闭',
        ourTeamIsWorkingOnThisTask: "我们的团队正在完成这项任务",
        pleaseProviceYourReasonForThisAction: "请填写终止原因",
        sendRequest: '发送请求',
        OK: "好的",
        areYouSureYouWantToDelete: "您确定要删除",
        proceedingWithDeletionWillResultInPermanentRemovalOfTheFile: "继续删除将导致文件被永久删除。",
        byCancelYouEnsureThatNoFileWillBeDeleted: "通过取消，可以确保不会删除任何文件",
        areYouSureYouWantToDeleteThis: "您确定要删除这个",
        serviceMessage: "消息",
        serviceFile: "文件",
        proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage: "继续删除将导致永久删除此信息。",
        proceedingWithDeletionWillResultInPermanentRemovalOfThisFile: "继续删除将导致永久删除此文件。",
        youEnsureThatNoMessagesWillBeDeleted: "通过取消，您可以确保不会删除任何消息",
        youEnsureThatNoFilessWillBeDeleted: "通过取消，您可以确保不会删除任何文件",
        serviceDeleteFail: '删除失败',
        enterYourReason: "请输入您的原因",
        seriveStartDate: "开始日期",
        serviceEndDate: "结束日期",
        yourRequestHasBeenSent: "您的请求已发送",
        sendUsYourFeedback: "给我们发送反馈",
        weAreConstantlyImproving: "我们在不断进步！分享您的反馈，塑造我们平台的未来。",
        describeYourExperienceHere: "请在此处描述您的体验。。。",
        additionallyYouCanAttachAnyScreenshots: "此外，您可以附加任何屏幕截图或文件以提供更多详细信息。",
        sendFeedback: '发送反馈',
        serviceResume: '继续',
        yourRequestHasBeenResumed: '您的请求已恢复',
        yourRequestIsCurrentlyPaused: '您的请求当前已暂停',
        serviceEdited: '已编辑',
        theFileSizeCannotExceed50M: "文件大小不能超过50M",
        feedbackSendSuccessfully: "反馈成功",
        feedbackSendFailed: "反馈失败",
        autoSave: '自动保存',
        serviceDescShowMore: '展开',
        serviceDescShowLess: '收起',
        members: "成员",
        shareTo: "分享给。。。",
        findTeamMembers: "找到团队成员",

        defaultPaymentMethod: '默认支付方式',
        autoTopupWillAutomaticallyChargeYourPaymentMethodT: '自动充值将自动向您的支付方式收费，以确保您的账户始终有足够的信用点。',
        gotIt: '知道了',
        pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA: '请确保您的支付方式是最新的，并且您有足够的信用点来支付自动充值。',
        thresholdCredits: '阈值信用点',
        creditAmountCredits: '信用点金额',
        enablingAutoTopupsWillAutomaticallyAddCreditsToYou: '启用自动充值将自动向您的账户添加信用点，以确保您的余额始终保持在指定的阈值以上。',
        thisWillAutomaticallyAddCreditsToYourAccountWhenev: '这将在您的账户余额低于指定阈值时自动添加信用点。',
        autoTopupNotice: '自动充值通知',
        roleName: '角色名称',
        addUser: '添加用户',
        newRole: '新角色',
        userName: '用户名',
        joined: '加入',
        thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow: '这个角色目前没有分配任何用户。开始添加团队成员，以明确他们的职责并优化你的工作流程。',
        noUsersAssigned: '未分配用户',


        // price
        "exportLimit": "每次数据导出上限",
        "customRolePermission": "用户权限设置",
        "teamManagement": "团队管理设置",
        "API": "API",
        "linkedinAccount": "Linkedin 账户",
        "perPageLimit": "每页搜索",
        "recordUploadLimit": "每次资料上传数量",
        "creditRollover": "信用点数累计",
        "quotaSharing": "配额共享",
        "quotaAdjustment": "配额调整",
        "add_On": "增值服务",
        "topUpCredits": "充值信用点数",
        "webVisitorIdentification": "意图分析",
        "recordSelection": "搜索筛选",
        "portal": "平台",
        "queryLimitPerHour": "每小时查询次数",
        "queryLimitPerDay": "每日查询次数",
        "callLimitPerHour": "每小时调用次数",
        "callLimitPerDay": "每日调用次数",
        "callLimitPerMonth": "每月调用次数",
        "basicSequencing": "基础流程自动化",
        "numberOfSequences": "工作流程数量",
        "numberOfMailboxesPerUser": "用户邮箱数量",
        "numberOfLinkedinAccountPerUser": "用户Linkedin账户数量",
        "numberOfSlackAccountPerUser": "用户Slack账户数量",
        "automatedFollowUp": "自动回复",
        "advancedSequencing": "自订工作流程",
        "abTesting": "A/B 测试",
        "multipleEmailTemplates": "工作流程模板",
        "reporting": "数据分析",
        "emailOpenTracking": "邮件打开跟踪",
        "emailClickTracking": "邮件点击跟踪",
        "emailReplyTracking": "邮件回复跟踪",
        "support": "技术支持",
        "priorityEmailSupport": "电邮支持",
        "companySelectionLimit": "公司选择数量",
        "prospectSelectionLimit": "客户选择数量",
        "totalPageLimit": "总页数搜索",
        "pricingYear": "年",
        "growth-annual": "初创计划",
        "business-annual": "商务计划",
        "pricingSearch": "客户搜索",
        "pricingSearches": "搜索",
        "pricingMonth": "月",
        "pricingYear": "年",
        "topupCredit": "充值信用点数",
        "perUserPerYear": "每位用户/每年\n每年计费",
        "perUserPerMonth": "每位用户/每月\n每月计费",
        "customCredits": "定制信用点数",
        "customSearches": "定制客户搜索",
        "queryLimitPerMonth": "每月查询次数",
        "customized": "定制方案",
        "custom": "定制",
        "free": "免费计划",
        "growth": "初创计划",
        "business": "商务计划",
        "organization": "企业计划",
        "search": "数据搜索",

        // setting 
        pleaseSelectTheUserYouWantToAdd: '请选择您想添加的用户',
        pleaseSelectTheUserYouWantToDelete: "请选择您想删除的用户",
        pleaseSelectTheUserYouWantToAddPermission: "请选择您想添加权限的用户",



}
