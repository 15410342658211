export const MODULE_OVERVIEW = 1;
export const MODULE_ANALYTIC = 2;
export const MODULE_FILTER = 3;

export const CATEGORY_ALL = '1';
export const CATEGORY_MY = '2';
export const CATEGORY_TEAM = '3';

export const FILTER_SEARCH = 1;
export const SAVED_SEARCH = 2;

export const SAVED_SEARCH_NEW = 1;
export const SAVED_SEARCH_UPDATE = 2;

export const INTENT_SEARCH_TYPE = 3;



export const categoryMap = new Map([
    [CATEGORY_ALL, null],
    [CATEGORY_MY, true],
    [CATEGORY_TEAM, false],
]);

// filter item 进入方式
export const PAGE_INTENT = 1;
export const PAGE_EMPLOYEE = 2;

export const NO_LOGO_URL = 'https://leads.pubrio.com/img/no_logo.png';

export const monthMap = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'Aug',
    '09': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  }

export const fullMonthMap = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December',
  }