import request from '@/utils/request';

/*------------------------- role ---------------------------*/
export function getPermissionList(data){
    return request({
        url: '/iam/permissions/',
        method: 'post',
        data
    })
}

export function getRoleList(data){
    return request({
        url: '/iam/roles/',
        method: 'post',
        data
    })
}
export function lookUpRole(data){
    return request({
        url: '/iam/roles/lookup',
        method: 'post',
        data
    })
}
export function createRole(data){
    return request({
        url: '/iam/roles/create',
        method: 'post',
        data
    })
}

export function addRoleToUser(data){
    return request({
        url: '/iam/roles/add/users',
        method: 'post',
        data
    })
}

export function addRoleToTeam(data){
    return request({
        url: '/iam/roles/add/teams',
        method: 'post',
        data
    })

}
export function updateRole(data){
  return request({
    url: '/iam/roles/update',
    method: 'post',
    data
  })
}
export function updateRolePermission(data){
    return request({
        url: '/iam/roles/update/permissions',
        method: 'post',
        data
    })
}

export function deleteRole(data){
    return request({
        url: '/iam/roles/delete',
        method: 'delete',
        data
    })
}

export function deleteUserRole(data){
    return request({
        url: '/iam/roles/delete/users',
        method: 'delete',
        data
    })
}

export function getUserListInRole(data){
    return request({
        url: '/iam/roles/users',
        method: 'post',
        data
    })
}



/*------------------------- team ---------------------------*/

export function getTeamList(data){
    return request({
        url: '/iam/teams/',
        method: 'post',
        data
    })
}

export function createTeam(data){
    return request({
        url: '/iam/teams/create',
        method: 'post',
        data
    })
}

export function updateTeam(data){
    return request({
        url: '/iam/teams/update',
        method: 'post',
        data
    })
}

export function deleteTeam(data){
    return request({
        url: '/iam/teams/delete',
        method: 'delete',
        data
    })
}

//pending
export function addTeamToUser(data){
    return request({
        url: '/iam/teams/add/users',
        method: 'post',
        data
    })
}

export function deleteUserTeam(data){
  return request({
    url: '/iam/teams/delete/users',
    method: 'delete',
    data
  })
}
export function UpdateTeamPermissions(data){
    return request({
        url: '/iam/team/update/permissions',
        method: 'post',
        data
    })
}

export function getUserListInTeam(data){
  return request({
    url: '/iam/teams/users',
    method: 'post',
    data
  })
}
export function updateTeamUser(){
  return request({
    url: '/iam/teams/update/users',
    method: 'post',
    data
  })
}

export function updateTeamRole(){
  return request({
    url: '/iam/team/update/roles',
    method: 'post',
    data
  })
}

/*------------------------- relation ---------------------------*/
export function getTeamRelationList(data){
  return request({
    url: '/iam/teams/relations',
    method: 'post',
    data
  })
}
export function modifyTeamRelation(data){
  return request({
    url: '/iam/teams/relations/modify',
    method: 'post',
    data
  })
}

export function deleteTeamRelation(data){
  return request({
    url: '/iam/teams/relations/delete',
    method: 'delete',
    data
  })
}

export function addTeamRelation(data){
  return request({
    url: '/iam/teams/relations/add',
    method: 'post',
    data
  })
}

export function getTeamRelationType(data){
  return request({
    url: '/iam/teams/relations/types',
    method: 'post',
    data
  })
}

/*------------------------- sharing rules ---------------------------*/
export function getSharingRulesList(data){
  return request({
    url: '/iam/sharing_rules/',
    method: 'post',
    data
  })
}
export function getSharingRulesGroupList(data){
  return request({
    url: '/iam/sharing_rules/groups',
    method: 'post',
    data
  })
}
export function createSharingRulesGroup(data){
  return request({
    url: '/iam/sharing_rules/groups/create',
    method: 'post',
    data
  })
}
export function updateSharingRulesGroup(data){
  return request({
    url: '/iam/sharing_rules/groups/update',
    method: 'post',
    data
  })
}
export function deleteSharingRulesGroup(data){
  return request({
    url: '/iam/sharing_rules/groups/delete',
    method: 'delete',
    data
  })
}
export function updateSharingRules(data){
  return request({
    url: '/iam/sharing_rules/groups/update/sharing_rules',
    method: 'post',
    data
  })
}
