import request from '@/utils/request';

export function getNotificationList(data){
    return request({
        url: '/notifications',
        method: 'post',
        data
    })
}
export function updateNotification(data){
    return request({
        url: '/notifications/update',
        method: 'post',
        data
    })
}
export function getNotificationTypeList(data){
    return request({
        url: '/notifications/types',
        method: 'post',
        data
    })
}

export function getNotificationSettingList(data){
    return request({
        url: '/notifications/settings',
        method: 'post',
        data
    })
}

export function updateNotificationSetting(data){
    return request({
        url: '/notifications/settings/update',
        method: 'post',
        data
    })
}
