import request from '@/utils/request';

export function getEmailTypes(){
  return request({
    url: '/crm/emails/types',
    method: 'get',
  })
}
export function getPhoneTypes(){
  return request({
    url: '/crm/phones/types',
    method: 'get',
  })
}
export function getCRMSchemaList(data){
  return request({
    url: '/crm/schema',
    method: 'post',
    data
  })
}
export function getCustomTypeList(data){
  return request({
    url: '/crm/schema/columns/types',
    method: 'get',
  })
}
export function getCRMDeduplication(){
  return request({
    url: '/crm/schema/columns/deduplication_identifier',
    method: 'get',
  })
}



export function getCRMColumns(data){
  return request({
    url: '/crm/schema/columns',
    method: 'post',
    data
  })
}
export function createCRMColumn(data){
  return request({
    url: '/crm/schema/columns/create',
    method: 'post',
    data
  })
}
export function updateCRMColumn(data){
  return request({
    url: '/crm/schema/columns/update',
    method: 'post',
    data
  })
}
export function deleteCRMColumn(data){
  return request({
    url: '/crm/schema/columns/delete',
    method: 'delete',
    data
  })
}