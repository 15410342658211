export default {
    //login
    loading: '加載中...',
    SignUp: '註冊',
    LoginSuccess: '登錄成功',
    LoginOut: '退出成功',
    email: '郵箱',
    password: '密碼',
    logIn: '登錄',
    WelcomeBack: '歡迎回來',
    PleaseEnterYourEmailAndPassword: '請輸入您的郵箱和密碼',
    CharactersRequired: '不少於8個字符',
    keepMeSignedIn: '保持登錄',
    forgetPassword: '忘記密碼?',
    dontHaveAnAccount: '沒有賬號?',
    signUpNow: '立即註冊',
    AllRightsReserved: '版權所有',
    privacyPolicy: '隱私',
    and: '和',
    termsOfService: '條款',
    Name: '名稱',
    viewAll: '查看全部',
    fold: '收起',
    noLimit: "無限制",
    //signup
    company: '公司',
    companyName: '公司名稱',
    signUp: '註冊',
    alreadyHaveAnAccount: '已有賬號',
    getStarted: '開始吧！用我們的平台為您的業務增添力量',
    signUpSuccess: '註冊成功',
    updateCreditCard: '更新信用卡',
    role: '角色',



    //forget
    noWorries: '不用擔心！輸入您註冊的電子郵件地址，我們將向您發送重置說明。',

    //reset
    reset: '重置',
    forgotPassword: '忘記密碼',
    RequestPasswordReset: '請求密碼重置',
    backToLogin: '返回登錄',
    verifyPassword: '驗證密碼',
    EnterANewPasswordFor: '輸入新密碼',
    ResetPassword: '重置密碼',
    newPassword: '新密碼',

    //top-nav
    Home: '首頁',
    Search: '搜索',
    Prospects: '客戶',
    filterYourSearchToFindHighlySpecific: '過濾搜索以找到高度特定的目標潛在客戶群',
    groupsOfTargetedLeads: '',
    filterYourSearchToFindPreciselyTargeted: '過濾搜索以找到精確定位的公司群',
    listsOfCompanies: '',
    transformAnonymousWebsiteTrafficInto: '將匿名網站流量轉化為實時識別的B2B',
    b2bLeadsWithRealtimeIdentification: '潛在客戶',
    discoverAndConnectWithPotentialBuyers: '積極發現並與潛在買家建立聯繫，尋求您',
    activelySeekingYourSolutionToday: '今天的解決方案',
    Intent_Signals: '趨勢信號',
    Website_Intent: '網站趨勢',
    Market_Intent: '市場趨勢',
    Sequence: '序列',
    Tools: '工具',
    Services: '服務',
    technologyLookup: '技術查詢',
    domainLookup: '域名查詢',
    emailVerification: '郵箱認證',
    upgrade: '升級',
    helpCenter: '幫助中心',
    contactUs: '聯系我們',
    feedback: '反饋',
    hello: '你好',
    clickToChange: '點擊更改',
    yourProfile: '個人資料',
    currentUsage: '使用情況',
    upgradePlan: '升級計劃',
    integrations: '集成',
    featureRequest: '特色請求',
    change: '更改',
    en: '英文',
    cn: '中文（簡）',
    tw: '中文（繁）',
    logout: '退出',
    switchLanguage: '切換語言成功',

    //not available
    ComingSoon: '即將推出！',
    TheFeatureIscurrentlyUnder: '該功能目前正在開發中',
    development: '',
    weAppreciateYourPatienceAsWeWorkToImprove: '我們感謝您的耐心等待，我們將努力改善',
    yourExperience: '您的體驗',
    inTheMeantimeIfYouHaveAnyQuestionsOrNeed: '同時，如果您有任何問題或需要幫助，',
    assistancePleaseContactOurSupportTeam: '請聯系我們的支持團隊',
    contactSupport: '聯系支持',
    ok: '確定',
    thankYouForBeingAValuedUserOfOurPlatform: '感謝您成為我們平台的重要用戶！',


    //search-company
    people: '人',
    companies: '公司',
    clearAll: '清除全部',
    searchProspects: '搜索客戶、關鍵詞...',
    //actions
    isFoundIn: '在',
    isNotFoundWithinAnyLists: '不在任何列表中',
    l: '個列表中找到',
    remove: '移除',
    saveToLists: '保存到列表',
    //delete dialog
    areYouSureYouWantToDeleteThisRecord: '您確定要刪除此記錄嗎？',
    proceedingWithDeletionWillResultInPermanentRemovalOfTheList: '繼續刪除將導致永久刪除列表',
    delete: '删除',
    byCancelingYouEnsureThatNoListsWillBeRemoved: '通過取消，您可以確保不會刪除任何列表',
    DeleteFailed: '刪除失敗',
    //export dialog
    holdOnWereGeneratingYourExport: "請稍等，我們正在生成您的導出",
    UseThisButtonOnlyIfYouWishToCancelYourAction: '僅在您希望取消操作時使用此按鈕',
    //save search pop
    saveSearch: '保存搜索',
    newSaveSearch: '新建保存搜索',
    UpdateSavedSearch: '更新保存搜索',
    enterNewSearchName: '輸入新搜索名稱',
    visibility: '可見性',
    manageWhoCanSeeYourSavedSearch: '管理誰可以看到您的保存搜索',
    private: '私有',
    onlyYouCanSeeYourSavedSearch: '只有您可以看到您的保存搜索',
    everyoneCanSeeYourSavedSearch: '每個人都可以看到您的保存搜索',
    pinSavedSearch: '將保存搜索固定在頂部',
    findSavedSearch: '查找保存搜索',
    enterSavedSearchName: '輸入保存搜索名稱',
    duplicate: '重複',
    update: '更新',
    noSavedSearches: '沒有保存的搜索',
    //add credits pop
    ClickTheButtonBelowToAddMoreCredits: '點擊下面的按鈕添加更多信用額度',
    PleaseEnterTheAmountYouLikeToTopUpToYourCredit: '請輸入您想要充值的金額',







    //companyLink\contactBtn\functionBtn
    more: '更多',
    verified: '已驗證',
    GetEmailSuccessfully: '獲取郵箱成功',
    CopySuccessfully: '複製成功',
    recorded: '已記錄',
    implement: '執行',
    sequence: '序列',
    of: '  總條數：',
    toAssistYouEffectivelyWeRequireFurtherDetailsAbout: '為了有效地幫助您，我們需要有關您的搜索偏好的更多詳細信息。請利用左側面板中的篩選選項來指定您所需的條件，以便我們能夠優化搜索結果並為您提供滿足您要求的目標群體資料。',
    //filter
    all: '全部',
    my: '我的',
    team: '團隊',
    lists: '列表',
    employees: '員工',
    technologies: '技術',
    socialmedia: '社交媒體',
    enterSocialMedia: '請輸入社交媒體',
    noResultsFound: '未找到結果',
    enterCompanies: '請輸入公司',
    searchCompanies: '搜索公司',
    filters: '過濾器',
    accountHqLocation: '總部位置',
    CityStateCountryZIP: '城市/州/國家/郵編',
    enterLocations: '请輸入位置',
    revenue: '收入',
    chooseARangeOfRevenue: '選擇收入範圍',
    ofRevenueIsUnknown: '收入未知',
    technology: '技術',
    category: '類別',
    categories: '類別',
    enterCategories: '請輸入類別',
    enterTechnologies: '請輸入技術',
    employee: '員工',
    predefinedRange: '預定義範圍',
    ofEmployeesIsUnknown: '員工數量未知',
    enterNames: '請輸入名稱',
    jobTitle: '職位',
    searchForAJobTitle: '搜索職位',
    enterIndustries: '请輸入行業',
    founded: '成立時間',
    chooseARangeOfFoundedTime: '選擇時間範圍',
    from: '從',
    to: '到',
    specialties: '特色',
    enterSpecialties: '請輸入特色',
    location: '位置',
    total: '總數',
    department: '部門',
    EnterDepartments: '請輸入部門',
    JobTitle: '職位',
    EnterJobTitles: '請輸入職位',
    ManagementLevel: '管理層級',
    EnterManagementLevels: '請輸入管理層級',
    AccountHQ: '地址',
    ContactHQ: '總部聯系方地址',
    departmentFunction: '部門職能',
    EnterJobFunctions: '請輸入部門職能',
    enterNamesAndPressTheEnterKey: '請輸入名稱並按Enter鍵',
    EnterJobTitlesAndPressTheEnterLKey: '請輸入職位並按Enter鍵',
    pleaseSelectRange: '請選擇範圍',
    addYourCreditCardInformation: '新增您的信用卡資訊',




    //table
    selectAllCompanies: '選擇所有公司',
    selected: '已選擇',
    export: '導出',
    industry: '行業',
    keywords: '關鍵詞',
    companyLocation: '公司位置',
    action: '操作',
    PleaseEnterTheSelectedQuantity: '請輸入選定的數量',
    contact: '聯系方式',
    Title: '職位',
    selectThisPage: '選擇此頁',
    clearSelection: '清除選擇',
    selectNumberOfCompanies: '選擇公司數量',
    maxCompanies: '最大公司數量',
    youCanOnlySelectAMaximumOf25Companies: '您最多只能選擇25家公司。',
    UpgradeYourPlanNow: '立即升級您的計劃！',
    viewPlans: '查看計劃',
    applySelection: '應用選擇',
    selectNumberOfProspects: '選擇客戶數量',
    maxProspects: '最大客戶數量',
    youCanOnlySelectAMaximumOf25Prospects: '您最多只能選擇25個客戶。',
    PleaseSelectTheDataToBeExported: '請選擇要導出的數據',
    overview: '概述',
    genericContacts: '聯系方式',
    size: '規模',
    contactLocation: '聯系地址',

    //account
    addCreditCard: '添加信用卡',
    zip: '郵編',
    country: '國家',
    state: '州',
    city: '城市',
    address: '地址',
    billingInformation: '帳單信息',
    cvc: 'CVC',
    year: '年',
    month: '月',
    expirationDate: '到期日',
    creditCardNumber: '信用卡號碼:',
    cardInformation: '卡信息',
    title: '標題',
    fullName: '全名',
    yourDetail: '您的詳細信息',
    creditsToUse: '待使用信用點數',
    youHave: '您有',
    download: '下載',
    buyMoreCredits: '購買更多信用點數',
    description: '描述',
    topUpCredits: '充值信用點數',
    cancel: '取消',
    date: '日期',
    invoiceHistory: '發票歷史',
    confirmNewPass: '確認新密碼',
    creditsUsed: '已使用信用點數',
    newPass: '新密碼',
    youHaveNotEnteredAnyCreditCardInformationYet: '您尚未輸入任何信用卡信息',
    oldPass: '舊密碼',
    cardNumber: '卡號',
    changePassword: '更改密碼',
    creditUsage: '信用點數使用情況',
    typeTheNewTitle: '輸入新標題',
    save: '保存',
    addMoreCredits: '添加更多信用點數',
    creditsMo: '信用點數/月',
    apiIntegrations: 'API集成',
    profileSubscription: "個人資料訂閱",
    'additional-user-licence': "附加用戶許可證",
    'agency-white-label': "機构白標",
    'api-extended-limit': "API擴展限制",
    'profile-subscription': "個人資料訂閱",
    creditsInYourPlan: '您計劃中的信用點數',
    plugin: "挿件",
    settings: '設置',
    phone: '電話',
    '1User': '1個用戶',
    users: '用戶',
    billing: '賬單',
    monthlyQueryLimit: '每月查詢限額',
    price: '價格',
    whatsIncluded: '包括什麼',
    givenName: '名',
    purchasePlan: '購買計劃',
    accountOverview: '賬戶概述',
    familyName: '姓',
    dailyQueryLimit: '每日查詢限額',
    rateLimit: '限制',
    youAreOnA: '您正在',
    PlanAndYourCreditsWillRefreshOn: '計劃中，您的信用點數將在',
    过期: '更新',
    searchSettings: '搜索設置',
    plan: '計劃',
    accountInfo: '賬戶信息',
    creditCardInformation: '信用卡信息',
    apiUsage: 'API使用情况',
    mo: '/月',
    perUserPerMonth: '每用戶每月',
    billedAnnually: '按年計費',
    dailyQuery: '每日查詢',
    monthlyQuery: '每月查詢',
    currentPlan: '當前計劃',
    everythingInPlus: 'everythingInPlus',
    mostPopular: '最受歡迎',
    topUpToIncreaseYourCredits: '增加您的信用點數',
    addCredits: '購買信用點數',
    allPlansInclude: '所有計劃均包',
    Amount: '金額',
    credits: '信用點數',
    purchaseSuccess: '購買成功，您的總信用點數為',
    purchaseFailed: '購買失敗',
    Loading: '加載中',
    Selected: '已選擇',
    SelectPlan: '選擇計劃',
    upgradeSuccess: '計劃切換成功',
    upgradeFailed: '計劃切換失敗',
    inviteSentSuccessfully: '邀請發送成功',
    DeleteSuccessfully: '刪除成功',
    AccountupdatedSuccessfully: '賬戶更新成功',
    AccountUpdateFailed: '賬戶更新失敗',
    PasswordUpdatedSuccessfully: '密碼更新成功',
    PasswordUpdateFailed: '密碼更新失敗',
    Users: '用戶',
    newUser: '新用戶',
    deleteUser: '刪除用戶',
    admin: '管理員',
    inviteYourTeam: '邀請您的團隊',
    empowerEveryoneToGetMoreDealsDonefaster: '授權每個人更快地完成更多交易',
    addMultipleEmailAddressesSeparatedByCommas: '添加多個電子郵件地址，并用逗號分隔',
    sendInvites: '發送邀請',
    yourTeams: '您的團隊',
    AreYouSureYouWantToDeleteThisTeamMember: '您確定要刪除此團隊成員嗎？',
    teamDetails: '團隊詳情',
    teamName: '團隊名稱',
    changeTeam: '切換團隊',
    clickToChangeTeam: '點擊更改團隊',
    changeTeamSuccessfully: '切換團隊成功',
    status: '狀態',
    Action: '操作',
    pending: '待定',
    current: '當前',
    AreYouSureYouWantToDeleteThisCreditCard: '您確定要刪除此信用卡嗎？',
    PasswordCannotBeEmpty: '密碼不能為空',
    nickname: '昵稱',
    FirstName: '名',
    LastName: '姓',
    timezone: '時區',
    contactNo: '聯系電話',
    controlYourTeamMembersAndTheirAccountAccessRightHere: '在此處管理您的團隊成員及其賬戶訪問權限',
    pleaseEnterYourCurrentPasswordToChangeYourPassword: '請輸入當前密碼以更改密碼',
    updatePassword: '更新密碼',
    oldPassword: '舊密碼',
    confirmNewPassword: '確認新密碼',
    InviteYourTeam: '邀請您的團隊',
    empowerEveryoneToGetMoreDealsDoneFaster: '授權每個人更快地完成更多交易',
    enterEmailAddressesMultipleCommaseparated: '輸入電子郵件地址（多個，用逗號分隔）',
    invoice: '發票',
    accountAdmin: '賬戶管理員',
    setAsDefault: '設為默認',
    bankName: '銀行名稱',
    expiry: '到期',
    viewAllYourPreviousInvoicesHere: '在此處查看您以前的所有發票',
    BillingHistory: '賬單歷史記錄',
    updateYourCreditCardInformation: '更新您的信用卡信息',
    ManageCreditCard: '管理信用卡',
    CardNumber: '卡號',
    billingAddress: '賬單地址',
    yourCreditsWillRefreshOn: '您的信用點數將在',
    yourCreditsWillBeChargeOn: "您的信用點數將在",
    "被收取": "被收取",
    perYear: '/每年',
    perMonth: '/每月',
    monthlyCredit: "每月信用",
    Usage: "使用",
    features: '特征功能',
    credits_mo: '信用點數/月',
    credits_year: '信用点数/年',
    _mo: '/月',
    seeOtherPlan: '查看其他計劃',
    subscriptionTiers: '訂閱區',
    experiencePricingThatsEasyToUnderstandAndAdaptable: "體驗易於理解且易於適應的價格",
    monthlyBilling: '月度計費',
    annualBilling: '年度計費',
    forPlanCustomizationContactOurSalesTeam: '有關計劃定制的更多信息，請聯系我們的銷售團隊',
    billedAnnually_user: '按年計費/用戶',
    seePlanComparison: '查看計劃比較',
    topUp: '充值',
    changeTeamNameFailed: '更改團隊名稱失敗',
    // purchaseCreditsAtTheRateOf100PerCreditCustomizeyourCreditBundleBasedOnYourNeeds: '按1美元/信用點數購買信用點數，請根據您的需求定制信用點數包',
    totalPrice: '總價',
    credit: '信用點數',
    RemoveCreditCard: '刪除信用卡',
    enterATitleForYourCard: '為您的卡輸入標題',
    enterYourCardNumber: '輸入您的卡號',
    digit: '3位數字',
    provideYourCompleteAddress: '提供您的完整地址',
    selectYourCountry: '選擇您的國家',



    // Intent
    intentOverview: "概覽",
    intentAnalytic: "分析",
    intentFilter: "篩選",
    searchCompany: '搜索公司、網站、領英...',
    savedCompanyWebsite: '已保存公司的網站',
    companiesFound: '家公司',
    companyFound: '家公司',
    filterList: {
        technologies: '技術',
        time: '時間',
        locations: '賬號位置',
        verticals: '行業',
        pageview_greater_than: '頁面預覽',
        session_greater_than: '會話',
        advertisements: '廣告',
        company_groups: '列表',
        start_date: '開始時間',
        end_date: '結束時間',
        search_term: '蒐索名稱'
    },
    employeeFilterList: {
        industry: '行業',
        technology: '技術',
        jobTitle: '職位',
        company: '公司',
        list: '清單',
        name: '名字',
        size: '規模',
        location: '位置',
        jobFunction: '工作職能'
    },
    selectLists: '請選擇列表',
    chooseARangeOfTime: '時間段選擇',
    greaterThan: '大於',
    selectAdvertisement: '選擇廣告',
    // intentIndustry: '行業',
    tabActivity: '活動',
    tabOverview: '概覽',
    tabEmployees: '員工',
    tabContacts: '通用聯繫人',
    tabTechnologies: '技術',
    intentStatistic: '統計',
    intentCampaign: '活動',
    newVisitors: '新訪客',
    recurringVisitors: '常客',
    mediocreLead: '普通的潛在客戶',
    sessionsTracked: '個跟踪會話',
    sessionTracked: '個跟踪會話',
    lastSeenOn: '最後查看',
    firstSeenOn: '首次查看',
    viewTrackedWebSession: '查看跟踪的網絡會話',
    webSession: '網絡會話',
    client: '客戶端',
    timeSpent: '花費時間',
    pageviews: '瀏覽量',
    pageview: '瀏覽量',
    referrer: '來源',
    sessions: '會話',
    session: '會話',
    visited: '訪問',
    AM: '早晨',
    PM: '下午',
    companyDetails: '公司詳情',
    overviewList: {
        location: '位置',
        industry: '行業',
        founded: '創建時間',
        ranking: '排名',
        size: '規模',
        revenue: '收入',
        keywords: '關鍵詞',
    },
    advancedSearch: '高級搜索',
    jobFunction: '工作職能',
    employeeLocation: '位置',
    concatLocation: '聯繫位置',
    contactEmail: '郵件',
    categoryAndTechnology: '類別和技術',
    firstDetected: '首次查看',
    lastDetected: '最後查看',
    saved: '已保存',
    addToContactList: '添加到連絡人列表',
    noCampaignIdentified: '暫時沒有活動',
    copyEmail: '複製郵箱',
    willBeSavedToTheFollowingSelectedLists: '將會被保存到下列選中的列表:',
    addToLists: '添加到列表',
    noDataToDisplay: '暫無更多數據',
    intentFounded: '創建時間',
    intentIndustry: '行業',
    intentRevenue: '收入',
    intentSize: '規模',
    applyFilters: '篩選',
    youCanEditTheseFilters: '您可以隨時編輯這些選項以篩選結果',
    collapse: '收起',
    expand: '展開',

    // analytic: '統計分析',
    // allLeads: '所有線索',
    // campaign: '活動',
    // medium: '媒體',
    // source: '來源',
    // term: '项目',
    // activities: '活動',
    // genericContacts: '通用聯繫人',
    // ranking: '排名',
    // size: '規模',
    // social: '聯繫方式',
    // website: '網站',
    // campaigns: '活動',
    installB2BDirectLeadFinder: '在您的網站上安裝 B2B Direct Lead Finder',
    addSite: '添加站點',
    areYouSureYouWantToRemoveThisWebsite: '您確定要移除這個網站嗎？',
    Cancel: '取消',
    installB2BLeadFinder: '安裝 B2B Lead Finder',
    pleaseTellUsABitAboutTheWebsiteYoudLikeToAdd: "請告訴我們您要添加的網站的一些信息",
    siteUrl: '站點網址',
    copyToClipboard: '複製到剪貼板',
    pleaseSelectAContactFirst: '請先選擇一個聯繫人',
    pasteCodeBelowIntoTheHead: '將下面的代碼粘貼到您希望跟踪訪問者的每個頁面的',
    ofEveryPageYouWishToTrackVisitorOn: '中',
    forWordpressWeRecommendInstalling: '對於 WordPress，我們建議安裝來自 Stefano Lissa 的',
    pluginFromStefanoLissa: '插件。',
    afterInstallingAndActivating: '安裝並激活插件後，導航到 WordPress 儀表板中的',
    inYourWordPressDashboard: '。',
    youWillSeeANumberOfTabs: '您會在插件界面中看到許多選項卡， 確保您位於默認的“頁眉和頁腳”選項卡中，然後復制並粘貼以下代碼片段:',
    settingHeaderAndFooter: '設置 → 頁眉和頁腳',
    dontForgetToClearYourCache: "不要忘記清除緩存",
    ifYouAreUsingAnyCaChing: '如果你正在使用像',
    orSimilar: "之類的插件, 確保在安裝代碼段後已經清除緩存!",
    GTMshouldAlreadyBeInstalled: '在按照這些說明操作之前，您的網站上應該已經安裝了 Google Tag Manager',
    ifYouDontHaveGTM: "如果您還沒有安裝 Google Tag Manager，您可以在",
    GTMsupportArticleAboutInstall: 'Google Tag Manager 支持的文章中了解如何安裝它',
    signInToGTMAndClick: '登錄 Google Tag Manager，然後點擊添加新標籤按鈕。然後',
    clickWhereItSays: '點擊',
    chooseATagTypeToBeginSetup: ' "Choose a tag type to begin setup" ',
    andSelect: '並從列表中選擇 ',
    fromTheList: '，然後將以下代碼複製並粘貼到文本字段中。',
    nextClick: '然後點擊 ',
    giveTheTagAName: '，為標籤命名，然後單擊“保存”按鈕。',
    dontForgetToPublishYourChange: "不要忘記發布您的更改",
    afterCreateTheCustom: '創建 "Custom HTML" 標記後，不要忘記',
    yourChanges: '你的更改!',
    submitAndPublish: '提交並發布',
    copySuccessfully: '複製成功',
    addToCompanyList: '添加到公司列表',
    // addToList: '添加到列表',
    // enterOrCreateList: '輸入或創建列表',
    installCode: '安裝代碼',
    wordpress: 'wordpress',
    googleTagManager: 'googleTagManager',
    noVisitorsYet: '暫無瀏覽',
    onceTheInsightsRollIn: "一旦深入瞭解，您將解鎖有關訪客等的寶貴資訊。",
    noTechAreFound: '暫無技術內容',
    updateSuccessfully: '更新成功',
    enterJobFunction: '請輸入工作職能',
    pinSuccessfully: '置頂成功',
    cancelPinSuccessfully: '取消置頂成功',
    adsName: '廣告名稱',
    utmTerm: 'utmTerm',
    utmCampaign: 'utmCampaign',
    utmSource: 'utmSource',
    utmMedium: 'utmMedium',
    enterNumber: "輸入數位",
    enterName: "請輸入名稱",
    enterJobTitle: "請輸入職位",
    areYouSureYouWantToRemovePeopleOfThisGroup: '您確定要將該人員從列表中删除嗎',
    areYouSureYouWantToRemoveCompanyOfThisGroup: '您确定要将该公司从列表中删除吗',
    byCancelingYouEnsureThatNoCompanyWillBeRemoved: '取消將不會删除任何公司',
    byCancelingYouEnsureThatNoPeopleWillBeRemoved: '取消將不會删除任何人員',
    howToGetStarted: '如何開始',
    Data: '條數據',
    deactivateWebsite: '停用網站',
    areYouCertainAboutDeactivatinYourWebsite: '您確定要停用您的網站嗎？這將導致無法接收任何潜在的公司活動。',
    byCancelingYouWillContinueToActivateAndUse: '取消後，您將繼續啟動並使用此工具',
    yourTrialHasEnded: '您的試用已到期',
    yourWebsiteHasBeenAutomaticallyInactivated: '您的網站已自動停用。 是否要重新啟動此工具以繼續監視數據？',
    Inactivated: '停用中',
    onTrial: '試用中',
    Active: '啟用設定',
    youAreOnTrail: '您正在七天試用期中',
    yourTrailHasEnded: '您的試用已過期',
    createNewWebIntent: '創建新的Web Intent',
    clickOnTheButton: '1. 點擊 (+) 按鈕',
    typeInUrlOrWebAddress: '2. 輸入URL或網址',
    theTrialWillActivate: '選中後，7天試用將啟動',
    pleaseEnterASiteUrl: '請輸入網址',
    noMoreData: '暫無更多數據',
    pleaseEnterANewName: '請輸入名稱',
    pleaseSelectASearchName: '請選擇蒐索名稱',
    clickToGetEmail: '點擊獲取郵件',
    emailIsNotAvailable: '無法獲取郵件',
    enterOrCreateLists: '蒐索或創建列表',
    viewMore: '查看更多',
    //fold: '收起',
    removeFromContactList: '從連絡人列表中移除',
    willBeRemovedFromTheFollowingLists: '將會從下列清單中移除',
    removeFromLists: '從清單中移除',
    pleaseSelectOrCreateAList: '請選擇或創建一個列表',
    chooseATriggerToMakeThisTagFire: 'chooseATriggerToMakeThisTagFire',
    youWontBeChargedYet: '現時您還不會被收費',
    youDontHaveEnoughCredits: "您沒有足够的信用點",
    wouldYouLikeToAdd50Credits: "您想添加50個信用點（最低充值金額）以繼續發送電子郵件嗎？",
    ThisEmailRequire: '該郵件需要',
    TheseEmailsRequires: '這些郵件需要',
    intentCredit: '信用點',
    intentCredits: '信用點',
    // analytic
    showNumber: '展示條數',
    Website: '網站',
    b2bTraffic: 'B2B 貿易',
    b2bReturningVsNew: 'B2B 常客 V.S. 新用戶',
    visitors: '訪客',
    totalView: '总浏览量',
    // b2bPageViews: 'B2B 浏览量',
    totalSessions: '全部会话',
    operatingSystem: '操作系統',
    browser: '瀏覽器',
    device: '設備',
    companySize: '公司規模',
    topIndustry: '熱門行業',
    topCountries: '熱門國家',
    topReferrals: '熱門推薦',
    new: '新用戶',
    returning: '老用戶',
    countriesStatistics: '國家統計',
    topCompaigns: '熱門活動',
    topPagesByVisits: '流覽量分析',
    topPagesByEngagement: '參與度分析',
    Referrals: '推薦',
    Pages: '頁面',
    avgTime: "平均時間",
    totalCompanies: '全部公司',
    b2bPageViews: 'B2B 頁面瀏覽',
    TotalSessions: '全部會話',
    viewCompanyList: '查看全部公司',
    noDiscussionsYet: "暫無討論",
    useThisSpaceToAskQuestions: "您可以使用此空間直接與我們提問、獲取更新和討論細節",
    // mycontact
    prospects: '前景',
    list: '列表',
    view: '查看',
    // delete: '刪除',
    add: '添加',
    newList: '新建列表',
    savedLists: '已保存列表',
    searchList: '搜索列表名稱',
    owner: '所有者',
    records: '記錄',
    actions: '操作',
    lastModified: '最後修改',
    name: '姓名',
    enterAListName: '請輸入列表名字',
    pleaseSelectAListFirst: '請先選擇一個列表項',
    saveSuccessfully: '保存成功',
    pleaseEnterAListName: '請輸入列表名稱',
    editList: '編輯列表',
    fromThisList: '从列表中移除,您確定要繼續嗎',
    areYouSureYouWantToChange: '您確定要將列表類型更改為',
    // yes: '確定',
    noListCreated: '暫無列表',
    enhanceTheOrganization: '加强您在目標市場內連絡人和帳戶的組織。 創建清單後，它們將顯示在此頁面上。',
    createSuccessfully: '創建成功',
    removeSuccessfully: '移除成功',
    createNewList: '創建新列表',
    groupTeam: '團隊',
    groupPersonal: '個人',
    edit: '編輯',
    areYouSureYouWantToDeleteTheList: '你確定要删除這個列表嗎?',
    proceedingWithDeletion: '繼續删除將導致永久删除該列表。',
    byCanceling: '若您取消，不會有任何清單被删除',
    byCancelingYouEnsureThatNo: '通過取消，您可以確保沒有',
    willBeRemoved: '會被移除',
    groupPeople: '人員',
    groupCompany: '公司',
    myList: '我的列表',
    teamList: "團隊列表",
    resetAssured: "請放心，您可以根據需要靈活地在不同類型之間切換。",
    wontBeChanged: "通過取消，您可以確保此清單類型不會更改",
    priority: '優先級',
    proceedingWithDeletionWillResultInPeopleDelete: '繼續删除將導致永久删除人員',
    proceedingWithDeletionWillResultInCompanyDelete: '繼續删除將導致永久删除公司',
    areYouSureYouWantToRemoveCompanyFromList: '您確定要將已選中公司從下列清單中删除嗎',
    areYouSureYouWantToRemovePeopleFromList: '您確定要將已選中人員從下列清單中删除嗎',
    groupExisted: '群組已經存在',
    sorryTheListIsCurrrntlyOpen: '抱歉，列表當前處於打開狀態。 你確定要删除它嗎？',
    listName: '名稱',
    pleaseEnterAValidSiteUrl: '請輸入有效的網站url',

    // market-intent
    comingSoon: '即將開放',
    weAreCurrentlyWorkingOnThisFeature: '我們目前正在開發此功能，並將很快推出',
    stayConnectedWithUs: '通過我們的社交媒體渠道與我們保持聯繫以接收更新',
    selectAWebsite: '請選擇一個網站',


    // sequence
    Sequences: '序列',
    label: '標籤',
    template: '模板',
    folder: '文件夾',
    single: '單個',
    group: '組',
    steps: '步驟',
    sizes: '規模',
    time: '時間',
    //email
    SendATestEmail: '發送測試郵件',
    Categories: '類別',
    addToCategory: '添加到類別',
    EnterOrCreateCategories: '輸入或創建類別',
    addSuccessfully: '添加成功',
    Channels: '頻道',
    emails: '郵件',
    enterChannel: '請輸入頻道',
    CategoryName: '類別名稱',
    PleaseEnterANewCategoryName: '請輸入新類別名稱',
    EnterANewCategoryName: '輸入新類別名稱',
    Steps: '步驟',
    Actions: '操作',
    Time: '時間',
    From: '從',
    To: '到',
    CancelSequenceCreation: '取消序列創建',
    AreYouSureYouWantToCancelTheSequenceCreationProcess: '您確定要取消序列創建過程嗎？',
    SaveAsDraft: '保存為草稿',
    CancelSequence: '取消序列',
    ByCancelingAllSettingsWillBeLost: "取消後，所有設置將丟失",
    ImportContacts: '導入聯繫人',
    PleaseUploadACSVFileByDraggingAndDroppingItHere: '請上傳一個 CSV 文件，將其拖放到這裡',
    ChooseAFile: '選擇一個文件',
    CSVFileFields: 'CSV 文件字段',
    ReplyFields: '回復字段',
    continue: '繼續',
    Contacts: '聯繫人',
    Back: '返回',
    Import: '導入',
    Imported: '已導入',
    Skipped: '已跳過',
    Done: '完成',
    NewCustomField: '新自定義字段',
    EnterNewLabel: '輸入新標籤',
    EnterNewType: '輸入新類型',
    removeRecorded: '移除記錄',
    YouHaveUnsavedChanges: '您有未保存的更改',
    LeavingDiscard: '您確定要離開嗎？您的更改將被丟棄。',
    StayPage: '留在此頁',
    LeavePage: '離開',
    NewCategory: '新類別',
    Create: '創建',
    UnlockingSequence: '解鎖序列',
    ModifyingLeadDeselection: '修改此內容將取消目前範本版本的選擇',
    Modify: '修改',
    CancelingPreserveCurrent: '取消將保留當前的選擇',
    rename: '重命名',
    CreatNewLabel: '創建新標籤',
    NewLabel: '新標籤',
    PleaseEnterANewLabelName: '請輸入新標籤名稱',
    EnterANewLabelName: '輸入新標籤名稱',
    SequenceName: '序列名稱',
    PleaseProvideNameForNewSequence: '請為新序列提供名稱',
    EnterNewSequenceName: '輸入新序列名稱',
    SetUpEmail: '設置郵件',
    NewSchedule: '新日程',
    PleaseEnterANewScheduleName: '請輸入新日程名稱',
    EnterNewScheduleName: '輸入新日程名稱',
    AddNewTemplate: '添加新模板',
    RemoveFromTemplate: '從模板中移除',
    deleteSuccess: '刪除成功',
    removeSuccess: '移除成功',
    GroupName: '組名',
    PleaseEnterNewGroupName: '請輸入新組名稱',
    EnterNewGroupName: '輸入新組名稱',
    TemplateName: '模板名稱',
    PleaseEnterANewTemplateName: '請輸入新模板名稱',
    EnterNewTemplateName: '輸入新模板名稱',
    renameSuccessfully: '重命名成功',
    CreateSuccessfully: '創建成功',
    addToGroup: '添加到組',
    SelectChannelForNewTemplate: '選擇新模板的頻道',
    Role: '角色',
    Account: "帳號",
    customFields: '自定義字段',
    contactsToStartEngagingWithThem: '聯繫人開始與他們互動',
    thisSequenceDoesntHaveAnyProspectsOrLeadsYetConsid: '此序列尚未添加任何潛在客戶或線索。 添加一些以開始與他們互動',
    noProspectsAdded: '未添加潛在客戶',
    deliveryCurrentlyNoMessagesHaveBeenSentThroughTheS: '目前尚未通過此序列發送任何消息',
    ensureEfficientCommunicationByMonitoringYourSequen: '通過監控您的序列，確保有效的溝通',
    noMessagesHaveBeenSent: '未發送消息',
    yourNeedsGetStartedByClickingNewSequence: '請點擊“新序列”,開始您的序列',
    beginBuildingYourSalesStrategiesByDesigningSequenc: '通過設計序列開始構建您的銷售策略',
    noSequencesCreatedYet: '尚未創建序列',
    leadActions: '潛在客戶操作',
    leadInformation: '潛在客戶信息',
    thisGroupHasNoTemplatesYet: '此組尚無模板',
    copyContent: '複製內容',
    default: '默認',
    selectVersion: '選擇版本',
    subject: '主題',
    searchTemplatesfolders: '搜索模板/文件夾',
    send: '發送',
    subject2: '主題',
    enterASubjectOrLeaveItBlankToContinueInTheSameThre: '輸入主題或將其留空以繼續在同一執行緒中',
    chooseYourChannel: '選擇您的頻道',
    kl: 'KL',
    addContacts: '添加聯繫人',
    addContactsToSequence: '添加聯繫人到序列',
    addremoveCustomContactField: '添加/移除自定義聯繫人字段',
    contactCustomFields: '聯繫人自定義字段',
    type: '類型',
    typePhoneNumber: '輸入電話號碼',
    addAPhoneNumber: '添加電話號碼',
    fieldType: '字段類型',
    fieldLabel: '字段標籤',
    skipTheFollowingNationalHolidaysLaborDayIndependen: '跳過以下國定假日：勞動節、獨立日、感恩節、聖誕節、新年',
    checked: '已選中',
    useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi: '使用聯繫人的本地時區，而不是計劃的時區',
    definePostreplyOrMeetingActionAndSequenceCompletio: '定義後回覆或會議操作和序列完成後的操作',
    stopRepliesHandling: '停止回覆處理',
    timeZone: '時區',
    country2: '國家',
    setMaxInitialEmailsDelaysEnabledisableOpensAndLink: '設置最大初始電子郵件延遲，啟用/禁用打開和鏈接跟踪',
    configuration: '配置',
    additionalInformation: '附加信息',
    connectALinkedinAccount: '連接LinkedIn帳戶',
    newLinkedinAccount: '新LinkedIn帳戶',
    feelFreeToRevisitAnyOfThePreviousStepsAtAnyTimeToR: '隨時隨地隨時重訪以前的任何步驟以重新檢視或更改您的設置',
    template2: '模板',
    next: '下一步',
    settings2: '設置',
    editThisTemplateScheduleFromThe: '從“設定”選項卡編輯此範本/日程',
    typeEmail: '輸入郵件',
    templateScheduling: '模板/日程安排',
    launch: '啟動',
    linkedin: 'LinkedIn',
    addAEmail: '添加郵件',
    addEmailAccount: '添加郵箱帳戶',
    addStep: '添加步驟',
    newEmailAccount: '新郵箱帳戶',
    craftYourLeadgenerationJourneyWithANewSequenceDefi: '通過定義新序列來設計您的潛在客戶生成之旅',
    craftYourCampaign: '設計您的活動',
    tailorYourOwnHoursToSuitSpecificSchedulingNeeds: '根據特定的安排需求自定義您自己的時間',
    personalizedScheduling: '個性化安排',
    requiredInformation: '所需信息',
    chooseEmailAccountsForSendingCampaignMessages: '選擇用於發送活動消息的郵箱帳戶',
    accounts: '帳戶',
    enterSequenceName: '',
    thisPageEmpowersYouToTailorYourSchedulingSequenceS: '此頁面使您能夠自定義您的安排序列，以滿足您的特定需求',
    schedule: '日程',
    useThisOptionToAddContactsToTheSequenceYouHaveCrea: '使用此選項將聯繫人添加到您已創建的序列中',
    save2: '儲存',
    days: '天',
    days2: '天內',
    within: '在',
    opened: '已打開',
    opened2: '已打開',
    day: '天',
    waitFor: '等待',
    day2: '天內',
    sendTestEmailToMe: '發送測試郵件給我',
    create: '創建',
    enterANameForCustomContactField: '為自定義聯繫人字段輸入名稱',
    a: 'A',
    byCancelingYouEnsureThatNoStepsWillBeDeleted: '取消後，將不會刪除任何步驟',
    to2: '到',
    enterNameForNewVersion: '輸入新版本的名稱',
    activated: '已激活',
    currentVersion: '當前版本',
    setTheNewVersionDefaultNow: '現在設置新版本為默認',
    enterNewName: '輸入新名稱',
    newVersion: '新版本',
    dontForgetToSetTheNewVersionDefaultOnceYouveFinish: '完成後，不要忘記設置新版本為默認',
    theDeletionOfThisConditionWillAlsoRemoveAllSubsequ: '刪除此條件將同時刪除所有後續步驟',
    pleaseEnterANewName2: '請輸入新名稱',
    aNewVersionOfTheSequenceHasBeenCreated: '已創建序列的新版本',
    youCanEitherEditTheCurrentVersionOrSaveItAndCreate: '您可以編輯當前版本，也可以保存它並創建新版本',
    areYouSureYouWantToProceed: '您確定要繼續嗎？',
    newVersionName: '新版本名稱',
    sequenceStart: '序列開始',
    chooseActions: '選擇操作',
    chooseSteps: '選擇步驟',
    enterChannel2: '輸入頻道',
    chooseCategories: '選擇類別',
    htmlMode: 'htmlMode',
    considerBroadeningYourSearchCriteria: '-請考慮擴大您的搜索條件',
    checkYourSpellingAndMakeSureThereAreNoTypos: '- 檢查拼寫，確保沒有拼寫錯誤',
    tryUsingDifferentKeywordsOrPhrases: '- 嘗試使用不同的關鍵字或短語',
    noResultsFoundForYourSearchCriteria: '未找到符合您的搜索條件的結果',
    fromScratch: '從頭開始',
    sender: '發件人',
    finished: '已完成',
    inProgress: '進行中',
    fromTemplate: '從模板',
    newSequence: '新序列',
    nameOwner: '名稱/所有者',
    sort: '排序',
    ownedBy: '所有者',
    starredOnly: '僅標星',
    starred: '標星',
    add2: '添加',
    lastSynced: '最後同步',
    hourlyLimit: '每小時限制',
    dailyLimit: '每日限制',
    account: '帳戶',
    skipTheFollowingNationalHolidaysLaborDayIndependen2: '跳過以下國定假日：勞動節、獨立日、感恩節、聖誕節、新年',
    useTheContactsLocalTimeZoneInsteadOfTheSchedulesTi2: '使用聯繫人的本地時區，而不是計劃的時區',
    saveChanges: '保存更改',
    emailSignature: '電子郵件簽名',
    reset2: '重置',
    pleaseNoteTheValueShouldBe0OrEmails: '請注意，填寫區間應大於0 或小於',
    youHaveNoScheduledTemplateYet: '您尚未安排模板',
    discoverPremiumOptions: '發現高級選項',
    mailbox: '郵箱',
    sendingLimits: '發送限制',
    connect: '連接',
    joinOurCommunityOfPremiumUsersTransformingEmailEng: '加入我們的高級用戶社區，改變電子郵件互動',
    enterYourEmailAddress: '輸入您的電子郵件地址',
    toJustMailbox: '到郵箱',
    yourCurrentBasicPlanAllowsAccess: '您當前的基本計劃允許訪問',
    setAsDefault2: '設置為默認',
    createNewSchedule: '創建新日程',
    enterTemplateName: '輸入模板名稱',
    googleMeet: 'googleMeet',
    emailPreview: '郵件預覽',
    addTemplate: '添加模板',
    simplifyYourWorkflowGroupYourSequenceTemplatesToKe: '簡化您的工作流程，將您的序列模板分組在一起，以保持組織良好',
    startCreatingPersonalizedTemplatesForYourSequences: '開始為您的序列創建個性化模板',
    noTemplateYet: '尚無模板',
    preview: '預覽',
    creatNewGroup: '創建新組',
    stayOrganizedWithYourSequenceTemplatesCreateGroups: '使用您的序列模板保持組織有序，創建組以將它們分組',
    noGroupsHaveBeenCreated: '尚未創建組',
    creatNewTemplate: '創建新模板',
    addSequenceTemplateToTheGroup: '將序列模板添加到組中',
    byCancelingYouEnsureThatNoChangesAreMade: '取消後，將不會進行任何更改',
    sequenceTemplates: '序列模板',
    youCanDeselectAndClickSaveToRemoveTheTemplateFromT: '您可以取消選擇並單擊“保存”將模板從組中移除',
    selectTemplateToAddToGroup: '選擇要添加到組中的模板',
    sequenceStatus: '序列狀態',
    sequenceStep: '序列步驟',
    newField: '新字段',
    enableTailoredDataEntryAndAdaptationToUniqueBusine: '啟用定制數據輸入和適應獨特業務需求',
    customContactFields: '自定義聯繫人字段',
    modality: '模態',
    enterADescriptionForCustomContactField: '為自定義聯繫人字段輸入描述',
    description2: '描述',
    enterADefaultValueForCustomContactField: '為自定義聯繫人字段輸入默認值',
    defaultValue: '默認值',
    enterAExampleForCustomContactField: '為自定義聯繫人字段輸入示例',
    example: '示例',
    enterPicklistoptionsForCustomContactField: '為自定義聯繫人字段輸入選擇列表選項',
    newCustomContactFields: '新自定義聯繫人字段',
    engagementMetrics: '參與度指標',
    message: '消息',
    to3: '到',
    receiver: '接收者',
    createNewCategory: '創建新類別',
    contacted: '已聯繫',
    sendTestEmail: '發送測試郵件',
    message2: '消息',
    includeSignature: '包括簽名',
    continueOnFail: '失敗後繼續',
    replyToPreviousThread: '回覆到以前的主題',
    sendAutomatic: '自動發送',
    searchByContacts: '按聯繫人搜索',
    processingErrorOccurredMaximumNumberOfUniqueContac: '發生處理錯誤，最大唯一聯繫人數為',
    shiftdragToMoveTheCanvas: 'Shift+拖動以移動畫布',
    active: '活躍',
    inactive: '不活躍',
    archived: '已歸檔',
    archive: '歸檔',
    unarchive: '取消歸檔',
    archiveSuccessfully: '歸檔成功',
    unarchiveSuccessfully: '取消歸檔成功',
    sequenceArchiveSuccess: '歸檔成功',
    sequenceUnarchiveSuccess: '取消歸檔成功',
    alphabetical: '按字母順序',
    creationDate: '創建日期',
    viewDate: '查看日期',
    staredFirst: '標星優先',
    SaveAsANewVersion: '保存為新版本',
    SaveCurrentVersion: '保存當前版本',
    schedules: '日程',
    EmailsSentPerDay: '每天發送的郵件數',
    EmailsSentPerHour: '每小時發送的郵件數',
    DelayBetweenEachEmailSent: '每封郵件之間的延遲',
    RecommendedDailyLimit: '建議每日限制',
    RecommendedHourlyLimit: '建議每小時限制',
    currentDelayAllows: '當前延遲允許',
    SetAsDefault: '設置為默認',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    Templates: '模板',
    Analytics: '分析',
    Meetings: '會議',
    Settings: '設置',
    EnterTemplateName: '輸入模板名稱',
    EnterGroupName: '輸入組名',
    ExportAllData: '導出所有數據',
    ExportAsCSV: '導出為CSV',
    Total: '總計',
    Opened: '已打開',
    Clicked: '已點擊',
    NotOpened: '未打開',
    Bounced: '退回',
    Replied: '已回復',
    Failed: '失敗',
    Inbox: '收件箱',
    Report: '報告',
    Log: '日誌',
    UseThisTemplate: '使用此模板',
    UseThisFolder: '使用此文件夾',
    UpdateThisTemplate: '更新此模板',
    RemoveFromGroup: '從組中移除',
    DeleteThisTemplate: '刪除此模板',
    MarkAsFinished: '標記為已完成',
    Remove: '移除',
    Pause: '暫停',
    Resume: '恢復',
    AddToSequence: '添加到序列',
    Export: '導出',
    InProgress: '進行中',
    Finished: '已完成',
    Paused: '已暫停',
    Engaged: '參與',
    Reply: '回復',
    NotSent: '未發送',
    MaxNumberOfEmailsPerDay: '每天最大發送郵件數',
    MaxNumberOfEmailsPerHour: '每小時最大發送郵件數',
    EnableOpensTracking: '啟用打開跟踪',
    EnableReplyTracking: '啟用回復跟踪',
    EnableClickTracking: '啟用點擊跟踪',
    ReplyByMessage: '通過消息回覆',
    BookAMeeting: '預約會議',
    ClickOnLink: '點擊鏈接',
    CreateManually: '手動創建',
    AddContactsToTheSequenceManually: '手動將聯繫人添加到序列',
    ImportFromCSV: '從CSV導入',
    UploadCSVFileToAddContactsToSequence: '上傳CSV文件以將聯繫人添加到序列',
    AddFromExisting: '從現有添加',
    AddExistingContactsIntoTheSequence: '將現有聯繫人添加到序列',
    ChooseFile: '選擇文件',
    MapAttributes: '映射屬性',
    Options: '選項',
    ReviewAndImport: '審核並導入',
    account_website: '帳號網站',
    linkedin_url: 'linkedin URL',
    raw_address: '地址',
    first_name: '姓氏',
    last_name: '名字',
    account_name: '帳戶名稱',
    Identifier: '識別符',
    firstName: '名字',
    lastName: '姓氏',
    EnterJobTitleOrPosition: '輸入職位或職位',
    EnterCompanyName: '輸入公司名稱',
    EnterYourCustomerID: '輸入您的客戶ID',
    EnterCompanyWebsiteURL: '輸入公司網站 URL',
    EnterLinkedInProfileURL: '輸入LinkedIn個人資料URL',
    EnterCity: '輸入城市',
    versionName: '版本名稱',
    PleaseEnterANewVersionName: '請輸入新版本名稱',
    EnterNewVersionName: '輸入新版本名稱',
    versions: '版本',
    DuplicateVersionSuccessfully: '複製版本成功',
    SequenceNameUpdatedSuccessfully: '序列名稱更新成功',
    SequenceNameCannotBeEmpty: '序列名稱不能為空',
    delete_success: '刪除成功',
    delete_fail: '刪除失敗',
    set_default_success: '設置默認成功',
    CreateNewVersionSuccessfully: '創建新版本成功',
    UpdateSuccessfully: '更新成功',
    AddToGroupSuccessfully: '添加到組成功',
    sendAutomaticEmail: '自動發送郵件',
    sendMessage: '發送消息',
    uploadFailed: '上傳失敗',
    InvalidEmailFormat: '無效的電子郵件格式',
    FailedToSetUpEmailAccount: '設置電子郵件帳戶失敗',
    noAccount: '無帳戶',
    addAnAccountToPersonalizYourExperienceAndUnlock: '添加帳戶以個性化您的體驗並解鎖',
    trackingFeaturesForThisSequence: '此序列的跟踪功能',
    UpdatedSuccessfully: '更新成功',
    update_fail: '更新失敗',
    connectYourGoogleMeetAccountToPubrioConversations: '將您的Google Meet帳戶連接到Pubrio會話',
    set_default_fail: '設置默認失敗',
    duplicatedSuccessfully: '複製成功',
    noStepsInSequencePleaseAddStepsFirst: '序列中沒有步驟，請先添加步驟',
    addLabelSuccessfully: '添加標籤成功',
    PleaseEnterSequenceName: '請輸入序列名稱',
    sequenceNameCannotBeEmpty: '序列名稱不能為空',
    sequenceNameUpdatedSuccessfully: '序列名稱更新成功',
    createStepFlowSuccessfully: '創建步驟流程成功',
    updateStepFlowSuccessfully: '更新步驟流程成功',
    testEmailSentSuccessfully: '測試郵件發送成功',
    removeEmailAccountSuccessfully: '移除郵箱帳戶成功',
    pleaseSelectTheDataToBeExported: '請選擇要導出的數據',
    confirm: '確認',
    saveFailed: '保存失敗',
    deleteThisStep: '刪除此步驟',
    Email: '郵件',
    LinkedIn: 'LinkedIn',
    API: 'API',
    Task: '任務',
    Custom: '自定義',
    proceedImmediately: '立即進行',
    dayss: '天',
    hourss: '時',
    minutess: '分',
    theConditionAndAllTheStepsThatFollowFromItWillBeDeleted: '該條件及其後續的所有步驟都將被刪除，是否繼續？',
    sendConnectionRequest: '發送連接請求',
    viewProfile: '查看個人資料',
    callAnAPI: '調用API',
    createAManualTask: '創建手動任務',
    hasEmailAddress: '有電子郵件地址',
    hasLinkedInURL: '有LinkedIn URL',
    hasPhoneNumber: '有電話號碼',
    customCondition: '自定義條件',
    openedEmail: '已打開郵件',
    clickOnLinkInEmail: '點擊郵件中的鏈接',
    unsubscribeFromEmail: '取消訂閱郵件',
    bookAMeeting: '預約會議',
    acceptedInvite: '接受邀請',
    conditions: '條件',
    Yes: ' 是 ',
    No: ' 否 ',
    feelFreeToEditTheContentAndSaveYourChangesDirectly: '隨時隨地隨時編輯內容並直接保存您的更改',
    activateTheNewVersionNow: '立即激活新版本',
    newVariable: '新變量',
    selectVariable: '選擇變量',
    ifLeadVariable: '如果變量',
    condition: '條件',
    selectCondition: '選擇條件',
    value: '值',
    contains: '包含',
    is_equal_to: '等於',
    is_empty: '為空',
    is_not_empty: '不為空',
    picture: '圖片',
    company_domain: '公司域名',
    company_name: '公司名稱',
    icebreaker: '破冰',
    change_steps_conditions: '更改步驟條件',
    ForceASpecificSenderForThisStep: '強制為此步驟指定發件人',
    method: '方法',
    selectASender: '選擇發件人',
    assignAPITaskTo: '分配API任務給',
    enterCompanyNamesAndPressEnterKey: '輸入公司名稱並按Enter鍵',
    nameOnCard: '卡上的名字',
    expiryDate: '到期日期',
    cvccvv: 'CVC/CVV',
    details: '詳細信息',
    notification: '通知',
    markAsAllRead: '標記為全部已讀',
    Personal: '個人',
    profile: '個人資料',
    extensions: '擴展',
    notifications: '通知',
    Company: '公司',
    roles: '角色',
    teams: '團隊',
    'current-usage': '當前使用情況',
    'upgrade-plan': '升級計劃',
    Products: '產品',
    APIKeys: 'API密鑰',
    popular: '熱門',
    free: '免費',
    lite: '精簡',
    growth: '增長',
    business: '商業',
    addAnyAdditionalStepsOrConditionsAsNeeded: '根據需要添加任何額外的步驟或條件',
    reviewAndFinalizeSequenceSettingsAndPreferences: '審查並完成序列設置和首選項',
    enterEmailAddressesMultipleCommaSeparated: '輸入電子郵件地址（多個用逗號分隔）',
    sharingRulesSelectTeamToEnableThisField: '共享規則：選擇團隊以啟用此字段',
    allSeatsAreCurrentlyOccupiedAnyNewUsersNeedingAPaidSeatWillIncurAdditionalCharges: '所有座位目前已被佔用。 需要付費座位的任何新用戶將產生額外費用。',
    dueToday: '今天到期',
    costPerBillingCycle: '每個計費週期的成本',
    'select...': '請選擇...',
    invite: '邀請',
    services: '服務',
    'services-group': '服務組',
    'all-notification-services': '所有通知服務',
    'services-status-update': '服務狀態更新',
    'system-group': '系統組',
    'all-notification-system': '所有通知系統',
    'system-new-feature-release': '系統新功能發布',
    'system-news-letter': '系統新聞通訊',
    'system-generic-alarm': '系統通用警報',
    markAsRead: '標記為已讀',
    showLess: '顯示更少',
    showMore: '顯示更多',
    changedStatusTo: '更改狀態為',
    cardInfo: '卡信息',
    creditCardDetails: '信用卡詳情',
    currentBalance: '當前餘額',
    enableAutoTopups: '啟用自動充值',
    automaticallyTopUp: '自動充值',
    whenBalanceFallsBelow: '當餘額低於',
    defaultPaymentMethod: '默認支付方式',
    autoTopupWillAutomaticallyChargeYourPaymentMethodT: '自動充值將自動向您的支付方式收費，以確保您的帳戶始終保持在指定的閾值以上。',
    gotIt: '知道了',
    pleaseEnsureYourPaymentMethodIsUptodateAndThatYouA: '請確保您的支付方式是最新的，並且您的帳戶有足夠的信用點來支付自動充值。',
    thresholdCredits: '閾值信用點',
    creditAmountCredits: '信用點數量',
    enablingAutoTopupsWillAutomaticallyAddCreditsToYou: '啟用自動充值將在您的帳戶餘額低於指定閾值時自動添加信用點。',
    thisWillAutomaticallyAddCreditsToYourAccountWhenev: '這將在您的帳戶餘額低於指定閾值時自動添加信用點。',
    autoTopupNotice: '自動充值通知',
    roleName: '角色名稱',
    addUser: '添加用戶',
    newRole: '新角色',
    userName: '用戶名',
    joined: '加入',
    thisRoleCurrentlyHasNoUsersAssignedToItStartAddingTeamMembersToDefineTheirResponsibilitiesAndStreamlineYourWorkflow: '此角色目前未分配任何用戶。 開始添加團隊成員以定義其責任並優化您的工作流程。',
    noUsersAssigned: '未分配用戶',

    'access-management-group': '訪問管理組',
    'access-management-roles': '訪問管理角色',
    'access-management-create-roles': '訪問管理創建角色',
    'access-management-edit-user-roles': '訪問管理編輯用戶角色',
    'access-management-add-role-to-users': '訪問管理為用戶添加角色',
    'access-management-delete-users-role': '訪問管理刪除用戶角色',
    'access-management-delete-roles': '訪問管理刪除角色',
    'access-management-read-roles-and-role-users': '訪問管理讀取角色和角色用戶',
    'access-management-teams': '訪問管理團隊',
    'access-management-delete-users-team': '訪問管理刪除用戶團隊',
    'access-management-read-teams-sharing-rules-teams-users': '訪問管理讀取團隊共享規則及團隊用戶',
    'access-management-create-teams': '訪問管理創建團隊',
    'access-management-edit-teams-sharing-rules': '訪問管理編輯團隊共享規則',
    'access-management-delete-teams-sharing-rules': '訪問管理刪除團隊共享規則',
    'access-management-add-and-update-team-to-users': '訪問管理添加和更新團隊到用戶',
    'access-management-read-team-relations': '訪問管理讀取團隊關係',
    'access-management-modify-team-relations': '訪問管理修改團隊關係',
    'intent-signal-group': '意圖信號組',
    'market-intent-signal': '市場意圖信號',
    'web-intent-signal': '網絡意圖信號',
    'web-intent-signal-add-and-activate': '網絡意圖信號添加並激活',
    'web-intent-signal-read-data': '網絡意圖信號讀取數據',
    'web-intent-signal-export-data': '網絡意圖信號導出數據',
    'web-intent-signal-delete-data': '網絡意圖信號刪除數據',
    'web-intent-signal-read-analytic': '網絡意圖信號讀取分析',
    'search-group': '搜索組',
    'company-search': '公司搜索',
    'prospects-search': '前景搜索',
    'sequence-group': '序列組',
    'sequences': '序列',
    'service-create-request': '服務創建請求',
    'service-update-request': '服務更新請求',
    'service-read-request': '服務讀取請求',
    'service-delete-service-attachment': '服務刪除附件',
    'service-delete-service-message': '服務刪除消息',
    'service-send-and-edit-service-message': '服務發送並編輯消息',
    'service-upload-service-attachment': '服務上傳附件',
    'settings-group': '設置組',
    'custom-fields': '自定義字段',
    'user-management-and-billing-group': '用戶管理和計費組',
    'billing-read-credit-card': '計費讀取信用卡',
    'billing-download-invoice': '計費下載發票',
    'billing-read-invoice': '計費讀取發票',
    'credit-usage': '信用使用',
    'credit-usage-read-profile-detail': '信用使用讀取配置檔詳細信息',
    'credit-usage-read-profile-usage': '信用使用讀取配置檔使用情況',
    'profile-and-team': '配置檔和團隊',
    'profile-and-team-invite-team-member': '配置檔和團隊邀請團隊成員',
    'profile-and-team-update-profile': '配置檔和團隊更新配置檔',
    'profile-and-team-delete-team-member': '配置檔和團隊刪除團隊成員',
    'profile-and-team-get-user-secret-token': '配置檔和團隊獲取用戶秘密令牌',
    'profile-and-team-update-user-and-password': '配置檔和團隊更新用戶和密碼',
    'profile-and-team-read-profile-users': '配置檔和團隊',
    'all-access-access-management-roles': '全訪問訪問管理角色',
    'all-access-access-management-teams': '全訪問訪問管理團隊',
    'all-access-market-intent-signal-page': '全訪問市場意圖信號頁面',
    'all-access-web-intent-signal-page': '全訪問網絡意圖信號頁面',
    'all-access-company-search-page': '全訪問公司搜索頁面',
    'all-access-prospects-search-page': '全訪問前景搜索頁面',
    'all-access-sequence-page': '全訪問序列頁面',
    'all-access-service-page': '全訪問服務頁面',
    'all-access-custom-fields-page': '全訪問自定義字段頁面',
    'all-access-billing-page': '全訪問計費頁面',
    'all-access-credit-usage-page': '全訪问信用使用頁面',
    'all-access-profile-and-team-page': '全訪問配置檔和團隊頁面',
    permission: '權限',
    newPermissionProfile: '新權限配置文件',
    enterAName: '輸入名稱',
    addUserToRole: '添加用戶到角色',
    changePermission: '更改權限',
    selectPermissionProfiles: '選擇權限配置文件',
    backToRoles: '返回角色',
    updateRole: '更新角色',
    enterADescription: '輸入描述',
    createANewRole: '創建新角色',
    AreYouSureYouWantToRemoveThisRole: '您確定要刪除此角色嗎？',
    AreYouSureYouWantToRemoveThisUserFromTheTeam: '您確定要從團隊中刪除此用戶嗎？',
    ProceedingWithDeletionWillPermanentlyRemoveTheRoleFromTheUser: '繼續刪除將永久從用戶中刪除角色。',
    ProceedingWithDeletionWillPermanentlyRemoveTheUserFromTheTeam: '繼續刪除將永久從團隊中刪除用戶。',
    addUserToTeam: '添加用戶到團隊',
    user: '用戶',
    sharingRuleGroup: '共享規則組',
    updateTeam: '更新團隊',
    createANewTeam: '創建新團隊',
    backToTeams: '返回團隊',
    listOfSharingRules: '共享規則列表',
    sharingRules: '共享規則',
    manageSharingRules: '管理共享規則',
    CreateAndCustomizeSharingRulesToControlAccessForDifferentUsersClickTheButtonBelowToAddNewSharingRules: '創建和自定義共享規則以控制不同用戶的訪問權限。 點擊下面的按鈕添加新的共享規則。',
    newSharingRules: '新共享規則',
    'full-sharing-market-intent-signal-data': '完全共享市場意圖信號數據',
    'full-sharing-web-intent-signal-data': '完全共享網絡意圖信號數據',
    'full-sharing-company-search-data': '完全共享公司搜索數據',
    'full-sharing-prospects-search-data': '完全共享前景搜索數據',
    'full-sharing-sequence-data': '完全共享序列數據',
    'full-sharing-service-data': '完全共享服務數據',
    'share-service-attachments': '共享服務附件',
    'share-service-messages': '共享服務消息',
    relation: '關係',
    newTeam: '新團隊',
    RenameSharingRules: '重命名共享規則',
    enterNewSharingRulesNameBelow: '在下面輸入新的共享規則名稱：',
    enterNewSharingRulesName: '輸入新的共享規則名稱',
    enterNewSharingRulesDescriptionBelow: '在下面輸入新的共享規則描述：',
    enterNewSharingRulesDescription: '輸入新的共享規則描述',
    enterSharingRulesName: '輸入共享規則名稱',
    currentUsers: '當前用戶',
    pendingUsers: '待定用戶',
    addRole: '添加角色',
    addTeam: '添加團隊',
    addPermission: '添加權限',
    'Re-invite': '重新邀請',
    relations: '關係',
    'is-parent-of': '是父級',
    addRelation: '添加關係',
    deleteRelation: '刪除關係',
    updateRelation: '更新關係',
    API_Keys: 'API密鑰',
    createNewKey: '創建新密鑰',
    createAPIKey: '創建API密鑰',
    APIs: 'API',
    setAsMasterKey: '設置為主密鑰',
    'growth-annual': "年度增强",
    business: "商務",
    "website-intent-data": "網站趨勢數據",
    'demo-identification': "演示標識",








    // services
    serviceMenuOverview: '概觀',
    serviceMenuTeams: '團隊',
    serviceMenuPipeline: '管道',
    serviceMenuAnalytics: '分析',
    serviceMenuResources: '資源',
    salesServices: '銷售服務',
    chooseYourTypeofServicesToStreamlineAndEnhanceYourBusinessOperations: '選擇您的服務類型以簡化和增強您的業務運營',
    dataPreparation: '數據準備',
    salesAssistant: '銷售助理',
    appointmentSetting: '會議設定',
    othersCustomService: '其他 (自定義服務)',
    requestService: '請求服務',
    learnMore: '了解更多',
    serviceRequestStatus: '服務請求狀態',
    noServiceRequestsYet: '尚無服務請求',
    submitYourFirstRequestToUnlockThePower: '提交您的第一個請求以解鎖我們銷售服務的力量！在這裡跟蹤您的請求狀態，讓您的銷售工作效能倍增。讓我們開始吧！',
    serviceTableItemName: '名稱',
    serviceTableItemStatus: '狀態',
    serviceTableItemType: '類型',
    serviceTableItemProgress: '進度',
    serviceTableItemStart: '開始',
    serviceTableItemEnd: '結束',
    serviceTableItemPriority: '優先級',
    created: '已創建',
    closed: '已關閉',
    paused: '已暫停',
    working: '進行中',
    completed: '已完成',
    resuming: '恢復中',
    reviewing: '審查中',
    others: '其他',
    data_preparation: '数据准备',
    sales_assistant: '销售助理',
    appointment_setting: '预约设置',
    appointment_booking: '預約',
    low: '低',
    normal: '正常',
    urgent: '緊急',
    high: '高',
    requiredCredits: "所需信用點",
    serviceCreated: "創建時間",
    QandARecord: "問題記錄",
    serviceAttachments: "附件",
    discussionBoard: '討論板',
    wannaAddSomething: "想添加一些內容?",
    serviceEdit: '編輯',
    serviceDelete: '删除',
    addDescription: '添加描述',
    DragDropor: '拖放或',
    chooseFile: '選擇',
    toUpload: '來上傳',
    ourDataPreparationService: '我們的數據準備服務提供量身定制的全面解決方案，以優化您的銷售資料管理。 我們專注於簡化流程，使您能够增强現有數据集，通過數據清理確保準確性，並創建完全符合您需求的定制清單。 這種方法為您提供了有效管理數據所需的靈活性和效率。',
    moreoverWithOurDedicatedDataManagement: '此外，有了我們專門的資料管理服務Pubrio，這個過程變得更加輕鬆。 您可以無縫地附加檔，並使用附加資訊擴充數据集。 Pubrio提供的不僅僅是豐富； 它還允許根據您的特定標準創建全新的清單。 這項個性化服務確保您的數据集經過精確定制，以滿足您的目標和行業標準，讓您能够控制您的數據格局。',
    flexibilityToAttach: "可以靈活地附加現有清單或訓示Pubrio根據指定標準查找新清單。",
    comprehensiveDataEnrichment: "全面的數據豐富，添加缺失的細節，增强數据集的完整性。",
    dataCleansingServicesToEnsureAccuracy: "數據清理服務，以確保準確性和質量，包括删除重複和糾錯。",
    abilityToInstructPubrio: "能够指導Pubrio根據精心定義的標準策劃全新的清單。",
    tailoringDatasetsPrecisely: '精確定制數据集，以符合特定目標和行業標準。',
    dataEnrichmentWithExistingList: "利用現有清單進行數據擴展：通過將當前清單上傳到Pubrio，輕鬆增强當前清單。 我們將填寫缺失的詳細資訊，如電子郵寄地址和電話號碼，以滿足您的要求。 例如，提供您的客戶資料庫，我們將使用重要資訊對其進行更新。",
    customListGenerationIf: "自定義清單生成：如果你沒有清單，讓Pubrio為你找到一個。 只要告訴我們你的標準，比如職位或行業，我們就會創建一個完美的清單。 例如，如果您需要一份特定領域的頂級科技高管名單，我們將為您處理。",
    dataCleansingForExistingLists: "現有清單的數據清理：使用Pubrio的數據清理服務確保您的數據準確可靠。 將您的清單發送給我們，我們將删除重複項並更正任何錯誤。 例如，如果您有一份最近的潛在客戶清單，我們將確保它已為您的行銷活動做好準備。",
    empoweringYourSalesEeavors: "我們的銷售助理服務為您的銷售工作提供支援，可在各個關鍵領域提供全面的支持，確保您的外聯和溝通策略得到精心調整，以取得成功。 無論您是希望製作引人注目的內容、無縫組織活動、開發量身定制的演示文稿，還是完善您的銷售和溝通策略，我們的專職團隊都會在這裡加大您的銷售力度，提升您的品牌在市場上的影響力。",
    craftingCompellingContent: "製作引人入勝的內容：有效的溝通是吸引觀眾注意力的關鍵。 我們的團隊擅長根據您的品牌和受眾偏好量身定制引人入勝的內容。 從發人深省的LinkedIn帖子到資訊豐富的博客文章和引人注目的社交媒體更新，我們確保您的資訊與您的目標市場產生共鳴。",
    seamlessEventAndWebinarPreparation: "無縫的活動和網絡研討會準備：活動和網路會議是與觀眾互動和產生潛在客戶的强大工具。 我們的銷售助理服務負責從計畫、促銷到執行的所有細節。 無論是舉辦資訊豐富的網絡研討會還是組織網絡活動，我們都會確保您的活動給人留下持久的印象。",
    tailoredContentDevelopmentAndPresentation: "量身定制的內容開發和演示：通過引人入勝的演示和個性化資訊來吸引您的受眾。 我們的團隊專門致力於打造具有視覺吸引力的套牌，並精心製作能與特定受眾群體產生共鳴的定制資訊。 無論您是向投資者推介還是培養潛在客戶，我們都會確保您的內容脫穎而出。",
    strategicSalesAndCommunicationFrameworks: "戰畧銷售和溝通框架：通過戰畧指導和有效的溝通腳本簡化您的銷售流程。 我們的銷售助理服務為您的團隊提供了推動轉化和建立有意義的聯系所需的工具和策略。",
    craftingCompellingContent: "製作引人入勝的內容",
    companyLinkedInPosts: "公司領英帖子（頻率：每週或每月）",
    personalLinkedInPosts: "個人LinkedIn帖子（頻率：靈活，基於偏好和內容可用性）",
    websiteBlogPosts: "網站博客文章（頻率：通常每週一次或每兩週一次）",
    socialMediaPosts: "社交媒体帖子（频率：每日或根据内容策略）",
    emailMarketingCampaigns: "電子郵件行銷活動（頻率：每週或每兩周）",
    seamlessEventAndWebinarPreparation: "無縫的活動和網絡研討會準備",
    webinarAndWorkshopPreparation: "網絡研討會和研討會準備",
    networkingAndOfflineEvent: "網絡和線下活動協調",
    tailoredContentDevelopment: "量身定制的內容開發和演示",
    companyDeckPreparation: "公司甲板準備（用於演示、推介等）",
    highlyPersonalizedMessage: "高度個性化的消息創建（針對特定受眾群體量身定制）",
    strategicSales: "戰畧銷售和溝通框架",
    salesCallScripts: "銷售電話腳本",
    emailOutreachTemplate: "電子郵件外聯範本",
    salesChannelStrategies: "銷售管道策略",
    ourAppointmentSettingService: "我們的預約設定服務旨在通過促進重要聯系、促進行業曝光率和培養有價值的前景來提升您的業務。 我們專注於個性化的行業曝光、無縫的研討會和網絡研討會協調、預熱新的前景，並向新的受眾發起吸引人的方法。",
    individualIndustryAppointment: "個別行業預約曝光與反應",
    tailoredOutreach: "針對行業內的關鍵決策者和影響者進行量身定制的文宣，確保有針對性的曝光。",
    strategicFollowups: "戰畧跟進使我們能够評估反應，解决問題，並促進更深入的參與。",
    eachInteraction: "每一次互動都是根據接受者的個人資料和偏好進行個性化的，最大限度地發揮影響。",
    seminarWebinarCoordination: "研討會和網絡研討會協調",
    ourExpertTeamHandles: "我們的專家團隊負責研討會和網絡研討會協調的各個方面，從最初的規劃到活動後的跟進。",
    weMeticulouslyPlan: "我們精心策劃和執行活動，以展示您的專業知識，確保參與者獲得無縫和引人入勝的體驗。",
    byLeveragingAdvancedTechnologyPlatforms: "通過利用先進的科技平臺，我們加强互動和參與，最大限度地提高您的活動的影響力。",
    newProspectWarmUp: "新客戶預熱",
    proactiveEngagementStrategies: "採用積極主動的參與戰畧，為新的前景預熱，為富有成果的討論奠定基礎。",
    tailoredCommunicationStrategies: "量身定制的溝通策略旨在建立融洽的關係、信任和對您的產品的興趣。",
    throughStrategicNurturing: '通過戰畧培育，我們讓潛在客戶參與進來，並為轉換做好準備，新增成功任命的可能性。',
    newAudienceApproach: "新受眾方法",
    weEmployInnovativeStrategies: "我們採用創新的策略來接觸新的受眾，並將您的業務範圍擴大到傳統管道之外。",
    targetedOutreachCampaigns: "有針對性的外聯活動是在徹底的受眾研究和細分的基礎上精心製定的，以確保相關性和有效性。",
    ourApproachIsContinuouslyOptimized: "我們的方法基於迴響和見解不斷優化，確保我們在接觸和吸引新受眾方面處於領先地位。",
    individualIndustry: "單獨行業預約曝光與反應",
    personalizedAppointmentSetting: "與行業利益相關者的個性化預約設置。",
    strategicFollowupCommunication: "戰略性後續溝通以進一步參與。",
    comprehensivePlanning: "全面規劃和執行活動。",
    utilizationOfAdvancedTechnologies: "利用先進技術增強互動。",
    newProspectWarmUp: "新前景熱身",
    proactiveEngagementStrategy: "針對前景的主動參與策略。",
    customizedCommunication: "定制溝通以保持興趣。",
    newAudienceApproach: "新觀眾方法",
    innovativeOutreach: "為新觀眾設計的創新性外展活動。",
    continuousRefinement: "根據反饋和洞察不斷完善。",
    customService: "定制服務",
    tailoredToMeet: "我們的定制服務可滿足您的獨特需求，提供靈活性和適應性，以滿足各種需求。 無論您是在網絡研討會參與者跟踪、線下活動推廣、廣告增强還是任何其他專業任務方面尋求幫助，我們的專職團隊都會幫助您高效地實現目標。",
    webinarParticipantsTracking: "網絡研討會參與者跟踪",
    comprehensiveTrackingOfWebinar: "全面跟踪網絡研討會參與者，分析參與度並收集有價值的見解。",
    customizedReportsAndAnalytics: "定制報告和分析，以評估網絡研討會的有效性並優化未來戰畧。",
    seamlessIntegration: "與現有系統無縫集成，實現簡化的資料管理和報告。",
    offlineEventPromotion: "線下活動推廣",
    strategicPlanning: "戰略規劃和執行線下活動推廣活動，以最大限度地提高出席率和參與度。",
    utilizationOfTargetedMarketingChannels: '利用有針對性的行銷管道和促銷策略，有效地接觸到您想要的受眾。',
    creativeApproaches: '針對每個活動的獨特特點量身定制的創造性方法，確保最大的影響力和投資回報率。',
    adsEnhancement: "廣告增强",
    optimizationOfAdCampaigns: "優化各種平臺上的廣告活動，以提高可見性、參與度和轉化率。",
    ABTesting: "A/B測試和效能分析，以確定最有效的廣告創意、資訊傳遞和目標定位策略。",
    continuousMonitoring: "持續監控和調整廣告活動，以適應不斷變化的市場動態並最大限度地提高效果。",
    customizedSolutions: "解決方案",
    ourCustomService: "我們的定制服務超越了預定義的功能，以滿足您的特定要求和偏好。",
    whetherYouNeedAssistance: "無論您在資料分析、內容創建、戰略規劃或業務的任何其他方面是否需要幫助，我們都可以根據您的需求定制我們的服務。",
    ourFlexibleApproach: "我們靈活的方法使我們能够與您密切合作，開發定制的解決方案，帶來切實的成果並推動業務增長。",
    setupAndImplementation: "建立和實施網絡研討會參與者跟踪系統。",
    customizedReporting: "定制報告和分析，以衡量網絡研討會的表現和觀眾參與度。",
    developmentAndExecution: "製定並執行有針對性的線下活動推廣活動。",
    coordinationOfPromotionalActivities: "協調促銷活動，包括印刷資料、直郵和本地廣告。",
    evaluationAndOptimization: "評估和優化跨數位平臺的廣告活動。",
    creativeDevelopment: "創造性開發、目標細化和績效監控，以最大限度地提高投資回報率。",
    tailoredServices: "針對特定需求和目標量身定制的服務。",
    collaborationWithOurTeam: "與我們的團隊合作，開發滿足您獨特需求的定制解決方案。",
    serviceGetStarted: "開始",
































    keyFeatures: '主要特點',
    dataEnrichment: '數據豐富',
    dataCleansing: "數據整理",
    customListGeneration: "自定義清單生成",
    serviceOfferings: "提供的服務",
    introDataPreparation: "我們的數據準備服務提供量身定制的全面解決方案，以優化您的銷售資料管理。 我們專注於…",
    introSalesAssitant: '銷售助理服務超越了一般的行政支持，它專門專注於銷售流程中不可或缺的任務。 它...',
    introAppointmentSetting: "這項服務包括一種個性化的、動手操作的方法，根據提供的服務將客戶與其目標受眾聯系起來...",
    introOtherService: '“其他”服务允许客户通过提交有关其独特需求的详细信息来请求定制服务。。。',
    questionCommonPagePriorityLevelTitle: '您對此任務的優先順序是多少？',
    questionCommonPagePriorityLevelDesc: '說明您的任務優先順序有助於我們及時滿足您的需求。 您可以在提交後更改它。',
    questionCommonPageTimelineTitle: '考慮訂閱條款或時間表？',
    questionCommonPageTimelineDesc: '讓我們知道您的首選時間以及您計畫何時開始。',
    questionCommonPageServiceRequestTitle: '準備好開始了嗎？ 首先，給你的應用程序一個獨特的名稱',
    questionCommonPageServiceRequestDesc: "我們將使用它來跟踪和更新您的儀錶板中的進度。",
    questionCommonPageServiceDurationTitle: "您的服務期會持續多久？",
    quesitonCommonPageInvestmentBudget: "您用於發展業務和項目的投資預算，是否超過每個月12000港幣？",
    questionCommonPageHearAboutUs: '您是從哪裡聽說我們的？',
    questionCommonPageUploadTitle: '請在此處上傳您的Excel/CSV檔案以進行數據豐富或數據清理',
    questionCommonPageUploadDesc: "如果您選擇創建自定義清單生成，則可以跳過或按一下“下一步”而不上傳",
    questionDataPreparationPageInterestedDataOptimizationTitle: '您對數據優化的哪些服務感興趣?',
    questionDataPreparationPageInterestedDataOptimizationDesc: '您需要在下一步中附加一個CSV檔案，以便選擇數據富集或數據清理',
    questionDataPreparationPageDataAttributesTitle: '選擇要合併到檔案中的特定數據内容（如果適用）',
    questionDataPreparationPageDataAttributesDesc: '選擇一個或者多個',
    questionDataPreparationPageNumberOfLeadsTitle: '您希望為您的業務產生或獲得多少潛在客戶？',
    questionDataPreparationPageNumberOfLeadsDesc: '指定數量或範圍，為您定制我們的服務',
    slugServiceName: '服務名稱',
    slugServiceDesc: '服務描述',
    errEnterServiceName: '請輸入服務名稱',
    errEnterDescription: "請輸入服務描述",
    errSelectAService: "請選擇一個服務",
    errSelectSpecificDataAttributes: "請選擇具體的數據内容",
    errInputTheQuantity: "請輸入數量",
    errSelectPriorityLevel: "請選擇優先順序",
    errSelectTimeline: "請選擇時間",
    errInputContent: "請輸入內容",
    errInvestmentBudget: "請選擇是或否",
    errServiceDuration: "請選擇服務期限",
    errHearAboutUs: '請選擇聽說通路',
    placeHolderEnterServiceName: "請輸入服務名稱",
    placeHolderEnterServiceDescription: '請輸入服務描述',
    placeHolderEnterListGeneration: "輸入生成的清單",
    placeHolder150TO250: 'placeHolder150TO250',

    serviceDownload: "下載",
    serviceExample: '例子',
    forYourReference: '作為您的參攷',
    yourFileNeedsToInclude: '您的文件至少需要包含網站或公司名稱',
    includeAll: '選擇全部',
    serviceNext: '下一步',
    serviceBack: "上一步",
    data_enrichment: "數據豐富",
    data_cleansing: "數據整理",
    custom_list_generation: "自定義清單生成",
    job_title: "職位名稱",
    timeline_within_one_weeks: "一周之內",
    timeline_one_to_two_weeks: '一到兩周',
    timeline_two_to_four_weeks: "二到四周",
    timeline_recurring: "迴圈的",
    timeline_custom_range: "自定義範圍",
    hear_about_us_social_media: '社交媒體',
    hear_about_us_google: '穀歌',
    hear_about_us_online_article: "網上的文章",
    hear_about_us_webinar: '網絡研討會',
    hear_about_us_referral: "介紹",
    hear_about_us_custom_input: '其他',
    duration_last_than_one_month: "超過一個月",
    duration_one_to_three_months: '一到三個月',
    duration_over_three_months: '超過三個月',
    duration_decide_later: "之後再說",
    yes: "是",
    no: "否",
    byCancelingYouCanEnsureThatNoTasksWillBeClosed: '通過取消，可以確保不會關閉任何任務',
    areYouSureYouWantToCloseThisTask: '確定要關閉此任務?',
    onceTheTaskIsClosed: "一旦任務關閉，它就無法撤銷，您也將無法繼續執行它",
    Close: '關閉',
    ourTeamIsWorkingOnThisTask: "我們的團隊正在完成這項任務",
    pleaseProviceYourReasonForThisAction: "請填寫終止原因",
    sendRequest: '發送請求',
    OK: "好的",
    areYouSureYouWantToDelete: "您確定要删除",
    proceedingWithDeletionWillResultInPermanentRemovalOfTheFile: "繼續删除將導致檔案被永久删除。",
    byCancelYouEnsureThatNoFileWillBeDeleted: "通過取消，可以確保不會删除任何檔案",
    areYouSureYouWantToDeleteThis: "您確定要删除這個",
    serviceMessage: "消息",
    serviceFile: "文件",
    proceedingWithDeletionWillResultInPermanentRemovalOfThisMessage: "繼續刪除將導致永久刪除此資訊。",
    proceedingWithDeletionWillResultInPermanentRemovalOfThisFile: "繼續刪除將導致永久刪除此檔案。",
    youEnsureThatNoMessagesWillBeDeleted: "通過取消，您可以確保不會删除任何消息",
    youEnsureThatNoFilessWillBeDeleted: "通过取消，您可以确保不会删除任何文件",
    serviceDeleteFail: '删除失敗',
    enterYourReason: "請輸入您的原因",
    seriveStartDate: "開始日期",
    serviceEndDate: "結束日期",
    yourRequestHasBeenSent: "您的請求已發送",
    sendUsYourFeedback: "給我們發送迴響",
    weAreConstantlyImproving: "我們在不斷進步！ 分享您的迴響，塑造我們平臺的未來。",
    describeYourExperienceHere: "請在此處描述您的體驗。。。",
    additionallyYouCanAttachAnyScreenshots: "此外，您可以附加任何螢幕截圖或檔案以提供更多詳細資訊。",
    sendFeedback: '發送迴響',
    serviceResume: '繼續',
    yourRequestHasBeenResumed: '您的請求已恢復',
    yourRequestIsCurrentlyPaused: '您的請求當前已暫停',
    serviceEdited: '已編輯',
    theFileSizeCannotExceed50M: "文件大小不能超過50M",
    feedbackSendSuccessfully: "迴響成功",
    feedbackSendFailed: "迴響失敗",
    autoSave: '自動保存',
    serviceDescShowMore: '展開',
    serviceDescShowLess: '收起',
    members: "成員",
    shareTo: "分享給。。。",
    findTeamMembers: "找到團隊成員",

    // price
    "exportLimit": "每次資料匯出上限",
    "customRolePermission": "用戶權限設定",
    "teamManagement": "團隊管理設定",
    "API": "API",
    "linkedinAccount": " Linkedin 帳戶",
    "perPageLimit": "每頁搜尋",
    "recordUploadLimit": "每次資料上傳數量",
    "creditRollover": "信用點數累計",
    "quotaSharing": "配額共享",
    "quotaAdjustment": "配額調整",
    "add_On": "增值服務",
    "topUpCredits": "儲值信用點數",
    "webVisitorIdentification": "意圖分析",
    "recordSelection": "搜尋篩選",
    "portal": "平台",
    "queryLimitPerHour": "每小時査詢次數",
    "queryLimitPerDay": "每日査詢次數",
    "callLimitPerHour": "每小時調用次數",
    "callLimitPerDay": "每日調用次數",
    "callLimitPerMonth": "每月調用次數",
    "basicSequencing": "基礎流程自動化",
    "numberOfSequences": "工作流程數量",
    "numberOfMailboxesPerUser": "用戶郵箱數量",
    "numberOfLinkedinAccountPerUser": "用戶Linkedin帳戶數量",
    "numberOfSlackAccountPerUser": "用戶Slack帳戶數量",
    "automatedFollowUp": "自動回覆",
    "advancedSequencing": "自訂工作流程",
    "abTesting": "A/B 測試",
    "multipleEmailTemplates": "工作流程範本",
    "reporting": "數據分析",
    "emailOpenTracking": "郵件打開跟踪",
    "emailClickTracking": "郵件點擊跟踪",
    "emailReplyTracking": "郵件回復跟踪",
    "support": "技術支援",
    "priorityEmailSupport": "電郵支援",
    "companySelectionLimit": "公司選擇數量",
    "prospectSelectionLimit": "客戶選擇數量",
    "totalPageLimit": "總頁數搜尋",
    "pricingYear": "年",
    "growth-annual": "初創計劃",
    "business-annual": "商務計劃",
    "pricingSearch": "客戶搜尋",
    "pricingSearches": "搜尋",
    "pricingMonth": "月",
    "pricingYear": "年",
    "topupCredit": "儲值信用點數",
    "perUserPerYear": "每位用戶/每年\n每年計費",
    "perUserPerMonth": "每位用戶/每月\n每月計費",
    "customCredits": "定制信用點數",
    "customSearches": "定制客戶搜尋",
    "queryLimitPerMonth": "每月査詢次數",
    "customized": "定制方案",
    "custom": "定制",
    "free": "免費計劃",
    "growth": "初創計劃",
    "business": "商務計劃",
    "organization": "企業計劃",
    "search": "數據搜索",

    // setting
    pleaseSelectTheUserYouWantToAdd: '請選擇您想添加的用戶',
    pleaseSelectTheUserYouWantToDelete: "請選擇您想删除的用戶",
    pleaseSelectTheUserYouWantToAddPermission: "請選擇您想添加許可權的用戶",

}
