<template>
  <div class="pagination">
    <!-- 上 -->
    <div class="pager" v-if="total !== 0">
      <span>{{ start }}-{{end}} {{$t('of')}} {{total_num}}</span>
    </div>
    <el-button class="btn" :disabled="pageNo == 1" @click="getPageNo(pageNo - 1)">
      <svg-icon icon-class="left"/>
    </el-button>
    <!-- 中 -->

    <el-select popper-class="pageNum" class="pageNum_select"  v-model="pageNum"
               @change="getPageNo(pageNum)" >
      <el-option
          v-for="item in pages"
          :key="item"
          :label="item"
          :value="item">
      </el-option>
    </el-select>

    <!-- 下 -->
    <el-button class="btn" :disabled = "Boolean(!total) || (pageNo === totalPages)" @click="getPageNo(pageNo + 1)">
      <svg-icon icon-class="right"/>
    </el-button>


  </div>
</template>

<script>

export default {
  name: "Pagination",
  props: ["pageNo", "pageSize", "total",'totalPages'],
  data() {
    return {
      pageNum: 1
    };
  },
  computed: {
    start(){
      return (this.pageNo - 1) * this.pageSize + 1
    },
    end(){
      return this.pageNo * this.pageSize > this.total ? this.total : this.pageNo * this.pageSize
    },
    total_num(){
      if (this.total>1000000){
        return `${(this.total/1000000).toFixed(1)}M`
      }else if (this.total>1000){
        return `${(this.total/1000).toFixed(1)}K`
      }else {
        return this.total
      }
    },
    pages() {
      if (this.totalPages <= 150) {
        return this.totalPages
      } else if (this.totalPages > 150) {
        return 150
      }
    }
  },
  watch:{
    pageNo:{
      handler(val){
        console.log(val);
        this.pageNum = val
      },
      immediate:true
    },
    pageNum(val){
      this.$emit('getPageNo', val)
    }
  },
  methods: {
    getPageNo(val) {
      //自定义事件子页面向父页面传参，计算属性值
      this.$emit('getPageNo', val)
    },

  }
};
</script>

<style lang="scss" scoped>
.pagination {
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding:10px;
  .btn{
    width: 32px;
    height: 32px;
    border: 0;
    background: transparent;
    border-radius: 8px;
    .svg-icon{
      font-size: 12px;
      fill:$color-black;
    }
  }
  .btn:hover{
  //  background:$color-gray-0;
    .svg-icon{
  //    fill: $color-blue-3;
    }
  }
  .btn:disabled{
    background:transparent;
    .svg-icon{
      fill: $color-gray-0;
    }
  }

  .pager{
    margin:0 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-align: left;
    color:$color-gray-1;

  }

}

</style>

