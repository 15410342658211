import request from '@/utils/request';

export function getStatusList(data){
  return request({
      method: 'get',
      url:'/services/status',
  })
};


export function getPrioritiesList(){
  return request({
      method: 'get',
      url:'/services/priorities',
  })
};

export function getServicesList(data, cancelToken) {
  return request({
    method:'post',
    url:'/services',
    data,
    cancelToken,
  })
}

export function getServiceTypeList(data) {
  return request({
    method: 'get',
    url:'/services/questionnaires',
    data,
  })
}

export function createService(data) {
  return request({
    method: 'post',
    url: '/services/create',
    data,
  })
}

export function getServiceLookup(data) {
  return request({
    method: 'post',
    url: '/services/lookup',
    data,
  })
}

export function updateService(data) {
  return request({
    method: 'post',
    url: '/services/update',
    data,
  })
}

export function getMessageList(data) {
  return request({
    method: 'post',
    url: '/services/messages',
    data,
  })
}

export function sendMessage(data) {
  return request({
    method: 'post',
    url: '/services/messages/send',
    data,
  })
}

export function editMessage(data) {
  return request({
    method: 'post',
    url: '/services/messages/edit',
    data,
  })
}

export function deleteMessage(data) {
  return request({
    method: 'delete',
    url: '/services/messages/delete',
    data,
  })
}

export function getAttachmentList(data) {
  return request({
    method: 'post',
    url: '/services/attachments',
    data,
  })
}

// download service attachment
export function attachmentDownload(data) {
  return request({
    method: 'post',
    url: '/attachments/download',
    data,
    responseType: 'blob'
  })
}

// delete service attachment
export function attachmentDelete(data) {
  return request({
    method: 'delete',
    url: '/services/attachments/delete',
    data,
  })
}

// add service attachment
export function attachmentAdd(data) {
  return request({
    method: 'post',
    url: '/services/attachments/upload',
    data,
  })
}

export function uploadSeqFile(data, file,cb, cancelToken){
  data.append("encoding_type", "latin1");
  return request({
      url: '/attachments/batch_upload',
      method: 'post',
      data,
      cancelToken,
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      onUploadProgress(e) {
        const complete = e.loaded / e.total * 100;
        cb && cb(file,Math.round(complete));
      }
  })
}

export function sendPauseRequest(data) {
  return request({
    method: 'post',
    url: '/services/pause_request/send',
    data,
  })
}

export function resolvePauseRequest(data) {
  return request({
    method: 'post',
    url: '/services/pause_request/resolve',
    data,
  })
}


export function getAssigneesList(data) {
  return request({
    method: 'post',
    url: '/services/assignees',
    data,
  })
}

export function addAssignee(data) {
  return request({
    method: 'post',
    url: '/services/assignees/add',
    data,
  })
}


export function deleteAssignee(data) {
  return request({
    method: 'delete',
    url: '/services/assignees/delete',
    data,
  })
}



