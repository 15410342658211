import router from '@/router'
import store from '@/store'


const whitelist = ['/login','/404','/signup','/reset','/forgot']

router.beforeEach(async (to,from,next) => {
    if(store.state.user.token){
        next()
    }else{
        if(whitelist.indexOf(to.path) > -1){
            next()
        }else{
            next('/login')
        }
    }
})
