export default {
  bind(el) {
    const handleScroll = () => {
      el.classList.add('scrolling');
      clearTimeout(el.scrollTimeout);
      el.scrollTimeout = setTimeout(() => {
        el.classList.remove('scrolling');
      }, 1000);
    };
    el.__handleScroll__ = handleScroll;
    el.addEventListener('scroll', handleScroll);
  },
  unbind(el) {
    el.removeEventListener('scroll', el.__handleScroll__);
    delete el.__handleScroll__;
  }
};
