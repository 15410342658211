import * as API from '@/api/notification'

const state = {
  notificationList:{},
  notificationSettingList:{},
  notificationTypeList:{},
  isGoSetting: false,
  isDisabled:true,
  pageInfo: {
    page: 1,
    per_page: 25,
  }
}

const mutations = {
  setIsDisabled(state,data){
    state.isDisabled = data
  },
  setGoSetting(state, data) {
    state.isGoSetting = data
  },
  setNotificationList(state, data) {
    state.notificationList = data
  },
  setNotificationSettingList(state, data) {
    state.notificationSettingList = data
  },
  setNotificationTypeList(state, data) {
    state.notificationTypeList = data
  }
}

const actions = {
  async getNotificationList(context, data) {
    const id = this.state.user.currentId.profile_id
    try{
      const res = await API.getNotificationList({
        "profile_id":id,
        ...data,
      });
      const result = {
        ...res.data,
        profile_user_notifications: res.data.profile_user_notifications.map((item) => ({
          ...item,
          more: false,
        }))
      }
      context.commit('setNotificationList', result);
      return result;
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async updateNotification(context, data) {
    const id = this.state.user.currentId.profile_id
    data = {
      "profile_id":id,
      ...data
    }
    try{
      const res = await API.updateNotification(data)
      return res?.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getNotificationTypeList(context, data) {
    const id = this.state.user.currentId.profile_id
    try{
      const res = await API.getNotificationTypeList({
        "profile_id":id,
        ...this.pageInfo,
      })
      context.commit('setNotificationTypeList', res?.data);
      return res?.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async getNotificationSettingList(context, data) {
    const id = this.state.user.currentId.profile_id
    try{
      const res = await API.getNotificationSettingList({
        "profile_id":id
      })
      context.commit('setNotificationSettingList', res?.data)
      return res?.data
    }catch(err){
      console.log(err);
      throw err;
    }
  },
  async updateNotificationSetting(context, data) {
    const id = this.state.user.currentId.profile_id
    data = {
      "profile_id":id,
      ...data
    }
    try{
      const res = await API.updateNotificationSetting(data)
      return res?.data
    }catch(err){
      console.log(err);
      throw err;
    }
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
