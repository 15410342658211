import * as SCHEMA from '@/api/schema'

const state = {
  CRM_Columns: JSON.parse(localStorage.getItem('CRM_Columns')) || [],
  SchemaList: JSON.parse(localStorage.getItem('Schema_List')) || [],
  CONTACT_ID: JSON.parse(localStorage.getItem('CONTACT_ID')) || '',
  ACCOUNT_ID: JSON.parse(localStorage.getItem('ACCOUNT_ID')) || '',
  CustomTypeList: JSON.parse(localStorage.getItem('CustomType_List')) || [],
  CustomList: JSON.parse(localStorage.getItem('Custom_List')|| '[]') ,
}
const mutations = {
  setCRMDeduplication(state, data){
    state.CRM_Columns = data
    localStorage.setItem('CRM_Columns', JSON.stringify(data))
  },
  setCRMSchemaList(state, data){
    data?.forEach(item => {
      if(item.slug === 'contact') {
        state.CONTACT_ID = item.crm_schema_id
        localStorage.setItem('CONTACT_ID', JSON.stringify(item.crm_schema_id))
      }else if(item.slug === 'account'){
        state.ACCOUNT_ID = item.crm_schema_id
        localStorage.setItem('ACCOUNT_ID', JSON.stringify(item.crm_schema_id))
      }
    })
  },
  setCustomTypeList(state, data){
    state.CustomTypeList = data
    localStorage.setItem('CustomType_List', JSON.stringify(data))
  },
  setCustomList(state, data){
    data && (state.CustomList = data)
    localStorage.setItem('Custom_List', JSON.stringify(state.CustomList))
  }
}
const actions = {
  async getCRMDeduplication(context) {
    try {
      const res = await SCHEMA.getCRMDeduplication();
      context.commit('setCRMDeduplication', res?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCRMSchemaList(context) {
    try {
      const id = this.state.user.currentId.profile_id;
      const res = await SCHEMA.getCRMSchemaList({ "profile_id": id });
      context.commit('setCRMSchemaList', res?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCustomTypeList(context, data) {
    try {
      const res = await SCHEMA.getCustomTypeList(data);
      context.commit('setCustomTypeList', res?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getCustomList(context) {
    try {
      const res = await SCHEMA.getCRMColumns({
        "profile_id": this.state.user.currentId.profile_id,
        "is_default": true,
        "crm_schema_id": this.state.schema.CONTACT_ID
      });
      context.commit('setCustomList', res?.data);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async createCustomList(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      const res = await SCHEMA.createCRMColumn({ "profile_id": id, ...data });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async updateCustomList(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      const res = await SCHEMA.updateCRMColumn({ "profile_id": id, ...data });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async deleteCRMColumn(context, data) {
    try {
      const id = this.state.user.currentId.profile_id;
      const res = await SCHEMA.deleteCRMColumn({ "profile_id": id, ...data });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};

export default{
  namespaced: true,
  state,
  mutations,
  actions,
}
