import request from '@/utils/request';

/*----------------user------------------*/
export function getUser(){
    return request({
        url: '/user',
        method: 'get'
    })
}
export function getProfileList(){
    return request({
        url: '/user/profiles',
        method: 'get',
    })
}
export function updateUser(data){
    return request({
        url: '/user/update',
        method: 'post',
        data
    })
}
export function updatePassword(data){
    return request({
        url: '/user/update/password',
        method: 'post',
        data
    })
}
/*-----------------profile----------------------*/
export function getProfile(data){
    return request({
        url: '/profile',
        method: 'post',
        data
    })
}
export function getUsage(data){
    return request({
        url: '/profile/usage',
        method: 'post',
        data
    })
}
/*--------------profile-user-------------------*/
export function getProfileUsers(data){
    return request({
        url: '/profile/users',
        method: 'post',
        data
    })
}
export function getProfileFullUsers(data){
    return request({
        url: '/profile/users/full',
        method: 'post',
        data
    })
}
export function inviteProfileUser(data){
    return request({
        url: '/profile/users/invite',
        method: 'post',
        data
    })
}
export function deleteUser(data){
    return request({
        url: '/profile/users/delete',
        method: 'delete',
        data
    })
}


/*--------------------------------订阅-------------------------------- */
/*--------------------订阅grade列表------------------*/
export function getSubscription(data){
    return request({
        url: '/subscriptions',
        method: 'post',
        data
    })
}
/*--------------------订阅grade------------------*/
export function upgradeSubscription(data){
    return request({
        url: '/subscriptions/upgrade/',
        method: 'post',
        data
    })
}

/*---------------------------Billing-------------------------*/

/*--增加信用卡--*/
export function addCredit(data){
    return request({
        url: '/billing/payment_methods/add',
        method: 'post',
        data
    })
}
/*--获取支付方式--*/
export function getPaymentMethods(data){
    return request({
        url: '/billing/payment_methods',
        method: 'post',
        data
    })
}
/*--更新(删除)支付方式--*/
export function deleteCredit(data){
    return request({
        url: '/billing/payment_methods/update',
        method: 'post',
        data
    })
}
/*---------发票-----------*/
export function getInvoices(data){
    return request({
        url: '/billing/invoices',
        method: 'post',
        data
    })
}
export function downloadInvoice(data){
    return request({
        url: '/billing/invoices/download',
        method: 'post',
        data,
    })
}
/*--购买credit--*/
export function purchaseCredit(data){
    return request({
        url: '/billing/credits/purchase',
        method: 'post',
        data
    })
}

/*---------------------------contact team sales-------------------------*/
export function contactTeamSales(data){
    return request({
        url: '/utils/metadata',
        method: 'post',
        data
    })
}

export function estimatePrice(data){
    return request({
        url: '/billing/users/purchase/estimate',
        method: 'post',
        data
    })
}
