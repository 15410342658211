import { getStatusList, getPrioritiesList } from '@/api/services';


const state = {
  statusList: [],
  prioritiesList: [],
  questionnaireIdToContentMap: {},
  allQuestions: {},
  questionnaireIdToPageMap: {},
  dataKeyToServiceIdMap: {},
  questionIdToValueMap: {},
  questionIdToCustomValueMap: {},
  attachments: {},
  statusMap: {},
  statusSlugMap: {},
}

const mutations = {
  setQuestionMap(state, payload) {
    state.questionnaireIdToContentMap = payload;
  },
  setAllQuestion(state, payload) {
    state.allQuestions = payload
  },
  setQuestionnaireIdToPageMap(state, payload) {
    state.questionnaireIdToPageMap = payload;
  },
  setDataKeyToServiceIdMap(state, payload) {
    state.dataKeyToServiceIdMap = payload;
  },
  setQuestionIdToValueMap(state, payload) {
    state.questionIdToValueMap = payload;
  },
  setQuestionIdToCustomValueMap(state, payload) {
    state.questionIdToCustomValueMap = payload;
  },
  setStatusMap(state, payload) {
    state.statusMap = payload;
  },
  setStatusSlugMap(state, payload) {
    state.statusSlugMap = payload;
  },
  setPrioritiesList(state, payload) {
    state.prioritiesList = payload;
  },
}

const actions = {
  async fetchStatusList({ commit }) {
    const { data } = await getStatusList();
    const statusMap = {};
    const statusSlugMap = {};
    data.map((item) => {
      statusMap[item.service_status_id] = item;
      statusSlugMap[item.slug] = item;
    })
    commit('setStatusMap', statusMap);
    commit('setStatusSlugMap', statusSlugMap);
  },
  async fetchPrioritiesList({ commit }) {
    try {
      const { data } = await getPrioritiesList();
      commit('setPrioritiesList', data);
    } catch (error) {
      
    }
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}