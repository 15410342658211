import request from '@/utils/request';

export function getDynamicVariables(data){
    return request({
        url: '/dynamic_variables',
        method: 'post',
        data
    })
}
export function createDynamicVariables(data) {
    return request({
        url: '/dynamic_variables/create',
        method: 'post',
        data
    })
}
export function updateDynamicVariables(data) {
    return request({
        url: '/dynamic_variables/update',
        method: 'post',
        data
    })
}
export function deleteDynamicVariables(data) {
    return request({
        url: '/dynamic_variables/delete',
        method: 'delete',
        data
    })
}

export function getSequenceList(data){
    return request({
        url: '/sequences',
        method: 'post',
        data
    })
}
export function lookupSequence(data){
    return request({
        url: '/sequences/lookup',
        method: 'post',
        data
    })
}
export function archiveSequences(data){
    return request({
        url: '/sequences/archive',
        method: 'post',
        data
    })
}

export function createSequences(data){
    return request({
        url: '/sequences/create',
        method: 'post',
        data
    })
}
export function updateSequences(data){
    return request({
        url: '/sequences/update',
        method: 'post',
        data
    })
}
export function activeSequences(data){
    return request({
        url: '/sequences/activate',
        method: 'post',
        data
    })
}
export function inactiveSequences(data){
    return request({
        url: '/sequences/deactivate',
        method: 'post',
        data
    })
}


/*-----------------tags--------------------*/
export function getSeqTagList(data){
    return request({
        url: '/sequences/tags',
        method: 'post',
        data
    })
}


export function createSeqTag(data){
    return request({
        url: '/sequences/tags/create',
        method: 'post',
        data
    })
}

export function addSeqTag(data){
    return request({
        url: '/sequences/tags/add',
        method: 'post',
        data
    })
}
export function removeSeqTagFromSeq(data){
    return request({
        url: '/sequences/tags/remove',
        method: 'post',
        data
    })

}


export function updateSeqTag(data){
    return request({
        url: '/sequences/tags/update',
        method: 'post',
        data
    })
}


export function deleteSeqTag(data){
    return request({
        url: '/sequences/tags/delete',
        method: 'delete',
        data
    })
}

/*-----------------channels--------------------*/
export function getSeqChannelTypes(){
    return request({
        url: '/channels/types',
        method: 'get',
    })
}
/*-----------------channel-template--------------------*/
export function getSeqChannelTemplateList(data){
    return request({
        url: '/channels/templates',
        method: 'post',
        data
    })
}

export function lookupSeqChannelTemplate(data){
    return request({
        url: '/channels/templates/lookup',
        method: 'post',
        data
    })
}
export function createSeqChannelTemplate(data){
    return request({
        url: '/channels/templates/create',
        method: 'post',
        data
    })
}
export function createSeqTemplateVersion(data){
    return request({
        url: '/channels/templates/create_version',
        method: 'post',
        data
    })
}
export function updateSeqChannelTemplate(data){
    return request({
        url: '/channels/templates/update',
        method: 'post',
        data
    })
}
export function deleteSeqChannelTemplate(data){
    return request({
        url: '/channels/templates/delete',
        method: 'delete',
        data
    })
}
export function setDefaultChannelTemplate(data){
    return request({
        url: '/channels/templates/default',
        method: 'post',
        data
    })
}
/*-----------------channel-template-group----------------------*/
export function getSeqChannelTemplateGroupList(data){
    return request({
        url: '/channels/templates/groups',
        method: 'post',
        data
    })
}
export function createSeqChannelTemplateGroup(data){
    return request({
        url: '/channels/templates/groups/create',
        method: 'post',
        data
    })
}
export function updateSeqChannelTemplateGroup(data){
    return request({
        url: '/channels/templates/groups/update',
        method: 'post',
        data
    })
}
export function deleteSeqChannelTemplateGroup(data){
    return request({
        url: '/channels/templates/groups/delete',
        method: 'delete',
        data
    })
}
export function addTemplateToGroup(data){
    return request({
        url: '/channels/templates/relationship/create',
        method: 'post',
        data
    })
}
export function updateTemplateGroup(data){
    return request({
        url: '/channels/templates/relationship/update',
        method: 'post',
        data
    })
}
export function removeTemplateFromGroup(data){
    return request({
        url: '/channels/templates/relationship/delete',
        method: 'delete',
        data
    })
}

/*-----------------schedule--------------------*/
export function getSeqScheduleList(data){
    return request({
        url: '/schedules',
        method: 'post',
        data
    })
}
export function createSeqSchedule(data){
    return request({
        url: '/schedules/create',
        method: 'post',
        data
    })
}
export function updateSeqSchedule(data){
    return request({
        url: '/schedules/update',
        method: 'post',
        data
    })
}
export function deleteSeqSchedule(data){
    return request({
        url: '/schedules/delete',
        method: 'delete',
        data
    })
}
export function setDefaultSchedule(data){
    return request({
        url: '/schedules/default',
        method: 'post',
        data
    })
}

/*-----------------upload--------------------*/
export function uploadSeqFile(data){
    return request({
        url: '/attachments/batch_upload',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}
export function getImage(profileId, hash){
    return request({
        url: `/attachments/hash/${profileId}/${hash}`,
        method: 'get',
        responseType: 'blob'
    })
}
export function downloadFile(data){
    return request({
        url: '/attachments/download',
        method: 'post',
        data,
        responseType: 'blob'
    })
}

/*------------------account-email--------------------*/
export function getSeqAccountEmailList(data){
    return request({
        url: '/channels/email_accounts',
        method: 'post',
        data
    })
}
export function getEmailAuth(data){
    return request({
        url: '/channels/email_accounts/auth_url',
        method: 'post',
        data
    })
}
export function updateEmailAccount(data){
    return request({
        url: '/channels/email_accounts/update',
        method: 'post',
        data
    })
}
export function setDefaultEmail(data){
    return request({
        url: '/channels/email_accounts/default',
        method: 'post',
        data
    })
}
export function unlinkAccount(data){
    return request({
        url: '/channels/email_accounts/unlink',
        method: 'post',
        data
    })
}
/*------------------account-LinkedIn--------------------*/
export function getSeqAccountLinkedinList(data){
    return request({
        url: '/channels/linkedin_accounts',
        method: 'post',
        data
    })
}
/*------------------step--------------------*/
export function getSeqStepList(data){
    return request({
        url: '/sequences/steps',
        method: 'post',
        data
    })
}

export function createSeqStep(data){
    return request({
        url: '/sequences/steps/create',
        method: 'post',
        data
    })
}

export function createStepVersion(data){
    return request({
        url: '/sequences/steps/create_version',
        method: 'post',
        data
    })
}
export function updateSeqStep(data){
    return request({
        url: '/sequences/steps/update',
        method: 'post',
        data
    })
}

export function deleteSeqStep(data){
    return request({
        url: '/sequences/steps/delete',
        method: 'delete',
        data
    })
}
export function setDefaultStep(data){
    return request({
        url: '/sequences/steps/default',
        method: 'post',
        data
    })
}
/*------------------contact--------------------*/
export function getSeqContactList(data){
    return request({
        url: '/sequences/contacts/',
        method: 'post',
        data
    })
}
export function getContactMetadata(data){
    return request({
        url: '/sequences/contacts/metadata',
        method: 'post',
        data
    })
}
export function getContactStatus(data){
    return request({
        url: '/sequences/contacts/status',
        method: 'get',
    })
}
export function creatSeqContactManually(data){
    return request({
        url: '/sequences/contacts/create',
        method: 'post',
        data
    })
}
export function analyzeSeqContact(data){
    return request({
        url: '/sequences/contacts/analyze',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}
export function importSeqContact(data){
    return request({
        url: '/sequences/contacts/import_files',
        method: 'post',
        data
    })
}
export function updateSeqContact(data){
    return request({
        url: '/sequences/contacts/update',
        method: 'post',
        data
    })
}
export function deleteSeqContact(data){
    return request({
        url: '/sequences/contacts/delete',
        method: 'delete',
        data
    })
}
export function addContactToMy(data){
    return request({
        url: '/sequences/contacts/import_lists',
        method: 'post',
        data
    })
}

export function updateContactStatus(data){
    return request({
        url: '/sequences/contacts/update/status',
        method: 'post',
        data
    })
}
export function exportContacts(data){
    return request({
        url: '/sequences/contacts/export',
        method: 'post',
        data
    })
}
export function exportContactsFull(data){
    return request({
        url: '/sequences/contacts/export/full',
        method: 'post',
        data
    })
}
export function getSeqContactAllIds(data){
    return request({
        url: '/sequences/contacts/select',
        method: 'post',
        data
    })
}




/*------------------send Email--------------------*/
export function sendEmail(data){
    return request({
        url: '/channels/email_accounts/send_test_email',
        method: 'post',
        data
    })
}

/*------------------inbox--------------------*/

export function getSeqInboxList(data){
    return request({
        url: '/sequences/inboxes',
        method: 'post',
        data
    })
}
export function getSeqInboxMeta(data){
    return request({
        url: '/sequences/inboxes/metadata',
        method: 'post',
        data
    })
}
export function lookupSeqInbox(data){
    return request({
        url: '/sequences/inboxes/lookup',
        method: 'post',
        data
    })
}
export function emailToSeqContact(data){
    return request({
        url: '/sequences/inboxes/channels/email/send',
        method: 'post',
        data
    })
}

export function getMessageStatus(data){
    return request({
        url: '/channels/messages/status',
        method: 'get',
    })
}
export function deleteInbox(data){
    return request({
        url: '/sequences/inboxes/delete',
        method: 'delete',
        data
    })
}
export function exportSeqInboxFull(data){
    return request({
        url: '/sequences/inboxes/export/full',
        method: 'post',
        data
    })
}
export function exportSeqInboxPartial(data){
    return request({
        url: '/sequences/inboxes/export',
        method: 'post',
        data
    })
}
export function getSeqInboxAllIds(data){
    return request({
        url: '/sequences/inboxes/select',
        method: 'post',
        data
    })

}

/*------------------inbox category-------------------*/

export function getSeqInboxCategoryList(data){
    return request({
        url: '/sequences/inboxes/categories',
        method: 'post',
        data
    })
}
export function createSeqInboxCategory(data){
    return request({
        url: '/sequences/inboxes/categories/create',
        method: 'post',
        data
    })
}
export function addSeqToCategory(data){
    return request({
        url: '/sequences/inboxes/categories/add',
        method: 'post',
        data
    })
}
export function editSeqInboxCategory(data){
    return request({
        url: '/sequences/inboxes/categories/edit',
        method: 'post',
        data
    })
}
export function removeSeqFromCategory(data){
    return request({
        url: '/sequences/inboxes/categories/delete',
        method: 'post',
        data
    })
}
export function updateSeqInboxCategory(data){
    return request({
        url: '/sequences/inboxes/categories/update',
        method: 'post',
        data
    })
}
export function deleteSeqInboxCategory(data){
    return request({
        url: '/sequences/inboxes/categories/delete',
        method: 'delete',
        data
    })
}

