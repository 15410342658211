import request from '@/utils/request';

export function getApiKeysList(data){
    return request({
        url: '/api_keys',
        method: 'post',
        data
    })
}
export function createApiKey(data){
    return request({
        url: '/api_keys/create',
        method: 'post',
        data
    })
}
export function revealApiKey(data){
    return request({
        url: '/api_keys/reveal',
        method: 'post',
        data
    })
}
export function deleteApiKey(data){
    return request({
        url: '/api_keys/delete',
        method: 'delete',
        data
    })
}
