import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { Message } from 'element-ui';

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
  }
});

// 请求拦截器
request.interceptors.request.use(
  config => {
    if (store.state.user.token) {
      config.headers['Authorization'] = `Bearer ${store.state.user.token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  response => {
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return Promise.reject(new Error(`HTTP Error: ${status}`));
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        // 401: 未登录/token过期/同一账号在异地登陆
        case 401:
          Message.error({
            message: 'Unauthorized, please login again',
            duration: 1500,
            forbidClick: true
          });
          store.commit('user/removeToken');
          store.commit('user/removeApikey');
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1500);
          break;
        // 404请求不存在
        case 404:
          Message.error({
            message: 'Network request does not exist',
            duration: 1500,
            forbidClick: true
          });
          break;
        // 其他错误，直接抛出错误提示
        case 422:
        case 426:
        case 524:
          Message.error({
            message: error?.response?.data?.message,
            duration: 2000,
            forbidClick: true
          });
          break;
        case 402:
          break;
        default:
          Message.error({
            message: error.message,
            duration: 2000,
            forbidClick: true
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default request;
