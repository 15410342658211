<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="feed-dialog"
    :modal-append-to-body="false"
    :before-close="handleClose">
    <div class="content">
      <div class="title">{{ $t('sendUsYourFeedback')}}</div>
      <div class="desc">{{ $t('weAreConstantlyImproving') }}</div>
      <el-input
        type="textarea"
        :rows="5"
        :placeholder="$t('describeYourExperienceHere')"
        v-model="content">
      </el-input>
      <div class="desc">{{ $t('additionallyYouCanAttachAnyScreenshots') }}</div>
      <FileUploadItem 
        ref="fileUpload"
        :uploadCb="uploadCb" 
        :item="{slug: 'fileList'}"
      />
      <el-button class="feedback-btn" @click="sendFeedback" :loading="loading">{{ $t('sendFeedback') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import FileUploadItem from '@/views/services/overview/components/FileUploadItem.vue';
import { sendFeedback } from '@/api/user';

const REQUEST_TYPE_FEEDBACK_ID = 13;

export default {
  props: ['dialogVisible'],
  components: {
    FileUploadItem
  },
  data() {
    return {
      content: null,
      fileList: [],
      loading: false,
    }
  },
  computed:{
    ...mapState('user', ['currentId']),
  },
  methods: {
    sendFeedback() {
      this.loading = true;
      sendFeedback({
        profile_id: this.currentId.profile_id,
        description: this.content, 
        request_type_id: REQUEST_TYPE_FEEDBACK_ID,
        attachments: this.fileList,
      }).then(res => {
        this.loading = false;
        this.$emit('update:dialogVisible', false);
        this.$message.success(this.$t('feedbackSendSuccessfully'));
        this.fileList = [];
        this.content = null;
        this.$refs.fileUpload.fileList = [];
        this.$refs.fileUpload.list = {};
      }).catch(err => {
        this.loading = false;
        this.$message.error(this.$t('feedbackSendFailed'));
      })
    },
    uploadCb(slug, list) {
      this.fileList = Object.keys(list).map((item) => list[item].hash);
    },
    handleClose() {
      this.$emit('update:dialogVisible', false);
      this.fileList = [];
      this.content = null;
      this.$refs.fileUpload.fileList = [];
      this.$refs.fileUpload.list = {};
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  display: none;
}

.feed-dialog {
  width: 640px;
}

.content {
  color: $color-blue-8;
  .title {
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.6px;
    text-align: center;
  }
  .desc {
    margin: 16px 0;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.39px;
  }
}

.feedback-btn {
  margin-top: 10px;
  width: 100%;
  height: 47px !important;
  padding: 14px 0;
  background-color: $color-blue-8;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  text-align: center;
  user-select: none;
}

::v-deep .upload-list {
  display: inline-block;
  width: 100%;
  height: 150px;
  overflow: scroll;
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  ::v-deep .el-dialog {
    &.feed-dialog {
      width: 559px;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  ::v-deep .el-dialog {
    &.feed-dialog {
      width: 512px;
    }
  }
}
@media (max-width:767px) {
  ::v-deep .el-dialog {
    &.feed-dialog {
      width: 463px;
    }
  }
}
</style>
