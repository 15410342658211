<template>
  <div class="dashboard" >
    <navBar style="height:56px"/>
    <keep-alive exclude="intent">
      <router-view />
    </keep-alive>
  </div>

</template>

<script>
import navBar from "@/views/dashboard/navBar";
export default {
  name: "Dashboard",
  components:{
    navBar,

  },
  data(){
    return{
      isDashboard: true,
    }
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">
.dashboard{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height:100vh;
}
</style>



