import * as SEQ from '@/api/sequence'
import {Message} from "element-ui";
import _ from "lodash";



const state = {
  inboxLoading:true,
  seqProspectLoading:false,
  stepEmailPreContent:{
    subject: "",
    content: "",
    templateId:'',
    groupId:'',
    fileList: [],
    reply_pre_thread: false,
    continue_on_fail: false,
    include_signature: false,
  },
  stepEmailContent:{
    subject: "",
    content: "",
    templateId:'',
    groupId:'',
    fileList: [],
    reply_pre_thread: false,
    continue_on_fail: false,
    include_signature: false,
  },
  contactList:[],
  seqChannelTemplateRelationship:[],
  stepsList:[],
  lockVersion:false,
  notSave:false,
  isShow: true,
  T_isShow: true,
  sequence_loading:false,
  stepVisible: false,//增加步骤条
  contactPageInfo: {},
  editorContent: '',
  dynamicList:[],
  inboxList:[],
  inboxCategoryList:[],
  inboxStatusNum:[],
  contactStatus:localStorage.getItem('contactStatus')?JSON.parse(localStorage.getItem('contactStatus')):[],
  messageStatus:[],
  seqGroupList:[],
  accountEmailList:localStorage.getItem('accountEmailList')?JSON.parse(localStorage.getItem('accountEmailList')):{channel_email_accounts:[],
    pagination:{}},
  accountLinkedInList:localStorage.getItem('accountLinkedInList')?JSON.parse(localStorage.getItem('accountLinkedInList')):{channel_linkedin_accounts:[], pagination:{}},
  defaultAccountEmail:'',
  channelTemplateList:[],
  seqChannelTypes:localStorage.getItem('seqChannelTypes')?JSON.parse(localStorage.getItem('seqChannelTypes')):[],
  seqTemplateList:localStorage.getItem('seqTemplateList')?JSON.parse(localStorage.getItem('seqTemplateList')):[],
  scheduleList:localStorage.getItem('scheduleList')?JSON.parse(localStorage.getItem('scheduleList')):[],
  sequenceList:{
    seqData: [],
    pagination:{},
  },
  seqTagList:[],
  selected:{
    "profile_id":'',
    "page":1,
    "per_page":25,
    "order_by":"name",//name, last_modified, created_at
    "is_archived":false,
    "is_active":false,
    "is_started":false,
    "sequence_name":"",
    "sequence_tags":[],
    "user_emails":[]
  },
  inboxSelected:{
    "profile_id":'',
    "sequence_id":'',
    "page":1,
    "per_page":25,
    "categories":[],
    "sequence_step_id":'',
    "channel_types":[],
    "channel_email_account_id":'',
    "start_date":"",
    "end_date":"",
    "channel_message_status_id":'',
    "search_term":""
  },
  inboxStatusSelected:{
    "profile_id":'',
    "sequence_id":'',
    "sequence_step_id":'',
    "channel_email_account_id":'',
    "start_date":"",
    "end_date":"",
    "categories":[],
    "search_term": "",
    "channel_types":[],
  },
  seqContactSelected:{
    "sequence_id":'',
    "profile_id":'',
    "page":1,
    "per_page":25,
    "order_by":"", //name, last_modified, created_at
    "first_name": "",  //这两个怎么选择
    "last_name": "",  //这两个怎么选择
    "account_name":"",
    "title": "",
    "location_id": [],
    "account_website":"", //这个要新增吗？
    // "sequence_step_id":1,
    // "step_index_id": "gia82jsdg8qgq0j",
    "step_index":'',
    "sequence_contact_status_id": "",

//需新增字段
    companySize: [],
    industry: [],
    categories: [],
    technologies: [],
  },
  inboxCategorySelected:{
    "profile_id":'',
    "sequence_inbox_category_name":"",
    "sequence_step_id":'',
    "channel_email_account_id":'',
    "start_date":'',
    "end_date":'',
    "channel_message_status_id": '',
    "search_term": '',
    "sequence_id": '',
    "channel_types":[],
  }
}
const mutations = {
  setNotSave(state,val){
    state.notSave = val
  },
  setVersionLock(state,val){
    state.lockVersion = val
  },
  setContent(state, val) {
    state.editorContent = val
  },
  setStepVisible(state, val) {
    state.stepVisible = val
  },
  setLoading(state,val){
    state.sequence_loading = val
  },
  setSelected(state, val) {
    let id = parseInt(this.state.user.currentId.profile_id)
    let temp = {...state.selected, page: 1,...val,'profile_id':id}
    if (_.isEqual(state.selected,temp)){
      state.isStopSearch = true
    }else{
      state.selected = temp
    }
  },
  setInboxSelected(state, val) {
    let id = parseInt(this.state.user.currentId.profile_id)
    let temp = {...state.inboxSelected, page: 1,...val,'profile_id':id}
    state.inboxSelected = temp
  },
  setInboxStatusSelected(state, val) {
    let id = parseInt(this.state.user.currentId.profile_id)
    let temp = {...state.inboxStatusSelected,...val,'profile_id':id}
    state.inboxStatusSelected = temp
  },
  setSeqContactSelected(state, val) {
    let id = parseInt(this.state.user.currentId.profile_id)
    let temp = {...state.seqContactSelected, page: 1,...val,'profile_id':id}
    state.seqContactSelected = temp
  },
  setContactStatus(state, val) {
    state.contactStatus = val
    localStorage.setItem('contactStatus',JSON.stringify(state.contactStatus))
  },
  setInboxCategorySelected(state, val) {
    let id = parseInt(this.state.user.currentId.profile_id)
    let temp = {...state.inboxCategorySelected, ...val,'profile_id':id}
    state.inboxCategorySelected = temp
  },
  setCollapse(state, data) {
    state.isShow = !state.isShow
  },
  setT_Collapse(state, data) {
    state.T_isShow = !state.T_isShow
  },
  setSequenceList(state,data){
    if(data){
      state.sequenceList.seqData = data.sequences
      state.sequenceList.pagination = data.pagination
    }else{
      state.sequenceList = {
        seqData: [],
        pagination: {},
      }
    }
    localStorage.setItem('sequenceList',JSON.stringify(state.sequenceList))
  },
  setSeqTagList(state,data){
    data? state.seqTagList = data:state.seqTagList = []
  },
  setSeqChannelTypes(state,data){
    state.seqChannelTypes = data
    localStorage.setItem('seqChannelTypes',JSON.stringify(state.seqChannelTypes))
  },
  setSeqTemplateList(state,data){
    state.seqTemplateList = data
    localStorage.setItem('seqTemplateList',JSON.stringify(state.seqTemplateList))
  },
  setSeqScheduleList(state,data){
    state.scheduleList = data
    localStorage.setItem('scheduleList',JSON.stringify(state.scheduleList))
  },
  setSeqChannelTemplateList(state,data){
    state.channelTemplateList = data
  },
  setSeqAccountEmailList(state,data){
    state.accountEmailList = data
    data.channel_email_accounts?.forEach(item => {
      if(item.is_default){
        state.defaultAccountEmail = item
      }
    })
    localStorage.setItem('accountEmailList',JSON.stringify(state.accountEmailList))
  },
  setSeqAccountLinkedInList(state,data){
    state.accountLinkedInList = data
    localStorage.setItem('accountLinkedInList',JSON.stringify(state.accountLinkedInList))
  },
  setSeqDynamicVariableList(state,data){
    state.dynamicList = data
    localStorage.setItem('dynamicList',JSON.stringify(state.dynamicList))
  },
  setSeqChannelTemplateGroupList(state,data){
    state.seqGroupList = data
    localStorage.setItem('seqGroupList',JSON.stringify(state.seqGroupList))
  },
  setSeqStepList(state,data){
    state.stepsList = data
  },
  setStepEmailPreContent(state,data){
    if(data){
      state.stepEmailPreContent = { ...state.stepEmailPreContent, ...data }
    }else{
      state.stepEmailPreContent = {
        subject: "",
        body: "",
        content: "",
        templateId:'',
        groupId:'',
        fileList: [],
        reply_pre_thread: false,
        continue_on_fail: false,
        include_signature: false,
      }
    }
  },
  setStepEmailContent(state,data){
    state.stepEmailContent = data
  },
  setSeqChannelTemplateRelationship(state,data){
    state.seqChannelTemplateRelationship = data
  },
  setSeqContactList(state,data){
    state.contactList = data
    localStorage.setItem('seqContactList',JSON.stringify(state.contactList))
  },
  setSeqInboxList(state,data){
    state.inboxList = data
  },
  setSeqInboxCategoryList(state,data){
    state.inboxCategoryList = data
  },
  setMessageStatus(state,data){
    state.messageStatus = data
    localStorage.setItem('messageStatus',JSON.stringify(state.messageStatus))
  },
  setInboxStatusNum(state,data){
    state.inboxStatusNum = data
  }
}
const actions = {
  async getSequenceList({ dispatch, commit }, data) {
    commit('setLoading', true);
    try {
      await dispatch('getSequenceListWithoutLoading', data);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      commit('setLoading', false);
    }
  },
  async getSequenceListWithoutLoading(context, data) {
    await context.commit('setSelected', data);
    try {
      const result = await SEQ.getSequenceList(this.state.sequence.selected);
      if (result?.data) {
        context.commit('setSequenceList', result?.data);
        return result?.data;
      } else if (!result?.message.includes('200')) {
        Message.error(result.message);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSequences(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSequences({
        'profile_id': id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSequences(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSequences({
        "profile_id": id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async archiveSequences(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.archiveSequences({
        "profile_id": id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async activeSequences(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.activeSequences({
        "profile_id": id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async inactiveSequences(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.inactiveSequences({
        "profile_id": id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSeqTagList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqTagList({
        profile_id: id,
        ...data
      });
      context.commit('setSeqTagList', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqTag(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqTag({
        profile_id: id,
        sequence_tag_name: data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqTag(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqTag({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addSeqTag(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.addSeqTag({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSeqChannelTypes(context) {
    try {
      const result = await SEQ.getSeqChannelTypes();
      context.commit('setSeqChannelTypes', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------channel-template--------------------*/
  async getSeqChannelTemplateList(context, data) {
    let id = this.state.user.currentId.profile_id;
    let email = this.state.user.user.email;
    try {
      const result = await SEQ.getSeqChannelTemplateList({
        profile_id: id,
        "page": 1,
        "per_page": 25,
        "user_emails": [email],
        ...data
      });
      const relationship = result?.data.relationship;
      const pagination = result?.data.pagination;
      context.commit('setSeqChannelTemplateList', result?.data.channel_templates);
      context.commit('setSeqChannelTemplateRelationship', relationship);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async lookupSeqChannelTemplate(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.lookupSeqChannelTemplate({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqChannelTemplate(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqChannelTemplate({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqTemplateVersion(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqTemplateVersion({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqChannelTemplate(context, data) {
    let id = parseInt(this.state.user.currentId.profile_id);
    try {
      const result = await SEQ.updateSeqChannelTemplate({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqChannelTemplate(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqChannelTemplate({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------template-group--------------------*/
  async getSeqChannelTemplateGroupList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqChannelTemplateGroupList({
        profile_id: id,
        ...data
      });
      context.commit('setSeqChannelTemplateGroupList', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqChannelTemplateGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqChannelTemplateGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqChannelTemplateGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSeqChannelTemplateGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqChannelTemplateGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqChannelTemplateGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addTemplateToGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.addTemplateToGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateTemplateGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateTemplateGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async removeTemplateFromGroup(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.removeTemplateFromGroup({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------schedule--------------------*/
  async getSeqScheduleList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqScheduleList({
        profile_id: id,
      });
      context.commit('setSeqScheduleList', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqSchedule(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqSchedule({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqSchedule(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSeqSchedule({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqSchedule(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqSchedule({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setDefaultSchedule(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.setDefaultSchedule({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------sequence-account-email--------------------*/
  async getSeqAccountEmailList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqAccountEmailList({
        profile_id: id,
        ...data
      });
      context.commit('setSeqAccountEmailList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getEmailAuth(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getEmailAuth({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSeqAccountLinkedinList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqAccountLinkedinList({
        profile_id: id,
        ...data
      });
      context.commit('setSeqAccountLinkedInList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createDynamicVariables(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      await SEQ.createDynamicVariables({
        profile_id: id,
        ...data
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateDynamicVariables(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      await SEQ.updateDynamicVariables({
        profile_id: id,
        ...data
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteDynamicVariables(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      await SEQ.updateDynamicVariables({
        profile_id: id,
        ...data
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------sequence-steps--------------------*/
  async getSeqStepList(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqStepList({
        profile_id: id,
        ...data
      });
      context.commit('setSeqStepList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqStep(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqStep({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createStepVersion(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createStepVersion({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqStep(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSeqStep({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqStep(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqStep({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async setDefaultStep(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.setDefaultStep({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------sequence-contact--------------------*/
  async getSeqContactList(context, data) {
    state.seqProspectLoading = true;
    context.commit('setSeqContactSelected', data);
    try {
      const result = await SEQ.getSeqContactList(this.state.sequence.seqContactSelected);
      context.commit('setSeqContactList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      state.seqProspectLoading = false;
    }
  },
  async getSeqContactMeta(context, data) {
    context.commit('setSeqContactSelected', data);
    try {
      const result = await SEQ.getContactMetadata(this.state.sequence.seqContactSelected);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getContactStatus(context) {
    try {
      const result = await SEQ.getContactStatus();
      context.commit('setContactStatus', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async importSeqContact(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.importSeqContact({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async creatSeqContactManually(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.creatSeqContactManually({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqContact(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSeqContact({
        profile_id: id,
        "is_paused": false,
        "is_finished": false,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqContact(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqContact({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addContactToMy(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.addContactToMy({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateContactStatus(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateContactStatus({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*-----------------sequence-inbox--------------------*/
  async getSeqInboxList(context, data) {
    state.inboxLoading = true;
    context.commit('setInboxSelected', data);
    try {
      const result = await SEQ.getSeqInboxList(this.state.sequence.inboxSelected);
      context.commit('setSeqInboxList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      state.inboxLoading = false;
    }
  },
  async getSeqInboxMeta(context, data) {
    let id = this.state.user.currentId.profile_id;
    context.commit('setInboxStatusSelected', data);
    try {
      const result = await SEQ.getSeqInboxMeta(this.state.sequence.inboxStatusSelected);
      context.commit('setInboxStatusNum', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async lookupSeqInbox(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.lookupSeqInbox({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async emailToSeqContact(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      await SEQ.emailToSeqContact({
        profile_id: id,
        ...data
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getMessageStatus(context) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getMessageStatus();
      context.commit('setMessageStatus', result?.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteInbox(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteInbox({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getSeqInboxAllIds(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.getSeqInboxAllIds({
        profile_id: id,
        ...data
      });
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /*------------------inbox category-------------------*/
  async getSeqInboxCategoryList(context, data) {
    context.commit('setInboxCategorySelected', data);
    try {
      const result = await SEQ.getSeqInboxCategoryList(this.state.sequence.inboxCategorySelected);
      context.commit('setSeqInboxCategoryList', result?.data);
      return result?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async createSeqInboxCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.createSeqInboxCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateSeqInboxCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.updateSeqInboxCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async deleteSeqInboxCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.deleteSeqInboxCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addSeqToCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.addSeqToCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async removeSeqFromCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.removeSeqFromCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async editSeqInboxCategory(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.editSeqInboxCategory({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async exportSeqInboxFull(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.exportSeqInboxFull({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async exportSeqInboxPartial(context, data) {
    let id = this.state.user.currentId.profile_id;
    try {
      const result = await SEQ.exportSeqInboxPartial({
        profile_id: id,
        ...data
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
};


export default{
  namespaced: true,
  state,
  mutations,
  actions,
}
