import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import company from './modules/company'
import account from './modules/account'
import intent from './modules/intent'
import contact from './modules/contact'
import people from './modules/people'
import sequence from './modules/sequence'
import schema from './modules/schema'
import services from './modules/services'
import permission from './modules/permission'
import apiKeys from './modules/apiKeys'
import notification from './modules/notification'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    people,
    company,
    account,
    intent,
    contact,
    sequence,
    schema,
    services,
    permission,
    apiKeys,
    notification
  },
  getters
})
export default store
