<template>
  <div>
    <UploadBox 
      ref="uploadBox"
      @uploadReset="uploadReset"
      @uploadSuccess="uploadSuccess" 
      @uploadFail="uploadFail"
      @deleteCb="handleRemoveSuccess"
    />
    <TransitionGroup class="upload-list" name="list">
        <div v-for="item in Object.keys(list)" :key="item" class="upload-item">
          <svg-icon 
            v-if="list[item].status === 'fail'"
            icon-class='service-upload-fail' 
            class="service-upload-file"
          />
          <svg-icon v-else icon-class='service-upload-file' class="service-upload-file"/>
          <span :class="['name', {'fail': list[item].status === 'fail'}]">{{list[item].file.name}}</span>
          <span 
            v-show="list[item].status === 'loading'" 
            v-loading="list[item].status === 'loading'"
            element-loading-spinner="el-icon-loading"
          ></span>
          <svg-icon 
            v-if="['success', 'fail'].includes(list[item].status)"
            icon-class='service-upload-close'
            :class="['service-upload-close', {'fail':  list[item].status === 'fail'}]"
            @click="handleDelete(item)"
          />
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UploadBox from './UploadBox.vue';

export default {
  props: ['uploadCb','item', 'value'],
  components: { UploadBox },
  data() {
    return {
      fileList: [],
      list: {},
      uploadLoading: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('user', ['currentId']),
  },
  watch: {
    list: {
      handler(val) {
        this.isLoading = Object.keys(val).every((item) => val[item].status === 'success');
        this.$emit('getLoadingStatus', this.isLoading);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if(Object.keys(val) !== '[]') {
          this.list = val;
        }
      },
      immediate: true,
    }
  },
  methods: {
    uploadReset(file) {
      this.uploadLoading = true;
      this.$set(this.list,file.uid, {
        hash: '',
        file,
        status: 'loading'
      });
    },
    uploadFail(data) {
      this.$set(this.list, data, {
        ...this.list[data],
        status: 'fail',
      })
      this.uploadLoading = false;
    },
    uploadSuccess(uid, data) {
      this.list = {
        ...this.list,
        [uid]: data
      };
      this.toArray();
      this.uploadLoading = false;
    },
    handleRemoveSuccess(file) {
      delete this.list[file.uid];
      this.toArray();
    },
    async handleDelete(data) {
      this.$delete(this.list, data);
      this.uploadCb(this.item.slug, this.list)
    },
    toArray() {
      this.uploadCb(this.item.slug, this.list)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active, .list-leave-active {
  transition: all .5s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
.upload-list {
  .upload-item {
    display: flex;
    height: 32px;
    padding: 0px 16px;
    align-items: center;  
    margin-bottom: 16px;
    margin-top: 16px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #F0F4FF;
    color: $color-blue-8;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0.36px;

    ::v-deep .el-loading-spinner {
      margin: 0 16px 0 0;
      transform: translate(-12px,-50%);
    }

    .name {
      flex: 1;
      &.fail {
        color: $color-red-1;
      }
    }
    .service-upload-file {
      margin-right: 10px;
      height: 12px;
    }
    .service-upload-close {
      width: 12px;
      height: 12px;
      cursor: pointer;
      stroke: $color-blue-8;
      &.fail {
        stroke: $color-red-1;
      }
    }
  }
}
</style>
