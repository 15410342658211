<template>
  <div id="right" >
    <el-button class="btn-nav-upgrade" @click="handleUpgrade">
      {{ $t('upgrade') }}
    </el-button>

    <el-dropdown class="notification" trigger="click">
      <span class="btn-nav-icon" @click="drawer = true" >
        <svg-icon style="width: 17px;height: 19px"  icon-class="topNav-bell" />
        <div class="bell-dot" v-if="unReadData?.length > 0" > {{unReadData?.length}}</div>
      </span>
      <el-dropdown-menu slot="dropdown"
                        :append-to-body="false"
                        :visible-arrow=false
                        class="notificationBox">
        <div class="header" >
          <span>{{ $t('notification') }}</span>
          <div class="right" >
            <i class="el-icon-loading" v-if="markLoading" />
            <svg-icon v-if="!markLoading" icon-class="checkmark-done" />
            <span @click="markAll">{{ $t('markAsAllRead') }}</span>
            <i class="el-icon-setting"  @click="goSetting" />
          </div>
        </div>
        <div @click="goNotification" class="content-wrapper">
          <div class="content" v-for="item in activeData" :key="item?.profile_user_notification_id">
            <div class="item" >
              <div class="left">
                <div class="logo">
                  <div class="dot" v-if="!item.is_read" />
                  <svg-icon icon-class="notification-mail-open" />
                </div>
              </div>
              <div class="right">
                <div class="title">{{ item.notification_type_slug }}</div>
                <div class="content">{{ item.notification_metadata.name }} changed status to {{item.notification_metadata.update_service_status_slug}}</div>
                <div class="date">{{ transferTime(item.created_at )}}</div>
              </div>
            </div >
          </div>
        </div>
        <div class="footer" @click="goNotification">
          View All
        </div>

      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown class="question" trigger="click">
      <span class="btn-nav-icon">
        <img src="../icons/png/topNav-help.png" alt="My Image">
      </span>
      <el-dropdown-menu slot="dropdown"
                        :append-to-body="false"
                        class="questionC" :visible-arrow=false>
        <el-dropdown-item>
          <a href="javascript:;" @click="showFeedbackDialog" class="rlink">
            <svg-icon icon-class="feedback" />
            <span >{{ $t('feedback') }}</span>
          </a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a href="https://www.pubrio.com/faqs" target="_blank" class="rlink">
            <svg-icon icon-class="Help"/>
            <span>{{ $t('helpCenter') }}</span>
          </a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a href="https://www.pubrio.com/contact-us" target="_blank" class="rlink">
            <svg-icon icon-class="about us"/>
            <span>{{ $t('contactUs') }}</span>
          </a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <div class="setting" @click="goSettings">
      <a class="btn-nav-icon">
        <svg-icon style="width: 23px;height: 23px" icon-class="topNav-setting" />
      </a>
    </div>

      <div id="user" class="user" >
        <div class="avatar" @click="handleShow1">
          <el-button class="btn-nav-user">{{ displayName }}</el-button>
        </div>
        <transition name="fade">
          <div class="menu" v-if="isShow1" >
            <div class="userName">
              {{ $t('hello')}},  {{name | bigFirstLetter}}
            </div>
            <div class="currentId">
                <span class="organ" @click="handleShow2">
                      <div>{{organization |bigFirstLetter}}</div>
                      <div class="changeOrgan">
                        <span>{{ $t('change') }}</span>
                        <svg-icon icon-class="nav-arrow" />
                      </div>
                  </span>

                  <transition name="fade">
                    <div class="menu" v-if="isShow2">
                      <div class="dropItem"
                          v-for="(item,index) in profile"
                          :key="index" >
                        <a @click="chooseProfile(item)" :class="item.name === organization ? 'active':''">
                          {{ item.name | bigFirstLetter}}
                        </a>
                      </div>
                    </div>
                  </transition>
            </div>
            <div class="dropItem">
              <router-link :to="{name:'account'}">
                <svg-icon icon-class="profile"/>
                {{ $t('yourProfile') }}
              </router-link>
            </div>
            <div class="dropItem">
              <router-link :to="{name:'currentUsage'}">
                <svg-icon icon-class="Frame 6543"/>
                {{ $t('currentUsage') }}
              </router-link>
            </div>
            <div class="dropItem">
              <router-link :to="{name:'upgrade'}">
                <svg-icon iconClass="rocket"></svg-icon>
                {{ $t('upgradePlan') }}
              </router-link>
            </div>
            <div class="dropItem">
              <router-link :to="{name:'billing'}">
                <svg-icon iconClass="Frame 6542" ></svg-icon>
                {{ $t('billing') }}
              </router-link>
            </div>
            <div class="dropItem feature">
              <a href="https://www.pubrio.com/contact-us">
                <svg-icon iconClass="Request" ></svg-icon>
                {{ $t('featureRequest') }}
              </a>
            </div>
            <div class="logout" >
              <el-button size="medium" class="btn-sub" @click="logout">
                {{ $t('logout') }}
              </el-button>
            </div>
          </div>
        </transition>
      </div>


    <div class="lang">
      <el-dropdown trigger="click" @command="changeLanguage">
            <span class="btn-nav-lang">
              {{$t(currentEn)}}
              <span></span>
            </span>
        <el-dropdown-menu slot="dropdown" :visible-arrow=false :append-to-body="false">
          <el-dropdown-item command="en" :disabled="'zh'=== $i18n.locale "><span>English (EN)</span></el-dropdown-item>
          <el-dropdown-item command="cn" :disabled="'cn'=== $i18n.locale "><span>中文（简）</span></el-dropdown-item>
          <el-dropdown-item command="tw" :disabled="'tw'=== $i18n.locale "><span>中文（繁）</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
    <Feedback :dialogVisible.sync="feedbackShow" />
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import {mapActions, mapMutations, mapState} from "vuex";
import Feedback from './Feedback.vue';
import moment from 'moment'

export default {
  name: "top-right",
  components: {
    Feedback,
  },
  data() {
    return {
      currentEn:this.$i18n.locale,
      isShow1:false,
      isShow2:false,
      feedbackShow: false,
      drawer: false,
      markLoading: false,
    };
  },
  computed:{
    ...mapState('notification',['notificationList']),
    activeData(){
      return this.notificationList.profile_user_notifications
    },
    unReadData(){
      return this.activeData?.filter(item => !item.is_read)
    },
    currentId(){
      return this.$store.state.user.currentId.profile_id
    },
    organization(){
      if(this.$store.state.user.currentId){
        return this.$store.state.user.currentId.name
      }
    },
    name(){
      return this.$store.state.user.user.nickname
    },
    displayName(){
      if(this.name){
        let arr = this.name.split(' ')
        if(arr.length === 1){
          return this.name.charAt(0).toUpperCase()
        } else {
          return this.name.split(' ')[0].charAt(0).toUpperCase()+this.name.split(' ')[1].charAt(0).toUpperCase()
        }
      }
    },
    profile(){
      return this.$store.state.user.profile
    }
  },
  watch:{
    $route(to) {
      if(to.path.indexOf('/setting') > -1){
        this.isShow1 = false
      }
    },
    currentId(val){
      this.getConfig({profile_id: val})
      this.getUserList({profile_id: val, is_current:true}) //获得当前用户信息
      this.getProfile({profile_id:val}) //获得account信息
    }
  },
  filters: {
    bigFirstLetter(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    }
  },
  methods: {
    ...mapMutations('user', ['setLanguage']),
    ...mapMutations('notification',['setGoSetting']),
    ...mapActions('user',['getConfig']),
    ...mapActions('account',['getUserList','getProfile']),
    ...mapActions('notification',['getNotificationList','updateNotification']),
    goSettings(){
      this.$router.push({name:'account'})
    },
    transferTime(val){
      const date = moment(val);
      const lastSaturday = date.clone().day(-1); // -1 表示上一个星期六
      lastSaturday.set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
      return lastSaturday.format('[Last Saturday at] hh:mm A');
    },
    async markAll(){
      if(this.unReadData.length === 0){
        this.$message.warning('No unread notification')
        return
      }
      try{
        this.markLoading = true;
        await this.updateNotification({
          "profile_user_notifications": this.activeData.map(item => item.profile_user_notification_id),
          "is_read": true
        })
        await this.getNotificationList()
        this.$message.success('Marked as all read')
      }finally {
        this.markLoading = false;
      }
    },
    goNotification(){
      this.$router.push({name:'notification'})
    },
    goSetting(){
      this.setGoSetting(true)
      this.$router.push({name:'notification'})  //后面需要全部换成路由跳转
    },
    showFeedbackDialog() {
      this.feedbackShow = true;
    },
    async chooseProfile(item){
      this.isShow2 = false
      await this.$store.commit('user/setCurrentId',item)
    },
    handleUpgrade(){
      this.$router.push({name:'upgrade'})
    },
    async logout(){
      let companySelected = this.$store.state.company.selected
      localStorage.setItem('companySelected',JSON.stringify(companySelected))
      let peopleSelected = this.$store.state.people.selected
      localStorage.setItem('peopleSelected',JSON.stringify(peopleSelected))

      await this.$store.dispatch('user/logout')
      await this.$router.push('/login?returnUrl=' + encodeURIComponent(this.$route.fullPath))
      let mes = this.$t('LoginOut')
      this.$message.success(mes)
    },
    /*-------------------切换语言---------------------------*/
    changeLanguage(lang) {
      this.currentEn= lang
      Cookie.set('language', lang) // 切换多语言
      this.$i18n.locale = lang // 设置给本地的i18n插件
      let mes = this.$t('switchLanguage')
      this.setLanguage(lang);
      this.$message.success(mes)
    },
    handleShow1(){
      this.isShow1 = !this.isShow1
    },
    handleShow2(){
      this.isShow2 = !this.isShow2
    },
    hideDropdown(e){
      let user = document.getElementById('user')
      if(user){
        if(!user.contains(e.target)){
          this.isShow1 = false;
          this.isShow2 = false;
        }
      }
      let currentId = document.getElementsByClassName('currentId')[0]
      if(currentId){
        if(!currentId.contains(e.target)){
          this.isShow2 = false;
        }
      }
    }
  },
  mounted() {
    this.getNotificationList()
    document.addEventListener('click', this.hideDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideDropdown);
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 991px) {
  .btn-nav-upgrade{
    display:none!important;
  }
}
@media (max-width: 767px) {
  #right{
    flex:1!important;
    justify-content: end;
    align-items: center;
    gap:10px!important;
    padding:0!important;
  }
}
#right{
  gap:20px;
  display:flex;
  align-items: center;
  .el-button{
    flex:2;
    font-size: 14px;
  }
  .notification{
    position: relative;
    .btn-nav-icon{
      position: relative;
      .bell-dot{
        position:absolute;
        top:-8px;
        left:9px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 16px;
        border-radius: 50%;
        background: #FF2727;
        font-family: Lato;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #FFFFFF;
      }
    }
    .notificationBox{
      width: 440px;
      margin-right: -240px;
      max-height: 440px;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #EAEEFF;
      box-shadow: 0px 16px 32px 0px #2636990A;
      background: #FFFFFF;
      .header{
        height:52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:16px;
        flex-shrink: 0;
        border-bottom: 1px solid #EAEEFF;
        span {
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 19.2px;
          letter-spacing: 0.03em;
          text-align: left;
          color: #00228C;
        }
        .right{
          cursor: pointer;
          .svg-icon{
            font-size: 16px;
            margin-right: 8px;
            stroke:#013CF3;
          }
          span{
              margin-right: 16px;
              font-family: Lato;
              font-size: 13px;
              font-weight: 500;
              line-height: 15.6px;
              letter-spacing: 0.03em;
              text-align: left;
              color: #013CF3;
              cursor: pointer;
            }
            i{
              font-size: 16px;
              color:#00228C;
            }
        }

      }
      .content-wrapper {
        flex: 1;
        overflow: auto;
        .content{
          background: #F6F9FF;
          cursor: pointer;
          .item{
            display: flex;
            gap:16px;
            padding:8px 16px;
            border-bottom: 1px solid #EAEEFF;
            .left{
              display: flex;
              align-items: center;
              .logo{
                position: relative;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: #E1E2E6;
                display: flex;
                align-items: center;
                justify-content: center;
                .svg-icon{
                  font-size: 16px;
                }
                .dot{
                  position:absolute;
                  top:0;
                  left:-8px;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #FF2727;
                  margin-right: 16px;
                }
              }

            }
            .right{
              .title{
                margin-bottom: 4px;
                color: var(--Black, #373C42);
                font-family: Lato;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.39px;
              }
              .content{
                margin-bottom: 4px;
                color: var(--Black, #373C42);
                font-family: Lato;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 154.3%; /* 20.059px */
                letter-spacing: 0.39px;
              }
              .date{
                color: var(--Gray-0004, #98A1B3);
                font-family: Lato;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 154.3%; /* 20.059px */
                letter-spacing: 0.39px;
              }

            }
          }
        }
      }
      .footer{
        height:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        flex-shrink: 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.6px;
        letter-spacing: 0.03em;
        text-align: left;
        color: #013CF3;
        cursor: pointer;
      }
    }
  }
  .question{
    ::v-deep .el-dropdown-menu{
      width: 232px;
      margin-right: -100px;
      padding-bottom: 12px;
      .el-dropdown-menu__item {
        width: 100%;
        padding:0 16px!important;
        a {
          display: flex;
          align-items: center;
          height: 44px;
          line-height: 40px;
          padding: 10px;
          font-weight: 600;
          font-size: 16px;
          color:$color-black;
          .svg-icon{
            margin-right: 16px;
            font-size: 20px;
          }
        }
        .rlink{
          border-bottom:0.4px solid #E1E2E6;
        }
      }
    }
  }
  .setting{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    color:#949fb1;
  }
  .setting:hover{
    color:#146ef6;
  }

  #user {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .userName{
      padding:0 16px;
      color:$color-blue-5!important;
      font-weight: 700;
    }
    .menu{
      position: absolute;
      top:50px;
      right:-80px;
      width: 346px;
      padding:16px 0;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 #2636990F;
    }
    .dropItem{
      a {
        display: block;
        height: 48px;
        line-height: 48px;
        margin:0 16px;
        padding:0 10px;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.03em;
        border-bottom: 0.4px solid $color-gray-0;
        color:$color-black;
        .svg-icon{
          font-size: 20px;
          margin-right: 16px;
        }
      }
    }
    .feature{
      a{
        border:0;
      }
    }
    .dropItem:hover{
      background: $color-blue-1;
    }
    .btn-sub{
      width: 314px;
      margin:26px auto 0;
    }
    .currentId{
      position: relative;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.03em;
      color:$color-black;
      padding:0 16px;
      .organ{
        height: 48px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        div:first-child{
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $color-black;
        }
        div:last-child{
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $color-black;
        }
      }
      .menu{
        position: absolute;
        top:40px;
        right:16px;
        width: 314px;
        padding:0 16px;
        border-radius: 8px;
        .dropItem{
          font-weight: 600;
          line-height: 48px;
          color: $color-black;
          a {
            margin:0;
            padding-left:9px;
          }
          &:hover{
            background: none;
          }
        }
      }
    }
    .active{
      color:$color-blue-5!important;
    }
    .changeOrgan{
      margin-top: -12px;
      height:15px;
      line-height: 15px;
      font-size: 12px;
      color:#949fb1;
      .svg-icon{
        margin-left: 8px;
      }
    }
  }
  .lang{
    width: 90px;
    font-weight: 700;
    cursor: pointer;
    ::v-deep .el-dropdown-menu {
      margin-right: -30px;
      width: 155px !important;
      padding-bottom: 12px;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 #2636990F;
      .el-dropdown-menu__item {
        span{
          display: flex;
          align-items: center;
          height: 44px;
          line-height: 40px;
          padding:0 10px;
          font-weight: 600;
          font-size: 16px;
          color:$color-black;
          border-bottom:0.4px solid #E1E2E6; ;
        }
      }
    }
  }
  ::v-deep .el-dropdown-menu {
    .el-dropdown-menu__item {
      width: 100%;
      padding:0 16px;
    }
    .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
      background: $color-blue-1;
    }
  }
}
.fade-enter-active {
  animation: fade-in 0.3s ease-in-out forwards;
}

.fade-leave-active {
  animation: fade-out 0.3s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    max-height: 0;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    max-height: 346px;
    transform-origin: top;
    transform: scaleY(1);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    max-height: 346px;
    transform-origin: top;
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    max-height: 0;
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0;
  }
}
</style>
