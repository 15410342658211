import * as people from "@/api/people";
import {exportCompany} from "@/api/people";
const _ = require('lodash');

const state = {
    searchPeopleList:[],//搜索公司列表
    peopleGroup:[],//人群saved分组
    total:0,
    savedList:[],//已保存的人
    peopleLists:[],//人员列表
    contactList:[],//location contact列表
    managementLevels:localStorage.getItem('managementLevels')?JSON.parse(localStorage.getItem('managementLevels')):[],//管理级别
    departments:localStorage.getItem('departments')?JSON.parse(localStorage.getItem('departments')):[],//部门
    departmentFunctions:localStorage.getItem('departmentFunctions')?JSON.parse(localStorage.getItem('departmentFunctions')):[],//部门Function
    titles:[],//title
    names:[],//name
    isLoading:false,
    isShow:true, //控制filter是否隐藏
    clearAll:false,//控制是否清空
    isStopSearch:false,//控制是否停止搜索
    addCreditsVisible:false,//控制是否显示添加credits弹窗
    selected: {
        search_term:'',
        people_groups:[],
        people_name:'',
        people_titles:[],
        departments:[],
        department_functions:[],
        management_levels:[],
        employees:[],
        verticals:[],
        people_locations:[],
        company_locations:[],
        companies:[],
        technologies:[],
        categories:[],
        per_page: parseInt(process.env.VUE_APP_PER_PAGE),
        profile_id: '',
    },
}
const mutations = {
    setCollapse(state){
        state.isShow = !state.isShow
    },
    setClear(state){
        state.clearAll = true
    },
    setLoading(state,val){
        state.isLoading = val
    },
    setAddCreditsVisible(state,val){
        state.addCreditsVisible = val
    },

    /*-------------综合搜索----------------*/
    setSelected(state,val){
        let id = this.state.user.currentId.profile_id
        let temp = {...state.selected, page: 1,...val,'profile_id':id}
        if (_.isEqual(state.selected,temp)){
           // state.isStopSearch = true
        }else{
            state.selected = temp
        }
    },
    setIsStopSearch(state,val){
        state.isStopSearch = val
    },
    setSearchPeopleList(state,List){
        state.searchPeopleList = List.peoples
        state.total = List.pagination
        state.savedList = List.peoples && List.peoples.filter(item=>{
            if (item.saved_lists !== null){
                return item
            }})
    },
    /*-------------人员saved分组----------------*/
    setPeopleGroup(state,peopleList){
        state.peopleGroup = peopleList
    },
    setManagementLevels(state,List){
        localStorage.setItem('managementLevels',JSON.stringify(List))
    },
    setDepartments(state,List){
        localStorage.setItem('departments',JSON.stringify(List))
    },
    setDepartmentFunctions(state,List){
        localStorage.setItem('departmentFunctions',JSON.stringify(List))
    },
}
const actions = {
    setClear(context){
        context.commit('setClear')
    },
    /*-------------综合搜索----------------*/
    async searchPeopleList(context,val){
      await context.commit('setSelected',val)
        if (this.state.people.isStopSearch){
            context.commit('setIsStopSearch',false)
            return
        }
        try {
            const result = await people.searchPeopleList(this.state.people.selected).catch(err=>{
              console.log(err)
            })
            if (result?.data) {
                context.commit('setSearchPeopleList',result?.data)
                return result?.data
            } else if (result?.message?.includes ('422')) {
                return result
            }
        }catch (error) {
            console.log(error)
            throw error
        }
    },
    /*-------------人员saved分组----------------*/
    async getPeopleGroup(context){
        let id = this.state.user.currentId.profile_id
        try{
          const result = await people.getPeopleGroup({'profile_id': id})
          context.commit('setPeopleGroup',result?.data)
        }catch (e) {
          console.log(e)
          throw e
        }
    },
    /*-------------人员管理级别----------------*/
    async getManagementLevels(context){
      try{
        const result = await people.getManagementLevels()
        context.commit('setManagementLevels',result?.data)
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*-------------人员部门----------------*/
    async getDepartments(context){
      try{
        const result = await people.getDepartments()
        context.commit('setDepartments',result?.data)
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*-------------部门Function----------------*/
    async getDepartmentFunctions(context){
      try{
        const result = await people.getDepartmentFunction()
        context.commit('setDepartmentFunctions',result?.data)
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*-------------人员name----------------*/
    async getNames(context,val){
      try{
        const result = await people.getNames(val)
        context.commit('setNames',result?.data)
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*-------------人员表格请求Email地址----------------*/
    async getEmail(context,val){
      try{
        const result = await people.getEmail({...val,profile_id:this.state.user.currentId.profile_id})
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*-------------delete item from group----------------*/
    async deletePeople(context,val){
      try {
        const result = await people.deletePeople({...val,profile_id:this.state.user.currentId.profile_id})
        return result
      }catch (err) {
        console.log(err)
        throw err
      }
    },
    /*-----根据groups返回domain ID------*/
    async getDomainIdByGroup(context,data){
      try{
        const result = await people.getDomainIdByGroup({...data,profile_id:this.state.user.currentId.profile_id})
        return result?.data
      }catch(err){
        console.log(err)
        throw err
      }
    },
    /*---------------export------------------*/
    async export(context,data){
      try{
        const result = await people.exportCompany({...data,profile_id:this.state.user.currentId.profile_id})
        return result
      }catch(err){
        console.log(err)
        throw err
      }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
