export const PEOPLE_TYPE = 1;
export const COMPANY_TYPE = 2;
export const CONTACT_TYPE = 3;

export const CATEGORY_ALL = 1;
export const CATEGORY_MY = 2;
export const CATEGORY_TEAM = 3;

export const PAGE_PEOPLE = 1;
export const PAGE_COMPANY = 2;
export const PAGE_INTENT_SAVED_LIST = 3;
export const PAGE_INTENT_EMPLOYEE = 4;
export const PAGE_INTENT_SAVE_COMPANY = 5;

export const categoryMap = new Map([
    [CATEGORY_ALL, null],
    [CATEGORY_MY, true],
    [CATEGORY_TEAM, false],
]);

export const DELETE_TYPE_HAS_OPENED = 1;
export const DELETE_TYPE_NONE_OPENED = 2;
export const DELETE_TYPE_SINGLE = 3;

export const TYPE_SAVED_TRUE = true;
export const TYPE_SAVED_FALSE = false;

export const TYPE_EMPLOYEE = true;