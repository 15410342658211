<template>
  <div>
    <el-upload
      action="#"
      :http-request="requestFile"
      class="upload-demo"
      drag
      :on-remove="handleRemoveSuccess"
      multiple>
      <svg-icon icon-class="service-upload" class="service-upload"></svg-icon>
      <div class="el-upload__text">
        {{ $t('DragDropor')}}<span class="file"> {{ $t('chooseFile')}} </span>{{ $t('toUpload')}}
      </div>
    </el-upload>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { uploadSeqFile } from '@/api/services';

export default {
  data() {
    return {
      uploadLoading: false,
      list: [],
      source: axios.CancelToken.source(),
    }
  },
  computed: {
    ...mapState('user', ['currentId']),
    ...mapState('services', ['errAttachmentMap']),
  },
  methods: {
    async requestFile(files) {
      const file = files.file;

      // 文件大小不超过50M;
      const maxSize = 50 * 1024 * 1024; // 10MB
      
      if (file.size > maxSize) {
        this.$message.error(this.$t('theFileSizeCannotExceed50M'));
        return;
      }

      this.$emit('uploadReset', file);

      let formData = new FormData();
      formData.append('attachments', files.file);
      formData.append("profile_id", this.currentId.profile_id);
      formData.append("attachment_type", 'attachment');
      uploadSeqFile(formData,file,this.uploadProgress, this.source.token).then(res => {
      
        this.$emit('uploadSuccess', file.uid, {
          file,
          hash: res.data.attachments[0][0].hash,
          status: 'success',
          id: res.data.attachments[0][0].attachment_id
        });
      }).catch(err => {
        if (axios.isCancel(err)) {

        } else {
          // 上传失败
          this.$emit('uploadFail', file.uid, {
            file,
            hash: '',
            status: 'fail',
          })
        }
      })
    },
    uploadProgress(file, progress) {
      this.$emit('uploadProgress', file, progress);
    },
    handleRemoveSuccess(file) {
      this.$emit('deleteCb', file);
    },
    cancelRequest() {
      this.source.cancel();
      this.source = axios.CancelToken.source();
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .el-upload-list {
  display: none;
}
::v-deep .el-upload {
  width: 100%;
  .el-upload-dragger { 
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px dashed $color-gray-0;
    .service-upload {
      width: 24px;
      height: 24px;
      margin-bottom: 9px;
    }
    .el-upload__text {
      color: $color-black;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.45px;
      .file {
        color: $color-blue-4;
      }
    }
  }
}
</style>
