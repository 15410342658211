/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */

/*------判断外部资源，不能删除---------*/
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}
/**
 * 校验手机号
 * ***/
export function validMobile(str) {
  return /^1[3-9]\d{9}$/.test(str)
}

export function addZero(val){
  val=val < 10 ? '0' + val : val;
  return val
}
